import React from "react";
import {
    Typography,
    Button,
    Box,
    Paper,
    Grid,
    TextField,
    FormControlLabel,
    Link,
    Badge,
    Checkbox, 
    Tabs,
    Tab,
    Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle, alpha,
  } from "@mui/material";
  import AppBar from '@mui/material/AppBar';
  import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
  import InputAdornment from '@mui/material/InputAdornment';
  import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect,useState,createRef } from "react";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Dummydata from "./BA_data"
// import homeImage from "../images/logo-glosorry-removebg-preview.png"
import BA_logo from '../images/BA-1-removebg-preview.png'
// import DS_logo from "../images/DS-removebg-preview.png"
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import html2canvas from "html2canvas";
import canvas2image from "canvas2image";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";
// import NewLogo from "../images/new_logo.png"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { InputLabelProps } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import Container from '@mui/material/Container';
import { Slide } from '@mui/material';
import search_data from './search_data'
import DS_Data from "./DS_data";
import DS_search from "./DS_search_data";
import BA_search from "./BA_search"
  

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
    "& .MuiDialog-paper": {
      maxWidth: "950px",
    //   height:"auto",
      overflowY:"none",
      overflowX:"none"
    },
    "@media only screen and (max-width: 600px)": {
        "& .MuiDialog-paper": {
            width: "500px",

            
          },
      },
      "@media only screen and (max-width: 380px)": {
        "& .MuiDialog-paper": {
            width: "500px",
            overflowX:"hidden"
            
          },
          
      },
      "@media only screen and (max-width: 410px)": {
        "& .MuiDialog-paper": {
            width: "335px",
            overflowX:"hidden"
            
          },
          
      }
  }));
  
  function BAHome(){
   
    const ref = createRef(null)
    const dekstopref=createRef(null)
    const {id}=useParams();
    
    console.log(id,"see id")
    const[sticky,setSticky]=useState(false)
    const[elementIn,setelementIn]=useState(false)
   
    

//    Mobile sticky nav

setTimeout(()=>{
    window.onscroll = function() {myFunction()};
    var header = document.getElementById("myHeader");
    // console.log(header.childNodes[0].lastChild,"mobheader")
    var sticky = header.offsetTop;
    function myFunction() {
        // console.log(sticky,window.pageYOffset,"mob")
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        
      } else {
        header.classList.remove("sticky");
    
      }
    }
    
},0)
if(window.innerWidth<900){
    setTimeout(()=>{
        window.onscroll = function() {myFunction()};
        var header = document.getElementById("myHeaderMob");
        // console.log(header.childNodes[0].lastChild,"mobheader")
        var sticky = header.offsetTop;
        function myFunction() {
            // console.log(sticky,window.pageYOffset,"mob")
          if (window.pageYOffset > sticky) {
            header.classList.add("stickymob");
            
          } else {
            header.classList.remove("stickymob");
        
          }
        }
        
    },0)
}


  

const getImage=(word,url)=>{
    const a = document.createElement("a");
    a.href = url
    a.download = `${word}.png`
    a.click();
    handleCloseLoader()
    // console.log(word,"pppp")
    // const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = {
    //     cropPositionTop: 0,
    //     cropPositionLeft: 0,
    //     cropWidth: 970,
    //     cropHeigth: 400
    //   };
  
    //   html2canvas(dekstopref.current).then(canvas => {
    //     let croppedCanvas = document.createElement("canvas");
    //     let croppedCanvasContext = croppedCanvas.getContext("2d");
  
    //     croppedCanvas.width = cropWidth;
    //     croppedCanvas.height = cropHeigth;
  
    //     croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);
  
       
    //   });
    };
    const getImageMob=(word,url)=>{
      // console.log(word,"pppp")
      const a = document.createElement("a");
          a.href = url
          a.download = `${word}.png`
          a.click();
          handleCloseLoader()
    //   const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = {
        //   cropPositionTop: 0,
        //   cropPositionLeft: 0,
        //   cropWidth: 900,
        //   cropHeigth: 600
        // };
    
        // html2canvas(ref.current).then(canvas => {
        //   let croppedCanvas = document.createElement("canvas");
        //   let croppedCanvasContext = croppedCanvas.getContext("2d");
    
        //   croppedCanvas.width = cropWidth;
        //   croppedCanvas.height = cropHeigth;
    
        //   croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);
    
         
        // });
      };
  
    const [index,setIndex]=useState(0)
    const [open, setOpen] = useState(false);
    const[nxt,setNxt]=useState(0)
    const[DummyData,setData]=useState(Dummydata)
    const[search,setSearch]=useState()
    const [aplha, setAplha] = useState("A");
    const[search_res,setSearchres]=useState()
    //   useEffect(()=>{
    //     if(id=='DS'){
    //         setData(DS_Data)
    //     }
    //     else{
    //         setData(Dummydata)
    //     }

    //   },[id])
   
    const [openLoader, setOpenLoader] = useState(false);
    const handleCloseLoader= () => {
      setOpenLoader(false);
    };
    const handleToggle = () => {
      setOpenLoader(!openLoader);
    };

    const handleChange = (event) => {
        setAplha(event.target.value);
    };
    
    const handleOpen = (val,j) => {setOpen(true);
    setIndex(val)
    setNxt(j)
    
    };
    const handleClose = () => setOpen(false);
   
    useEffect(()=>{
        
        // const final_data=[
        //     {
        //         label:"A",
        //         content:[]
        //     },
        //     {
        //         label:"B",
        //         content:[]
        //     },
        //     {
        //         label:"C",
        //         content:[]
        //     },
        //     {
        //         label:"D",
        //         content:[]
        //     },
        //     {
        //         label:"E",
        //         content:[]
        //     },
        //     {
        //         label:"F",
        //         content:[]
        //     },
        //     {
        //         label:"G",
        //         content:[]
        //     },
        //     {
        //         label:"H",
        //         content:[]
        //     },
        //     {
        //         label:"I",
        //         content:[]
        //     },
        //     {
        //         label:"J",
        //         content:[]
        //     },
        //     {
        //         label:"K",
        //         content:[]
        //     },
        //     {
        //         label:"L",
        //         content:[]
        //     },
        //     {
        //         label:"M",
        //         content:[]
        //     },
        //     {
        //         label:"N",
        //         content:[]
        //     },
        //     {
        //         label:"O",
        //         content:[]
        //     },
        //     {
        //         label:"P",
        //         content:[]
        //     },
        //     {
        //         label:"Q",
        //         content:[]
        //     },
        //     {
        //         label:"R",
        //         content:[]
        //     },
        //     {
        //         label:"S",
        //         content:[]
        //     },
        //     {
        //         label:"T",
        //         content:[]
        //     },
        //     {
        //         label:"U",
        //         content:[]
        //     },
        //     {
        //         label:"V",
        //         content:[]
        //     },
        //     {
        //         label:"W",
        //         content:[]
        //     },
        //     {
        //         label:"X",
        //         content:[]
        //     },
        //     {
        //         label:"Y",
        //         content:[]
        //     },
        //     {
        //         label:"Z",
        //         content:[]
        //     }
        // ]
        
        // const dbData=async()=>{
        //     const res= await fetch(`http://localhost:8000/quiz/product-glossory?vertical=${"PM"}&status=${1}`)
        //     const data=await res.json();
        //     data.map((val)=>{
        //         if(val.label=="A"){
        //             final_data[0].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="B"){
        //             final_data[1].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="C"){
        //             final_data[2].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="D"){
        //             final_data[3].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="E"){
        //             final_data[4].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="F"){
        //             final_data[5].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="G"){
        //             final_data[6].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="H"){
        //             final_data[7].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="I"){
        //             final_data[8].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="J"){
        //             final_data[9].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="K"){
        //             final_data[10].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="L"){
        //             final_data[11].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="M"){
        //             final_data[12].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="N"){
        //             final_data[13].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="O"){
        //             final_data[14].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="P"){
        //             final_data[15].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="Q"){
        //             final_data[16].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="R"){
        //             final_data[17].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="S"){
        //             final_data[18].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="T"){
        //             final_data[19].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="U"){
        //             final_data[20].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="V"){
        //             final_data[21].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="W"){
        //             final_data[22].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="X"){
        //             final_data[23].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="Y"){
        //             final_data[24].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //         if(val.label=="Z"){
        //             final_data[25].content.push(
        //                 {
        //                     word:val.word,
        //                     definition:val.definition
        //                 }
        //             )
        //         }
        //     })
        //     setData(final_data)
           
            
        // }
        // dbData()
       
        AOS.init({ duration: 2000});
    },[])
    

    const handleChnage=(e)=>{ 
       console.log(e.target.value,"value")
       const item=e.target.value
    //    console.log(item,"llllll")
       if(!item){
        // if(id=='DS')
        // setData(DS_Data)
        // else
        setData(Dummydata)
       }
       function findArrayElementByTitle(array, title) {
        return array.filter((element) => {
          return element.word.toUpperCase().includes(title.toUpperCase())
        })
      }
     
     var arr= findArrayElementByTitle(BA_search,item[0].toUpperCase()+ item.slice(1))
     console.log(arr,"searcharr")
     if(arr.length==0){
        setData([])
     }
     else{
        const final_data=[
            {
                label:"A",
                content:[]
            },
            {
                label:"B",
                content:[]
            },
            {
                label:"C",
                content:[]
            },
            {
                label:"D",
                content:[]
            },
            {
                label:"E",
                content:[]
            },
            {
                label:"F",
                content:[]
            },
            {
                label:"G",
                content:[]
            },
            {
                label:"H",
                content:[]
            },
            {
                label:"I",
                content:[]
            },
            {
                label:"J",
                content:[]
            },
            {
                label:"K",
                content:[]
            },
            {
                label:"L",
                content:[]
            },
            {
                label:"M",
                content:[]
            },
            {
                label:"N",
                content:[]
            },
            {
                label:"O",
                content:[]
            },
            {
                label:"P",
                content:[]
            },
            {
                label:"Q",
                content:[]
            },
            {
                label:"R",
                content:[]
            },
            {
                label:"S",
                content:[]
            },
            {
                label:"T",
                content:[]
            },
            {
                label:"U",
                content:[]
            },
            {
                label:"V",
                content:[]
            },
            {
                label:"W",
                content:[]
            },
            {
                label:"X",
                content:[]
            },
            {
                label:"Y",
                content:[]
            },
            {
                label:"Z",
                content:[]
            }
        ]
      //   if(arr.length>0){
      
      //   }
           arr.map((val)=>{
                    if(val.label=="A"){
                        final_data[0].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="B"){
                        final_data[1].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="C"){
                        final_data[2].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="D"){
                        final_data[3].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="E"){
                        final_data[4].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="F"){
                        final_data[5].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="G"){
                        final_data[6].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="H"){
                        final_data[7].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="I"){
                        final_data[8].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="J"){
                        final_data[9].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="K"){
                        final_data[10].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="L"){
                        final_data[11].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="M"){
                        final_data[12].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="N"){
                        final_data[13].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="O"){
                        final_data[14].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="P"){
                        final_data[15].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="Q"){
                        final_data[16].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="R"){
                        final_data[17].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="S"){
                        final_data[18].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="T"){
                        final_data[19].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="U"){
                        final_data[20].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="V"){
                        final_data[21].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="W"){
                        final_data[22].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="X"){
                        final_data[23].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="Y"){
                        final_data[24].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                    if(val.label=="Z"){
                        final_data[25].content.push(
                            {
                                word:val.word,
                                definition:val.definition,
                                image:val.image
                            }
                        )
                    }
                })
                console.log(final_data,"finalllll")
                
                setData(final_data)
     }
    
         
    
    }
    function add3Dots(string, limit)
{
    // console.log(string.length,"lets see")
  var dots = "...";
  if(string.length > limit)
  {
    // you can also use substr instead of substring
    string = string.substring(0,limit) + dots;
  }
 
    return string;
}
 console.log(DummyData,"dummydata")   
    return(
        <>
      
                <Box>
                            <Box sx={{background:" #f5f9fc",height:"550px",display: { xs: "none", lg: "block" }}}>
        
        <Grid container>
            <Grid item lg={5} md={6} >
                <Box sx={{ml:5,mt:13}}>
                <Typography sx={{color:"#0b3694",fontSize:"45px",fontWeight:"600",maxWidth:"473px",lineHeight:"1.17",mb:4}}>Business Analytics Glossary</Typography>
   
   <Typography sx={{color:"#171d29",width:"570px",fontSize:"18px",mb:1}} >
   This glossary serves as a valuable resource for business analytics enthusiasts, offering a comprehensive collection of key terms, concepts, and frameworks frequently employed in the field of business analytics. Whether you're an experienced professional or just starting your analytics journey, it's an indispensable asset to expand your understanding and enhance your proficiency.
</Typography>

                </Box>
           
   
            </Grid>
            <Grid item lg={5} md={6}>
                <Box sx={{ml:21,mt:4,width:"100%"}}>
                <img src={BA_logo} style={{maxWidth:"100%",height:"auto"}}/>
                </Box>
    
   
    </Grid>
            
        </Grid>
   
    </Box>
    {/* Tablet view */}
    <Box sx={{background:" #f5f9fc",height:"1200px",width:"100%",display: { xs: "none", lg: "none",sm:"block" }}}>
    <Grid container>
        <Grid item sm={6}  >
                <Box sx={{width:"100%",mt:7,ml:13,mb:2}}>
                <img src={BA_logo} style={{width:"600px",height:"auto"}}/>
                </Box>
    
   
    </Grid>
        </Grid>
        <Grid container>
            <Grid item sm={6} >
                <Box sx={{ml:15,width:"100%"}}>
                <Typography sx={{color:"#0b3694",fontSize:"55px",fontWeight:"600",width:"650px",lineHeight:"1.17",mb:2,mt:5}}>Business Analytics Glossary</Typography>
   
   <Typography sx={{color:"#171d29",width:"600px",fontSize:"25px"}} >
   This glossary serves as a valuable resource for business analytics enthusiasts, offering a comprehensive collection of key terms, concepts, and frameworks frequently employed in the field of business analytics. Whether you're an experienced professional or just starting your analytics journey, it's an indispensable asset to expand your understanding and enhance your proficiency.
</Typography>
  
                </Box>
           
            </Grid>
           
            
        </Grid>
       
   
    </Box>
{/* mobile view */}

    <Box sx={{background:" #f5f9fc",height:"800px",display: { sm: "none", lg: "none",xs:"block" }}}>
      <Container fixed>

      
        <Grid container>
            <Grid item xs={6} >
                <Box sx={{px:1}}>
                <Typography sx={{color:"#0b3694",fontSize:"35px",width:"370px",fontWeight:"600",lineHeight:"1.17",mb:2,mt:5}}>Business Analytics Glossary</Typography>
   
   <Typography sx={{color:"#171d29",width:"370px",fontSize:"20px"}} >
   This glossary serves as a valuable resource for business analytics enthusiasts, offering a comprehensive collection of key terms, concepts, and frameworks frequently employed in the field of business analytics. Whether you're an experienced professional or just starting your analytics journey, it's an indispensable asset to expand your understanding and enhance your proficiency.
</Typography>
  
                </Box>
           
            </Grid>
           
            
        </Grid>
        <Grid container>
        <Grid item xs={6}  >
                <Box sx={{mt:2.5,ml:2}}>
                <img src={BA_logo} style={{width:"340px",height:"auto"}}/>
                </Box>
    
   
    </Grid>
        </Grid>
      </Container>
        
   
    </Box>





   
                </Box>
       

 
 
{/* large view */}
<Box id="myHeader" sx={{backgroundColor:"#0b3694",display:{xs: "none", lg: "flex"} ,justifyContent:"center",mb:3,p:2}}>
   
<Box className="alpha" sx={{mr:0.5, }}  >


    
<FormControl  sx={{background:"#fff",borderRadius:"6px"}}>

<Select
  labelId="demo-simple-select-label"
  id="demo-simple-select"
  onChange={handleChange}

  defaultValue={10}
  
  
 
  sx={{color:"#000",width:300}}

>
  <MenuItem value={10} ><a className="mobile" href="#A">A</a></MenuItem>
  <MenuItem value={20} ><a className="mobile" href="#B">B</a></MenuItem>
  <MenuItem  value={30}><a className="mobile" href="#C">C</a></MenuItem>
  <MenuItem value={40} ><a className="mobile" href="#D">D</a></MenuItem>
  <MenuItem value={50} ><a className="mobile" href="#E">E</a></MenuItem>
  <MenuItem value={60} ><a className="mobile" href="#F">F</a></MenuItem>
  <MenuItem value={70}><a className="mobile" href="#G">G</a></MenuItem>
  <MenuItem value={80}><a className="mobile" href="#H">H</a></MenuItem>
  <MenuItem value={90} ><a className="mobile" href="#I">I</a></MenuItem>
  <MenuItem  value={100}><a className="mobile" href="#J">J</a></MenuItem>
  <MenuItem value={110} ><a className="mobile" href="#K">K</a></MenuItem>
  <MenuItem value={120} ><a className="mobile" href="#L">L</a></MenuItem>
  <MenuItem value={130} ><a className="mobile" href="#M">M</a></MenuItem>
  <MenuItem  value={140}><a className="mobile" href="#N">N</a></MenuItem>
  <MenuItem value={150} ><a className="mobile" href="#O">O</a></MenuItem>
  <MenuItem value={160} ><a className="mobile" href="#P">P</a></MenuItem>
  <MenuItem value={170} ><a className="mobile" href="#Q">Q</a></MenuItem>
  <MenuItem value={180} ><a className="mobile" href="#R">R</a></MenuItem>
  <MenuItem value={190} ><a className="mobile" href="#S">S</a></MenuItem>
  <MenuItem value={200} ><a className="mobile" href="#T">T</a></MenuItem>
  <MenuItem value={210}><a className="mobile" href="#U">U</a></MenuItem>
  <MenuItem value={220}><a className="mobile" href="#V">V</a></MenuItem>
  <MenuItem value={230}><a className="mobile" href="#W">W</a></MenuItem>
  <MenuItem value={240}><a className="mobile" href="#X">X</a></MenuItem>
  <MenuItem value={250}><a className="mobile" href="#Y">Y</a></MenuItem>
  <MenuItem value={260}><a className="mobile" href="#Z">Z</a></MenuItem> 
</Select>

 </FormControl>


{/* <Typography sx={{color:"#fff",fontSize:"10px",fontWeight:"bold"}}>A B C D E F G H I J K L M N O P  Q R S T U V W X Y Z</Typography> */}
</Box>
<Box className="search" sx={{ml:5}}>
<TextField 
onChange={handleChnage}
InputLabelProps={{style : {color : 'black'} }}
InputProps={{
endAdornment: (
    <InputAdornment position="end">
      <img style={{cursor:"pointer"}} src="https://www.cognigy.com/hubfs/Blog%20Assets/Search%20icon.svg" alt="searchLogo"/>
    </InputAdornment>
  ),
style: {
height: "55px"
}
}} sx={{background:"#fff",borderRadius:"6px",width:300,ml:1}} placeholder="Search Term">
    
</TextField>
</Box>
   </Box> 
 {/* Tablet view */}
   <Box id="myHeaderMob" sx={{backgroundColor:"#0b3694",display:{sm: "flex", lg: "none",xs:"flex"} ,justifyContent:"space-between",mb:3,p:4}}>
    <Box sx={{width:"50%"}} >


    
        <FormControl fullWidth sx={{background:"#fff",borderRadius:"6px"}}>
        
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={handleChange}
       
          defaultValue={10}
          
          fullWidth
         
          sx={{color:"#000"}}
        
        >
          <MenuItem value={10} ><a className="mobile" href="#A">A</a></MenuItem>
          <MenuItem value={20} ><a className="mobile" href="#B">B</a></MenuItem>
          <MenuItem  value={30}><a className="mobile" href="#C">C</a></MenuItem>
          <MenuItem value={40} ><a className="mobile" href="#D">D</a></MenuItem>
          <MenuItem value={50} ><a className="mobile" href="#E">E</a></MenuItem>
          <MenuItem value={60} ><a className="mobile" href="#F">F</a></MenuItem>
          <MenuItem value={70}><a className="mobile" href="#G">G</a></MenuItem>
          <MenuItem value={80}><a className="mobile" href="#H">H</a></MenuItem>
          <MenuItem value={90} ><a className="mobile" href="#I">I</a></MenuItem>
          <MenuItem  value={100}><a className="mobile" href="#J">J</a></MenuItem>
          <MenuItem value={110} ><a className="mobile" href="#K">K</a></MenuItem>
          <MenuItem value={120} ><a className="mobile" href="#L">L</a></MenuItem>
          <MenuItem value={130} ><a className="mobile" href="#M">M</a></MenuItem>
          <MenuItem  value={140}><a className="mobile" href="#N">N</a></MenuItem>
          <MenuItem value={150} ><a className="mobile" href="#O">O</a></MenuItem>
          <MenuItem value={160} ><a className="mobile" href="#P">P</a></MenuItem>
          <MenuItem value={170} ><a className="mobile" href="#Q">Q</a></MenuItem>
          <MenuItem value={180} ><a className="mobile" href="#R">R</a></MenuItem>
          <MenuItem value={190} ><a className="mobile" href="#S">S</a></MenuItem>
          <MenuItem value={200} ><a className="mobile" href="#T">T</a></MenuItem>
          <MenuItem value={210}><a className="mobile" href="#U">U</a></MenuItem>
          <MenuItem value={220}><a className="mobile" href="#V">V</a></MenuItem>
          <MenuItem value={230}><a className="mobile" href="#W">W</a></MenuItem>
          <MenuItem value={240}><a className="mobile" href="#X">X</a></MenuItem>
          <MenuItem value={250}><a className="mobile" href="#Y">Y</a></MenuItem>
          <MenuItem value={260}><a className="mobile" href="#Z">Z</a></MenuItem> 
        </Select>

         </FormControl>
   
   
    {/* <Typography sx={{color:"#fff",fontSize:"10px",fontWeight:"bold"}}>A B C D E F G H I J K L M N O P  Q R S T U V W X Y Z</Typography> */}
    </Box>
    <Box sx={{width:"50%",ml:2,mr:1}}>
      <FormControl fullWidth>
      <TextField 
        onChange={handleChnage}
        InputLabelProps={{style : {color : 'black'} }}
        InputProps={{
        endAdornment: (
            <InputAdornment position="end">
              <img style={{cursor:"pointer"}} src="https://www.cognigy.com/hubfs/Blog%20Assets/Search%20icon.svg" alt="searchLogo"/>
            </InputAdornment>
          ),
      style: {
        height: "55px"
      }
    }} sx={{background:"#fff",borderRadius:"6px",ml:2,width:"auto"}} placeholder="Search Term">
            
        </TextField>
      </FormControl>
       
    </Box>
   </Box>



























   <Box>
    {DummyData.length==0?(<Box>
        <Typography sx={{textAlign:"center",mb:2}} variant="h5">No word found</Typography>
    </Box>):(<Box>

        {DummyData.map((val,i)=>(
        <Box>
{val.content.length>0?(

    <Box id={val.label}>
            <Box   sx={{mb:2,pt:19}} >
     <Typography className="alphaheading">{val.label}</Typography>
     <hr/>
     
        
             <Grid container sx={{p:4}}>                
            {val.content.map((val,j)=>(
               <>
                <Grid item lg={6} md={6} sx={{mt:2,maxWidth: "45% !important",ml:4.5,mr:2,display: { xs: "none", lg: "block",md:"block" }}} className="card" >
                 {/* <Box > */}
                 <Box sx={{height:"17rem"}} onClick={()=>{handleOpen(i,j)}}>
                    <Box sx={{height:"6rem"}}>
                    <Typography variant="h5" sx={{color:"#0b3694",fontWeight:"700",mb:2,fontSize:"25px"}} className="title" >
                        {val.word} 
                     </Typography>
                    </Box>
                <Box sx={{height:"10rem"}} >
                <Typography sx={{color:"#171d29",lineHeight:"1.25",fontWeight:"400",mb:3,fontSize:"17px"}} className="para" >
                         
                         {add3Dots(val.definition[0],150)}
                         </Typography>
                </Box>
                    
                     <Box sx={{height:"5rem"}}>
                     <Typography  sx={{color:"#0b3694",fontWeight:"520",display:"flex"}}  >
                     <ArrowForwardIcon sx={{mr:0.5}} />
                         Learn More
                     </Typography>
                     </Box>
                 </Box>
                     
                    
                 {/* </Box> */}
                 </Grid>
                 

         {/* Tablet view         */}
         <Grid item lg={6} sm={12} sx={{mt:2,ml:4,boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",mr:2,display: { xs: "none", lg: "none",md:"none",sm:"block" }}} className="card" >
                 {/* <Box > */}
                 <Box sx={{height:"17rem"}} onClick={()=>{handleOpen(i,j)}}>
                    <Box sx={{height:"6rem"}}>
                    <Typography variant="h5" sx={{color:"#0b3694",fontWeight:"700",mb:2,fontSize:"25px"}} className="title" >
                        {val.word} 
                     </Typography>
                    </Box>
                <Box sx={{height:"10rem"}}>
                <Typography sx={{color:"#171d29",lineHeight:"1.25",fontWeight:"400",mb:3,fontSize:"17px"}} className="para">
                         
                {add3Dots(val.definition[0],150)}
                         </Typography>
                </Box>
                    
                     <Box sx={{height:"5rem"}}>
                     <Typography  sx={{color:"#0b3694",fontWeight:"520",display:"flex"}}  >
                     <ArrowForwardIcon sx={{mr:0.5}} />
                         Learn More
                     </Typography>
                     </Box>
                 </Box>
                     
                    
                 {/* </Box> */}
                 </Grid>

{/* Mobile view */}
<Grid item lg={4} xs={12} sx={{mb:5,boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",display: { xs: "block", lg: "none",sm:"none",md:"none" }}} className="card" >
                 
<Box sx={{height:"19rem"}} onClick={()=>{handleOpen(i,j)}}>
<Box sx={{height:"6rem"}}>
<Typography variant="h5" sx={{color:"#0b3694",fontWeight:"700",mb:2,fontSize:"25px"}} className="title" >
   {val.word} 
</Typography>
</Box>
<Box sx={{height:"10rem"}}>
<Typography sx={{color:"#171d29",lineHeight:"1.25",fontWeight:"400",mb:3,fontSize:"17px"}} className="paramob">
    
{add3Dots(val.definition[0],90)}
    </Typography>
</Box>

<Box sx={{height:"5rem"}}>
<Typography  sx={{color:"#0b3694",fontWeight:"520",display:"flex"}}  >
<ArrowForwardIcon sx={{mr:0.5}} />
    Learn More
</Typography>
</Box>
</Box>



</Grid>
               
               </>
            






                  )) 
      } 
                 
                 
             {/* </Grid> */}
         </Grid>
        
    

    </Box>
    </Box>
):(

    <Box></Box>
)}


        </Box>
    
  ))}

    </Box>)}
   

</Box>



       
      

















      


<BootstrapDialog


        open={open}
        
        
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent 
   
        ref={dekstopref} className="specific"
        sx={ {
            
    width: 900,
    height:950,
    overflowY:"hidden",
    
    bgcolor: '#f5f9fc',
    display: { xs: "none", lg: "block" },
   
    p: 4,
  }}>
            <Box sx={{p:2}}>
           
        <Box sx={{display:"flex",justifyContent:"flex-end",color:"#0b3694"}}>
            <CloseIcon sx={{cursor:"pointer",fontSize:"40px"}}  onClick={handleClose}/>
            </Box>
      

       <Box sx={{display:"flex",mb:2,color:"#0b3694"}} >
          <Typography id="modal-modal-title"  component="h1" sx={{fontWeight:"700",fontSize:"32px",ml:1.5}} >
          {DummyData.length>0&&DummyData[index].content.length>0&&DummyData[index].content[nxt].word}
          </Typography>
          {/* <DownloadForOfflineIcon onClick={()=>{ handleToggle()
            getImage(Dummydata[index].content[nxt].word)}} sx={{fontSize:"25px",mt:1,ml:2,cursor:"pointer"}} /> */}
          </Box>
          <Box sx={{mb:2,p:2,height:"19rem",overflowY:"scroll",overflowX:"none", "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px gray",
        webkitBoxShadow: "inset 0 0 6px gray",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "gray",
        outline: "1px solid gray",
      }}}>
          <Typography sx={{color:"#171d29",mb:2,width:"780px",fontSize:"16px"}} >
          {DummyData.length>0&&DummyData[index].content.length>0&&DummyData[index].content[nxt].definition}
         </Typography>

         
          </Box>
        {/* </Slide> */}
          
        {/* </Slide> */}
           
        
         <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Box>
          <Button className={(nxt==0)?"dis":"abled"} variant="contained" size="large" sx={{background:"#0b3694",color:"#171d29",p:1,boxShadow:"none",border:"1px solid #0b3694 ","&:hover":{background:"#0b3694"},textTransform:"none"}}
            onClick={()=>{
                if(!(nxt==0))
                setNxt(nxt-1)
            }}
            // disabled={nxt==0}
            >
            <KeyboardArrowLeftIcon className={(nxt==0)?"dis":"abled"} sx={{color:"#fff"}}/>
              
                
            </Button>
            <Button className={(DummyData.length>0&&DummyData[index].content.length==(nxt+1))?"dis":"abled"} variant="contained" size="large" sx={{background:"#0b3694",color:"#171d29",p:1,ml:1,boxShadow:"none",border:"1px solid #0b3694","&:hover":{background:"#0b3694"},textTransform:"none"}}
            onClick={()=>{
                if(!(DummyData[index].content.length==(nxt+1)))
                setNxt(nxt+1)
            }}
            // disabled={DummyData.length>0&&DummyData[index].content.length==(nxt+1)}
            >
           
                <KeyboardArrowRightIcon className={(DummyData.length>0&&DummyData[index].content.length==(nxt+1))?"dis":"abled"}  sx={{color:"#fff"}}/>
            </Button>
          </Box>
            
            {/* <Box sx={{display:"flex",border:"1px solid #dbe2ef ",borderRadius:"6px",background:"#fff",justifyContent:"center"}}>
                
                <Typography sx={{ml:1,mt:2}}>Share This</Typography>
                
                <Typography sx={{p:1}}>
                   
                    <FacebookIcon sx={{ml:0.5,mt:1,cursor:"pointer",color:"#0b3694"}}/>
                    <LinkedInIcon sx={{ml:0.5,mt:1,cursor:"pointer",color:"#0b3694"}}/>
                    <TwitterIcon sx={{ml:0.5,mt:1,cursor:"pointer",color:"#0b3694"}}/>
                    <EmailIcon sx={{ml:0.5,mt:1,cursor:"pointer",color:"#0b3694"}}/>
                </Typography>
            </Box> */}
             <Button variant="contained" size="large" sx={{background:"#0b3694",color:"#171d29",p:1,ml:1,boxShadow:"none",border:"1px solid #0b3694","&:hover":{background:"#0b3694"},textTransform:"none"}}
           onClick={()=>{ handleToggle()
            getImage(DummyData[index].content[nxt].word,DummyData[index].content[nxt].image)}}
            
            >
            <Typography sx={{ml:2,fontWeight:"bold",color:"#fff"}}>Download</Typography>
               <DownloadIcon sx={{color:"#fff",ml:1}}  />
            </Button>
            
         </Box>
        

      
         
            </Box>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            </DialogContent>



{/* Mobile view */}
<DialogContent 
        ref={ref} className="specific"
        sx={ {
            
    width:347,
    overflowX:"none",
    height:500,
    bgcolor: '#f5f9fc',
    display: { xs: "block", lg: "none" },
   
    p: 4,
    pr:4
  }}>
            <Box sx={{}}>
            <Box sx={{display:"flex",justifyContent:"flex-end",color:"#0b3694"}}>
            <CloseIcon sx={{cursor:"pointer",fontSize:"40px"}}  onClick={handleClose}/>
            </Box>
      

       <Box sx={{display:"flex",mb:3,color:"#0b3694",height:"4rem"}}>
          <Typography id="modal-modal-title"  component="h1" sx={{fontWeight:"700",fontSize:"23px",ml:2,width:"280px"}}>
          {DummyData.length>0&&DummyData[index].content.length>0&&DummyData[index].content[nxt].word}
          </Typography>
          {/* <DownloadForOfflineIcon onClick={()=>{ handleToggle()
            getImageMob(Dummydata[index].content[nxt].word)}} sx={{fontSize:"35px",mt:1,ml:2,cursor:"pointer"}}/> */}
          </Box>
          <Box sx={{mb:2,p:2,height:"17rem",width:"100%",overflowY:"scroll",overflowX:"none", "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px gray",
        webkitBoxShadow: "inset 0 0 6px gray",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "gray",
        outline: "1px solid gray",
      }}}>
          <Typography sx={{color:"#171d29",mb:2,width:"265px"}}>
          {DummyData.length>0&&DummyData[index].content.length>0&&DummyData[index].content[nxt].definition}
         </Typography>

         
          </Box>
        
         <Box sx={{display:"flex",justifyContent:"space-evenly"}}>
          <Box>

          <Button className={(nxt==0)?"dis":"abled"} variant="contained" size="large" sx={{background:"#0b3694",color:"#171d29",p:1,mr:2,boxShadow:"none",border:"1px solid #0b3694 ","&:hover":{background:"#0b3694"},height:40,textTransform:"none"}}
            onClick={()=>{
                if(!(nxt==0))
                setNxt(nxt-1)
            }}
            // disabled={nxt==0}
            >
            <KeyboardArrowLeftIcon className={(nxt==0)?"dis":"abled"} sx={{color:"#fff"}}/>
               {/* <Typography sx={{mr:1}}>Previous</Typography> */}
                
            </Button>
            <Button className={(DummyData.length>0&&DummyData[index].content.length==(nxt+1))?"dis":"abled"} variant="contained" size="large" sx={{background:"#0b3694",color:"#171d29",p:1,boxShadow:"none",border:"1px solid #0b3694","&:hover":{background:"#0b3694"},textTransform:"none"}}
            onClick={()=>{
                if(!(DummyData[index].content.length==(nxt+1)))
                setNxt(nxt+1)
            }}
            // disabled={DummyData.length>0&&DummyData[index].content.length==(nxt+1)}
            >
           
                <KeyboardArrowRightIcon className={(DummyData.length>0&&DummyData[index].content.length==(nxt+1))?"dis":"abled"} sx={{color:"#fff"}}/>
            </Button>
          </Box>
           
            {/* <Box sx={{display:"flex",border:"1px solid #dbe2ef ",borderRadius:"6px",background:"#fff",justifyContent:"center"}}>
                
                <Typography sx={{ml:1,mt:2}}>Share This</Typography>
                
                <Typography sx={{p:1}}>
                   
                    <FacebookIcon sx={{ml:0.5,mt:1,cursor:"pointer",color:"#0b3694"}}/>
                    <LinkedInIcon sx={{ml:0.5,mt:1,cursor:"pointer",color:"#0b3694"}}/>
                    <TwitterIcon sx={{ml:0.5,mt:1,cursor:"pointer",color:"#0b3694"}}/>
                    <EmailIcon sx={{ml:0.5,mt:1,cursor:"pointer",color:"#0b3694"}}/>
                </Typography>
            </Box> */}
            <Button onClick={()=>{ handleToggle()
            getImageMob(DummyData[index].content[nxt].word,DummyData[index].content[nxt].image)}} variant="contained" size="large" sx={{background:"#0b3694",color:"#171d29",p:1,ml:4,boxShadow:"none",border:"1px solid #0b3694 ","&:hover":{background:"#0b3694"},textTransform:"none"}}
            
            
            >
            <Typography sx={{fontWeight:"bold",color:"#fff"}} >Download</Typography>
                <DownloadIcon sx={{color:"#fff",ml:1}}/>
            </Button>
            
         </Box>
        

      
         
            </Box>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            </DialogContent>



        {/* </Paper> */}
      </BootstrapDialog>
      
        </>
    )
  }
  export default BAHome;