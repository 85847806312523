




const BA_Data=[
    {
        label:"A",
        content:[

            {word: 'Active Listening', definition:[
               "Active Listening is a method used to listen and respond to others in a structured and deliberate way.  It requires a listener to understand and actively evaluate what he or she heard."
            ],image:"https://drive.google.com/uc?export=download&id=16FNBqOpNnOuoiQK9n7DfZ3fJ55j1Dr5L"},

        {word: 'Activity Diagram ', definition:[
           "An activity diagram is a UML diagram that is used to model a process. It models the actions (or behaviors) performed by the components of a business process or IT system, the order in which the actions take place, and the conditions that coordinate the actions in a specific order. Activity diagrams use swim lanes to group actions together. Actions can be grouped by the actor performing the action or by the distinct business process or system that is performing the action."
        ],image:"https://drive.google.com/uc?export=download&id=1w67SJEc9bTSdW8wp-kJA19AvBZHtR81F"},
        // {word: 'Activation Function', definition: ["In artificial neural networks, an activation function is a mathematical function that determines the output of a neuron based on its input. The activation function applies a non-linear transformation to the input, allowing the neuron to model complex, non-linear relationships between inputs and outputs."]
        // ,image:"https://drive.google.com/uc?export=download&id=1CD7hGWq7mZPBheWyvFrzQb6BjQT_RSHf"},
        
        {word: 'Agile', definition: ["Agile is a general term and conceptual framework used to describe a number of “light-weight” methodologies, such as Extreme Programming (XP), SCRUM, and Rapid Application Development (RAD), which exhibit a series of common characteristics. Some of these characteristics include iterative analysis and development, time-boxed iterations of a predefined length, delivery of the most critical features and functions first, delivery of a complete build with an initial set of limited features within a few months (often 1-2 months), small cross-functional teams usually of 6-9 team members, daily team communication meetings, and reduced levels of documentation."]
        ,image:"https://drive.google.com/uc?export=download&id=1SDquFJlrxMZOFP4UA-NifVAccD9epGdQ"},

        {word: 'Alternative Flow', definition:["An alternate flow describes a use case scenario other than the basic flow that results in a user completing his or her goal. It is often considered to be an optional flow and implies that the user has chosen to take an alternative path through the system."],
        image:"https://drive.google.com/uc?export=download&id=1469-5X6Qa8mf7szeiCo50S05VnR9wNW7"},
//         {word: 'Adam Optimization', definition: ["An optimization algorithm that can be used instead of the classical stochastic gradient descent procedure to update network weights iteratively based on training data. It computes individual adaptive learning rates for different parameters from estimates of the first and second moments of the gradients."],
//         image:"https://drive.google.com/uc?export=download&id=1nKLngaSa3mB4sWICTSV-2byCCcLss91k"},

//         {word: 'Air Flow', definition:["Apache Airflow is an open-source platform for creating, scheduling, and monitoring workflows, also known as pipelines, in data science. Airflow is designed to automate and orchestrate complex workflows that involve multiple tasks, dependencies, and data sources.",
//     <p>The core of Airflow is its DAG (Directed Acyclic Graph) scheduler, which allows users to define a set of tasks and dependencies between them, and then schedule and monitor the execution of those tasks. Each task in the DAG is defined as a Python function or a script that performs a specific action, such as running a Spark job, executing a SQL query, or sending an email.</p>
//     ],
//         image:"https://drive.google.com/uc?export=download&id=1jZZUO_wclVbUs2Akff09RYoXA8VBCusi" },
//         {word: 'Algorithm', definition: ["In data science, an algorithm refers to a set of rules or procedures used by a computer program to solve a particular problem or perform a specific task. These rules or procedures are usually expressed in the form of code, which is executed by a computer to process and analyze data.",
//     <p>Data scientists use algorithms to build predictive models, which can be used to forecast future trends, identify patterns in data, or make decisions based on historical data. The choice of algorithm depends on the nature of the problem and the characteristics of the data being analyzed.</p>
//     ]
//         ,image:"https://drive.google.com/uc?export=download&id=1bjAWyl6M3R3tghTsRDtCDO5-6WQcoere"},

//         {word: 'Anaconda', definition: ["Anaconda is a popular data science platform that provides a collection of open-source tools and libraries for data analysis, scientific computing, and machine learning. It is designed to simplify the process of setting up and managing data science environments by providing an all-in-one solution that includes a wide range of tools and packages.",
//         <p>Anaconda includes the following components:</p>,
//         <p>Conda: A package management system that allows users to easily install, update, and remove software packages.</p>,
//         <p>Python: A popular programming language used for data analysis and machine learning.</p>,
//         <p>Jupyter Notebook: A web-based interactive computational environment for creating and sharing data analyses.</p>,
//         <p>Spyder: An integrated development environment (IDE) for data science that provides a powerful code editor and debugging tools.</p>,
//         <p>Pandas: A library for data manipulation and analysis.</p>,
//         <p>NumPy: A library for numerical computing in Python.</p>,
//         <p>Matplotlib: A library for creating visualizations and plots.</p>,
//         <p>Scikit-learn: A library for machine learning in Python.</p>
        
    
    
//     ]
//         ,image:"https://drive.google.com/uc?export=download&id=1DHiW86d_NZeyNesW3nDGPwezNgbhtdcE"},

//         {word: 'Analytics', definition: ["Analytics refers to the systematic analysis of data, using statistical and computational methods, to derive insights and make informed decisions. In the context of data science, analytics involves the process of collecting, cleaning, processing, and analyzing large volumes of data to uncover patterns, trends, and relationships that can be used to improve business outcomes, optimize processes, or gain a deeper understanding of a phenomenon."
// ]
//         ,image:"https://drive.google.com/uc?export=download&id=1ygyEKrxzUTJ4_OEBX1_5Rplh6xl68_pY"},

//         {word: 'Apache Spark', definition: ["Apache Spark is an open-source, distributed computing framework designed for large-scale data processing. Spark has gained immense popularity in the data science community due to its ability to handle large volumes of data, processing speed, and support for a wide range of data sources and processing models.",
//     <p>In data science, Apache Spark can be used for a variety of tasks such as data preprocessing, data cleaning, feature extraction, and machine learning. Spark's ability to process data in-memory and its support for distributed computing allows data scientists to work with large datasets without having to worry about memory limitations or processing delays.
//     </p>
//     ]
//         ,image:"https://drive.google.com/uc?export=download&id=1V9ZbSm9dt7NGITrzXUANkKW_rbVnFdvS"},

//         {word: 'Autoregression', definition: ["Autoregression is a statistical modeling technique used in data science to model time-series data. It is a type of regression analysis where the dependent variable is regressed on one or more lagged values of itself. In other words, autoregression models the relationship between a variable and its own past values."]
//         ,image:"https://drive.google.com/uc?export=download&id=1mPBAraxvhjz0_MkSZRfHCAgjQv3ezKKX"},

//         {word: 'API', definition: ["API stands for Application Programming Interface, and it is a set of rules, protocols, and tools used for building software applications. In data science, APIs are used to connect different software systems, allowing them to exchange data and information.",
//     <p>APIs can be used to access and manipulate data from various sources, such as social media platforms, databases, web services, and other applications. Data scientists often use APIs to retrieve data from these sources, process and analyze it, and then present the results to end-users or other applications.</p>
//     ]
//         ,image:"https://drive.google.com/uc?export=download&id=1fv2SPwcWMgV6MODoo8y5C0kmZqfiX-GD"},

//         {word: 'Artificial General Intelligence', definition: ["Artificial General Intelligence (AGI) refers to a hypothetical form of artificial intelligence (AI) that can perform any intellectual task that a human being can. AGI would be able to reason, understand natural language, learn from experience, and solve complex problems without being explicitly programmed for each task.",
//     <p>In data science, AGI represents the ultimate goal of AI research, as it would enable machines to perform a wide range of tasks across different domains, such as natural language processing, image recognition, and decision making. AGI could also potentially surpass human intelligence in certain areas and help solve some of the world's most pressing problems.</p>
//     ]
//         ,image:"https://drive.google.com/uc?export=download&id=1m7WLH-iCf4NSPN0I35XXr08Jj3Rh2Txd"},

//         {word: 'Artificial Intelligence', definition: ["A branch of computer science that involves using machine learning, programming, and data science techniques that enable computers to behave intelligently. Artificial Intelligence (AI) refers to the development of intelligent machines that can perform tasks that typically require human intelligence, such as speech recognition, decision-making, and visual perception."]
//         ,image:"https://drive.google.com/uc?export=download&id=12NMVv59_8AwmHR9C_vYhRjDx1qUFpQNp"},

//         {word: 'Artificial Neural Networks', definition: ["Artificial Neural Networks (ANNs) are a type of AI algorithm modeled after the structure and function of the human brain. ANNs are used in data science to analyze and interpret complex data sets, learn patterns, and make predictions.",
//         <p>ANNs are made up of layers of interconnected nodes, or "neurons," which process and transmit information. The input layer receives data, and the output layer produces the final result. In between, there can be one or more hidden layers that process the information and extract patterns.</p>
//     ]
//         ,image:"https://drive.google.com/uc?export=download&id=1ZY1AxxMsjJTo5CMw63d3ariEMM5GA_11"},

//         {word: 'Attention', definition: ["Any of a wide range of neural network architecture mechanisms that aggregate information from a set of inputs in a data-dependent manner. A typical attention mechanism might consist of a weighted sum over a set of inputs, where the weight for each input is computed by another part of the neural network."]
//         ,image:"https://drive.google.com/uc?export=download&id=1uGuHkzjFWq6eYFT-HuTLiyN7ZNWKsxG4"},
        
//         {word: 'Attribute Sampling', definition: ["Attribute Sampling is a statistical sampling technique used in data science to estimate the proportion of a specific characteristic, or attribute, in a population. This technique is used to determine whether a population meets certain quality standards, such as the proportion of defective items in a manufacturing process."]
//         ,image:"https://drive.google.com/uc?export=download&id=1zxbOR3FkbmO1p0gH3ocBFlXvs7S-mOLj"},

              
        ]
    },
    {
        label:"B",
        content:[

            {word: 'Business Analysis Planning and Monitoring', definition: ["A BABOK 2.0 Knowledge Area that describes how a business analyst determines which activities will be needed to complete the business analysis effort.  The tasks within this knowledge area govern the business analysis tasks in all of the other knowledge areas." ]
            ,image:"https://drive.google.com/uc?export=download&id=1TxRieMu3xi4HRr7rzfHvy7n-78FPuCuY"
        },
        {word: 'Burndown Chart', definition: ["A Burndown Chart is a tool used by multiple software engineering methods to track the progress of work completed.  It compares the amount of work remaining (typically measured along the vertical axis) against time (measured along the horizontal axis).  The burndown chart gives a quick view of the amount of work that is completed over time."]
        ,image:"https://drive.google.com/uc?export=download&id=1vNoNbYqaMEmIius3TAua7A4RE75bJKxK"
    },
        {word: 'Business Entity Model', definition: ["A business entity model is a logical model that documents the entities, or things, that a business or business process uses and interacts with in order to accomplish its business activities and goals. In addition to documenting entities, a business entity model may capture the attributes of an entity, relationships between entities, and cardinality information. Many business entity models are created in the form of a UML class diagram."]
        ,image:"https://drive.google.com/uc?export=download&id=1xSJbt7qeFYLv0IzMbjvipGxiimrmTD_y"
    },
    //     {word: 'Bill of Material (BOM)', definition: ['A comprehensive inventory of the raw materials, assemblies, subassemblies, parts, and components, as well as the quantities of each needed to manufacture a product.']
    //     ,image:"https://drive.google.com/uc?export=download&id=1zxbOR3FkbmO1p0gH3ocBFlXvs7S-mOLj"
    // },

    //     {word: 'Big Data', definition: ["Big Data refers to the vast and complex datasets generated by various sources, including digital platforms, sensors, and user interactions. Managing and analyzing Big Data can provide valuable insights, inform decision-making, and uncover trends or patterns that might otherwise remain hidden."]
    //     ,image:"https://drive.google.com/uc?export=download&id=1F6aTL2HQqyCfgLbJEBt1MO6RueT68AK0"
    // },
    //     {word: 'Brainstorming', definition: ["Brainstorming is a creative problem-solving technique that encourages individuals or groups to generate a wide range of ideas and solutions in a free and open environment. It fosters creativity and innovation by suspending judgment and promoting collaboration."]

    //     ,image:"https://drive.google.com/uc?export=download&id=1-6d4GaZurO3FLQF5xjEJ5P99bIF6XhBY"
    // },
    //     {word: 'Brand Equity', definition: ["Brand equity represents the intangible value of a brand, including its reputation, customer loyalty, and perceived quality. Strong brand equity can lead to higher customer trust, increased sales, and a competitive advantage in the market."]
    //     ,image:"https://drive.google.com/uc?export=download&id=1nlkdmHMOeSPMnDQoVEpp6ALTDH_JT6n3"
    // },
    //     {word: 'Budgeting', definition: ["Budgeting is the process of creating a financial plan that outlines expected revenues and expenditures for a specific period, typically a fiscal year. It serves as a financial roadmap for organizations, helping them allocate resources effectively and control costs."
    
    // ]
    //     ,image:"https://drive.google.com/uc?export=download&id=1Yq_6BJF5ie0kHbq9PhrxCGOsAnjF6PW3"
    // },
    //     {word: 'Business Continuity Planning (BCP)', definition: ["Business Continuity Planning involves developing strategies, protocols, and contingency measures to ensure an organization's critical functions can continue or resume smoothly in the face of disruptions, such as natural disasters or cyberattacks. It aims to minimize downtime and safeguard data, personnel, and operations."]
    //     ,image:"https://drive.google.com/uc?export=download&id=1_WqYjlkPHYx_2EGo2iwp8yP7dKd8PleL"
    // },
    //     {word: 'Business Ethics', definition: ["Business ethics encompasses the principles and standards of conduct that guide decision-making and behavior within an organization. It focuses on moral and ethical considerations, promoting integrity, fairness, and social responsibility in business practices."]
    //     ,image:"https://drive.google.com/uc?export=download&id=1GQuvpabYWOBJyr7D-Ec0EqgCtub7dzlk"
    // },
    //     {word: 'Business Intelligence (BI)', definition: ["Business Intelligence refers to the technologies and practices used to collect, analyze, and present data to support informed decision-making within an organization. It transforms raw data into actionable insights, enabling executives and managers to make strategic choices based on empirical evidence."]
    //     ,image:"https://drive.google.com/uc?export=download&id=1qptvnb87loJYkO0VnqI7EtTmnouKvzQj"
    // },
    //     {word: 'Business Model Canvas', definition: ["The Business Model Canvas is a visual tool that helps organizations describe, analyze, and design their business models. It consists of nine key building blocks, including customer segments, value propositions, channels, customer relationships, revenue streams, key resources, key activities, key partnerships, and cost structure."]
    //     ,image:"https://drive.google.com/uc?export=download&id=1F8qIPGIRCbT6pkBzEd_ruZwb1lvlCSYm"
    // },
    //     {word: 'Business Process Reengineering (BPR)', definition: ["Business Process Reengineering is the radical redesign and reorganization of an organization's business processes to improve efficiency, reduce costs, enhance quality, and respond more effectively to customer needs. It often involves rethinking workflows, technology adoption, and organizational structure."]
    //     ,image:"https://drive.google.com/uc?export=download&id=1J1EDMUGySqp7GSsUaZUOx6BHooX2AABw"
    // },
    //     {word: 'Business Analytics ', definition: ["The process of transforming data into insights to improve business decisions. Data management, data visualization, predictive modeling, data mining, forecasting simulation, and optimization are some of the tools used to create insights from data."]
    //     ,image:"https://drive.google.com/uc?export=download&id=1JES9PAXAXVDC3le0JzrNOn_UwjK7hODp"
    // },
    //     {word: 'Business Intelligence ', definition: ["A means of performing descriptive analysis of data using technology and skills to make informed business decisions. The set of tools used for BI collects, governs, and transforms data."]
    //     ,image:"https://drive.google.com/uc?export=download&id=1T4Ydy8lUSkMjzwitK0zxNKLIelb3iZmV"
    // },
    ]
    
    
},
    {
        label:"C",
        content:[
            {word: 'CBAP', definition: [

                "The Certified Business Analysis Professional (CBAP) certification is a globally recognized credential awarded to experienced business analysts who have demonstrated their expertise and proficiency in the field of business analysis. It is considered one of the most prestigious certifications for business analysts and is administered by the International Institute of Business Analysis (IIBA).",
                <p>The Certified Business Analysis Professional certification (CBAP certification) is the designation given to those professionals who sit for and pass the CBAP exam.  For this reason, the term CBAP is often used as a shorthand term to refer to the CBAP exam itself.</p>
            ]
            ,image:"https://drive.google.com/uc?export=download&id=1OrchALpJk2nnefAf2qj61m4kBg406HsS"
        },
            {word: 'CCBA', definition: [
                "The Certification of Competency in Business Analysis (CCBA) is a professional certification for individuals who have demonstrated their knowledge and skills in the field of business analysis. The CCBA certification is offered by the International Institute of Business Analysis (IIBA), a globally recognized organization dedicated to promoting excellence in business analysis.",
                <p>To earn the CCBA certification, candidates must meet specific eligibility requirements, which typically include a minimum number of years of work experience in business analysis, as well as the successful completion of the CCBA exam. The exam assesses a candidate's proficiency in various business analysis knowledge areas, including but not limited to business analysis planning and monitoring, elicitation and collaboration, requirements analysis, and solution assessment and validation.</p>
            ]
            ,image:"https://drive.google.com/uc?export=download&id=1RDbK6Bwt8KhLkjzwsYClKO0iXpsFF464"
        },
            {word: 'Class Diagram', definition: ["A class diagram is a UML diagram that describes the structure of a system by showing the classes of a system, the attributes and operations that belong to each class, and the relationships between the classes."]
            ,image:"https://drive.google.com/uc?export=download&id=1nQrtM4tNeSm-dD2E3r5dVimZjeTZOapo"
        },
            {word: 'Concentration Ratio', definition: ["Concentration Ratio (CR) is a measurement used to understand the level of competition that exists within a market or industry in which a company operates."]
            ,image:"https://drive.google.com/uc?export=download&id=1uYqmtd6y36Nj6g3wlZClpEe2dBEESnlU"
        },
            {word: 'Communication Diagram', definition: ["A communication diagram is a UML 2.0 diagram which models the objects or parts of a system, the interactions (or messages) between them, and the sequence in which these interactions occur. A communication diagram models this as a free-form arrangement of objects or parts of a system. The free-form arrangement of objects lends itself well to showing the sequenced interactions in a more compact space."]
            ,image:"https://drive.google.com/uc?export=download&id=1GIk38zQnlfQ4oOpz92CugmFnlyOmJCSa"
        },
            {word: 'Context Diagram', definition: ["A context diagram is a special form of a data flow diagram that represents an entire system as a single process and highlights the interactions between the system being analyzed and other systems or people that interact with it."]
            ,image:"https://drive.google.com/uc?export=download&id=1lUMRom060mT83hu0IPKebwyzg_-VnsOW"
        },
            {word: 'Convergent Thinking', definition: ["Convergent thinking is a cognitive process that focuses on finding the single, best solution to a well-defined problem or question. It is a type of thinking characterized by narrowing down options and possibilities to identify the most appropriate and effective solution. Convergent thinking typically involves logical and analytical reasoning, as well as the application of established rules, facts, and knowledge."]
            ,image:"https://drive.google.com/uc?export=download&id=1fpbPSRw6D3lSqjrLi7Q4JepKmSe1fJtL"
        },
            {word: 'Cost Benefit Analysis', definition: ["Cost Benefit Analysis is a technique used to determine if the financial benefits of a project outweigh the associated cost of undertaking the project in the first place. For a short term project where the benefit may be an immediate one-time cash windfall this may be as simple as subtracting the total of all project costs from the total of all project benefits. If the total is positive, then the project may be worth completing."]
            ,image:"https://drive.google.com/uc?export=download&id=13iB7TeysYZpoCW2Gk-7zwasauItjMs5d"
        },
            {word: 'CRUD', definition: [

                "CRUD stands for Create, Read, Update, and Delete. It is an acronym used in computer programming and database management to describe the four basic operations that can be performed on a database or data repository. These operations are fundamental for managing and manipulating data in various software applications and systems:" ,
            <p> Create (C): This operation involves adding new records or data entries to a database. It typically includes inserting data into a database table or collection. For example, creating a new user account, adding a new product to an inventory database, or entering customer information.</p>,
            <p>Read (R): The Read operation is about retrieving and viewing existing data from a database. It includes querying the database to access specific records or information. For instance, reading a user's profile, searching for products by category, or displaying a list of orders.
            </p>,
            <p> Update (U): Update allows for modifying or changing existing data within the database. It includes editing, revising, or altering records. Common examples include updating a user's contact information, changing the quantity of a product in stock, or modifying an employee's job title.
            </p>,
            <p>Delete (D): The Delete operation is used to remove data or records from the database. It involves permanently erasing information. For instance, deleting a user account, removing an item from a shopping cart, or deleting a post from a social media platform.
            </p>
             ]
            ,image:"https://drive.google.com/uc?export=download&id=1LsnWkBeSmWv_NEmXCuKIePxMTJIKQ8cW"
        },
        //     {word: 'Cross-Functional Team', definition: ["A cross-functional team consists of individuals from different departments or functional areas within an organization who collaborate on a specific project or task. These teams bring diverse skills and perspectives to problem-solving and project execution."]
        //     ,image:"https://drive.google.com/uc?export=download&id=1G06wpgRadrppQ092omBBwyNa0nUaKCwB"
        // },
        //     {word: 'Customer Acquisition Cost (CAC)', definition: ["Customer Acquisition Cost represents the total expenses incurred by a company to acquire a new customer. It includes marketing and sales expenditures, customer onboarding costs, and related expenses, helping organizations assess the efficiency of their customer acquisition efforts."]
        //     ,image:"https://drive.google.com/uc?export=download&id=1pid2MfZK_72vERTaei6rJdub6PypzyLD"
        // },
        // {word: 'Customer Lifetime Value (CLV)', definition: ["Customer Lifetime Value predicts the net profit generated from a customer over their entire relationship with a business. It factors in their purchasing behavior, loyalty, and retention, guiding marketing and customer relationship strategies."]
        //     ,image:"https://drive.google.com/uc?export=download&id=17MbLkCryFWPgTHmG7h-EcY9_Mw1lE4Fx"
        // },
        //     {word: 'Customer Relationship Management (CRM)', definition: ["CRM encompasses the practices and technologies used to manage interactions with current and potential customers. It includes capturing and analyzing customer data, improving customer service, and fostering strong customer relationships to drive sales and loyalty."]
        //     ,image:"https://drive.google.com/uc?export=download&id=1dtt2L2qi8pEyCoWXLzFRrIMDGbxC3HLY"
        // },
           
        ]
    },
    {
        label:"D",
        content:[

            {word: 'Database View', definition:["A database view is a stored query that returns data from one or more database tables. The stored query, or view, is a virtual table. Once you have defined a view, you can reference it just as you would any other table in a database. Since the view is the result of a stored query, it does not contain a copy of the data itself. Instead, it references the data in the underlying base tables."] 
             ,image:"https://drive.google.com/uc?export=download&id=1SFdsd21XInXMVoFZFrbf228axO4Jpf8z"
        }
,{word: 'Data Flow Diagram', definition: ["A data flow diagram models the system as a network of functional processes and its data. It documents the system’s processes, data stores, flows which carry data, and terminators which are the external entities with which the system communicates."]
         ,image:"https://drive.google.com/uc?export=download&id=1AEdBRtm53uOzU4tNxHqa1XqYdLAhOfv1"
}
,{word: 'Decision Table', definition:["A decision table is an unambiguous and compact technique for modeling complicated logic using several sets of conditions in a tabular format.  It is often used to model logic that may otherwise require many sentences or paragraphs to convey."] 
     ,image:"https://drive.google.com/uc?export=download&id=1QgOFaD93h8l73-DRQjFuc8QVM_52Tm7H"
}
,{word: 'Decision Tree', definition: ["A decision tree graphically represents a series of decision points with branching occurring at each decision point forming a treelike structure.  A decision tree maps out each possible outcome and will often also include the probability of each outcome."]
     ,image:"https://drive.google.com/uc?export=download&id=1LLARtempZJ5pvNng5Hvkm23BxbL9RHzz"
}
,{word: 'Discount Rate', definition: ["The discount rate is the percentage rate used to reduce future cash flow values for each year in the future that they occur. This is necessary to determine what the comparable cash flow amount would be in present terms."]
     ,image:"https://drive.google.com/uc?export=download&id=1mxj03ILyCTfvGIc_qLLrZN50TRvrfloi"
}
,{word: 'Divergent Thinking', definition: ["Divergent thinking is a creative process that involves generating numerous unconventional ideas or solutions to a problem. It encourages exploring multiple perspectives, producing a high quantity of ideas, and deferring judgment during brainstorming sessions. This open-ended approach is valuable in fostering creativity and innovation across various fields."]
     ,image:"https://drive.google.com/uc?export=download&id=1CMjYfN6Uh7PBJJPBa2vPRMeAGnU7w4u8"
}
// ,{word: 'Data Engineer', definition: ["An IT worker whose primary job is to prepare data for analytical or operational uses. These software engineers are typically responsible for building data pipelines to bring together information from different source systems."]
//      ,image:"https://drive.google.com/uc?export=download&id=1yhveau-Ju9cXDYc_A_a5CWscK871rDBU"
// }
// ,{word: 'Data Engineering ', definition: ["The practice of designing and building systems for collecting, storing, and analyzing data at scale."]
//      ,image:"https://drive.google.com/uc?export=download&id=1OXZOYfirzP9giQVaCdHmBDzZK1X67z7g"
// }
// ,{word: 'Dataframe', definition: ["A data structure that organizes data into a 2-dimensional table of rows and columns, much like a spreadsheet."]
//      ,image:"https://drive.google.com/uc?export=download&id=1U13Iqq9kCjEdcx0TNrik3Uqk2ijKe8Wa"
// }
// ,{word: 'Data Governance', definition: ["Data governance is everything you do to ensure data is secure, private, accurate, available, and usable. It includes the actions people must take, and the processes they must follow."]
//      ,image:"https://drive.google.com/uc?export=download&id=1dEOQBM-UqvsEGZfHBvp_vBTRvQ6GpnJh"
// }
// ,{word: 'Data Journalism ', definition: ["A type of journalism reflecting the increased role that numerical data is used in the production and distribution of information in the digital era."]
//      ,image:"https://drive.google.com/uc?export=download&id=1X5nT4T56sptr8TNBy5kYFC6KfG5Xqrd4"
// }
// ,{word: 'Data Lake ', definition: ["A centralized repository designed to store, process, and secure large amounts of structured, semi-structured and unstructured data."]
//      ,image:"https://drive.google.com/uc?export=download&id=1dqwcnRHKl1S4Q9fTR9DQiguswMVERyFn"
// }
// ,{word: 'Data Literacy ', definition: ["The ability to read, write and communicate data in context, including an understanding of data sources and constructs, analytical methods and techniques applied, and the ability to describe the use case, application, and resulting value."]
//      ,image:"https://drive.google.com/uc?export=download&id=1mqlmd5i9LQgJ3WvSm3G0EvzMN77YouoA"
// }
// ,{word: 'Data Mining ', definition: ["Data mining is the process of discovering hidden patterns, correlations, and insights from large datasets using various statistical and computational techniques. The goal of data mining is to extract valuable information and knowledge from data that can be used for decision-making and prediction."]
//      ,image:"https://drive.google.com/uc?export=download&id=1suOGQ-sTMFOOOZsX5yGUnWdgRTBtPbm9"
// }
// ,{word: 'Developers ', definition: ["The ones that help create new products or improve existing ones, which helps the company effectively meet consumer's needs"]
//      ,image:"https://drive.google.com/uc?export=download&id=18oYJUK-rIDNvpKzcUe3NdT5G-gAyiTh9"
// }
 
// ,{word: 'Data Modeling ', definition: ["The process of creating a visual representation of either a whole information system or parts of it to communicate connections between data points and structures."]
//      ,image:"https://drive.google.com/uc?export=download&id=18Z_blvnL3oNidCdOd_Y1jb7EO5rFeXdB"
// }
// ,{word: 'Data Pipeline ', definition: ["A method in which raw data is ingested from various data sources and then ported to data store, like a data lake or data warehouse, for analysis."]
//      ,image:"https://drive.google.com/uc?export=download&id=1ibnzFkY3WKBFagzcOAGQ1qtpjJrgDP5R"
// }
// ,{word: 'Data Science ', definition: ["The study of data to extract meaningful insights for business. It is a multidisciplinary approach that combines principles and practices from the fields of mathematics, statistics, artificial intelligence, and computer engineering to analyze large amounts of data."]
//      ,image:"https://drive.google.com/uc?export=download&id=1t9qVIFoGKPNAMnh4KRfjmY4X4Uy3nmn3"
// }
// ,{word: 'Data Scientist ', definition: ["An analytics professional who is responsible for collecting, analyzing and interpreting data to help drive decision-making in an organization."]
//      ,image:"https://drive.google.com/uc?export=download&id=1jjuh4pFnQgXre5kRj6uKvo3_PoWGagKj"
// }
// ,{word: 'Data Set', definition: ["A collection of related, discrete items of related data that may be accessed individually or in combination or managed as a whole entity."]
//      ,image:"https://drive.google.com/uc?export=download&id=1UovZipMSgamY8nAQnyYvNTa9iE-BA1E8"
// }
// ,{word: 'Data Structure ', definition: ["In data science, a data structure refers to the way that data is organized and stored in a computer system or database. A well-designed data structure is important for efficient and effective data storage, retrieval, and manipulation."]
//      ,image:"https://drive.google.com/uc?export=download&id=1s944_MRM7TlrP8kTXts-14HRuGHX_vzT"
// }

// ,{word: 'Data Visualization ', definition: ["Data visualization is the graphical representation of data and information using charts, graphs, and other visual elements. The goal of data visualization is to help users understand complex data and patterns more easily and to identify trends, outliers, and relationships."]
//      ,image:"https://drive.google.com/uc?export=download&id=1iS8v5wq3f2GCnYX2t4PErz3KX_dt3XdV"
// }
// ,{word: 'Data Warehouse', definition: ["A data warehouse is a large, centralized repository of data that is used for reporting, data analysis, and business intelligence purposes. The data in a data warehouse is typically organized around specific subjects or domains, such as sales, marketing, or finance, and is often used to support decision-making processes."]
//      ,image:"https://drive.google.com/uc?export=download&id=1Bv0AgwQys3WoSenc7KNiTa8uz8dYwDjM"
// }
// ,{word: 'Data Wrangling ', definition: ["The process of removing errors and combining complex data sets to make them more accessible and easier to analyze."]
//      ,image:"https://drive.google.com/uc?export=download&id=14CLg3CdeLhiSn4HTGOVEYPvPhoLyU4Ur"
// }
// ,{word: 'Decision Tree', definition: ["A non-parametric supervised learning algorithm, which is utilized for both classification and regression tasks. It has a hierarchical, tree structure, which consists of a root node, branches, internal nodes and leaf nodes."]
//      ,image:"https://drive.google.com/uc?export=download&id=1WxJqRax5-4BwsndqaeBt3OfGZtrxi61I"
// }
// ,{word: 'Deep Learning ', definition: ["A subset of machine learning that trains a computer to perform human-like tasks, such as speech recognition, image identification and prediction making. It improves the ability to classify, recognize, detect and describe using data."]
//      ,image:"https://drive.google.com/uc?export=download&id=1C0j_zCq31p9MC308ybjYwcnH7NN5mUvv"
// }
// ,{word: 'Dimensionality Reduction ', definition: ["It is the transformation of data from a high-dimensional space into a low-dimensional space so that the low-dimensional representation retains some meaningful properties of the original data, ideally close to its intrinsic dimension."]
//      ,image:"https://drive.google.com/uc?export=download&id=1txgASKjvdyIo2bVaOGALE6TREfZjtLlD"
// }

]
    },
    {
        label:"E",
        content:[

            {word: 'Elicitation (BABOK Knowledge Area)', definition: ["A BABOK 2.0 Knowledge Area that describes the steps required to elicit requirements from stakeholders.  It includes preparing for elicitation by identifying a combination of techniques that will be used, conducting the elictation using the idetified techniques, documenting the elicitation results, and confirming what has been documented."]
            ,image:"https://drive.google.com/uc?export=download&id=17I4hCdsOT8EVqHoI4aGRMzhuMW_nAzre"
        },
            {word: 'Enterprise Analysis (BABOK Knowledge Area)', definition: ["Enterprise Analysis in the BABOK framework involves understanding an organization's needs, defining clear business objectives, identifying opportunities for improvement, assessing current capabilities and gaps, determining solution approaches, performing feasibility and risk assessments, defining detailed business requirements, and ensuring alignment with the organization's strategic goals. It is a critical process that helps business analysts make informed decisions and guide organizations toward achieving their objectives."]
            ,image:"https://drive.google.com/uc?export=download&id=17pZIEZzmTuCh_q6NSJoMh0AgkZGS4Aij"
        }
,{word: 'Entity Relationship Diagram', definition: ["An entity-relationship diagram models the relationships between entities in a database.  Standard symbols are used to represent different types of information.  The conventional notation uses rectangles to represent entities (nouns), diamonds to represent relationships (verbs) and ovals to represents attributes of entities.  Other notations are sometimes used."]
        ,image:"https://drive.google.com/uc?export=download&id=1F6bQC62xK7T2COGESsIKD7ansV62ZWfi"
},
{word: 'Exception Flow', definition: ["A use case exception flow is an unintended path through the system usually as a result of missing information or system availability problems. Exception flows represent an undesirable path to the user. However, even though the exception flow has occurred the system  will ideally react in a way that recovers the flow and provide some useful information to the user."]
        ,image:"https://drive.google.com/uc?export=download&id=1jk0Z4mUpyQF_uaCrxBAbe2LC30K2QeuQ"
}

        ]
    },
    {
        label:"F",
        content:[

            {word: 'Fact Model', definition: ["A fact model is a static model which structures business knowledge about core business concepts and business operations. It is sometimes called a business entity model. The fact model focuses on the core business concepts (called terms), and the logical connections between them (called facts). The facts are typically verbs which describe how one term relates to another."]
            ,image:"https://drive.google.com/uc?export=download&id=1jMCvc_Y1leRDPZtBC1STOIexGJmKa1In"
        }
,{word: 'Fishbone Diagram', definition: ["A fishbone diagram is a problem-analysis tool that derives it’s name from it’s shape which resembles the skeleton of a fish. Developed by Dr. Kaoru Ishikawa, a Japanese quality control statistician, the fishbone diagram is a systematic way of looking at an effect and identifying and capturing the causes that contribute and result in that particular effect. For this reason, it is sometimes referred to as a cause and effect diagram."]
        ,image:"https://drive.google.com/uc?export=download&id=1tQ_JCDG6JKeCx9Okgii-qg73bwTirQC2"
}
,{word: 'Financial Ratio Analysis', definition: ["Financial Ratio Analysis is the evaluation and interpretation of a company’s financial data using standard financial ratios or accounting ratios to determine a company’s financial state or condition. A financial ratio or accounting ratio is a ratio of two values that are taken for a company financial statements (Balance Sheet, Income Statement, Statement of CashFlows, Statement of Retained Earnings)."]
    ,image:"https://drive.google.com/file/d/1_vtx5uChLzGeh4bko0R06DBtqJnj7ElI/view?usp=drive_link"
}
// ,{word: 'Feature Creep', definition: ["A phenomenon that happens when a product team continues adding features to the point that they undermine the product's value"]
//     ,image:"https://drive.google.com/uc?export=download&id=10l4IKkwdlVgKlaiSwqkeJu7-cEsEQGZp"
// }
 
// ,{word: 'Feature Engineering', definition: ["The process of selecting, transforming, extracting, combining, and manipulating raw data to generate the desired variables for analysis or predictive modeling."]
//     ,image:"https://drive.google.com/uc?export=download&id=187WmHHfDQNP6WJn8YDs_mvPvlxuNlYtd"
// }
// ,{word: 'Feature Selection ', definition: ["The method of reducing the input variable to your model by using only relevant data and getting rid of noise in data."]
//     ,image:"https://drive.google.com/uc?export=download&id=1gqJiF-rB3B2Psy94P7M7kFV3PyrWi-ze"
// }
       ]
    },
    {
        label:"G",
        content:[
            {word: 'Gap Analysis', definition: ["Gap analysis is the process of comparing two things in order to determine the difference or “gap” that exists between them. It involves comparing where the organization is now with where it wants to be and developing a plan to bridge the gaps. This helps organizations prioritize and allocate resources effectively to achieve their goals."]
            ,image:"https://drive.google.com/uc?export=download&id=1Zs6LeVRLeFg7GYeO7qJ8OqOsRcAQwqsu"
        }
        ]
    },
    {
        label:"H",
        content:[

            {word: 'Herfindahl Hirschman Index', definition: [
                "The Herfindahl-Hirschman Index (HHI) is a measure of market concentration and competitiveness within an industry or market. It's calculated by summing the squares of the market shares of all firms or participants within that industry or market.",
                <p>In essence, the HHI quantifies how concentrated or competitive a market is. A higher HHI value indicates greater market concentration, meaning a few large firms dominate the market. Conversely, a lower HHI suggests a more competitive market with many smaller firms.</p>,
                <p>The HHI is commonly used in antitrust and competition analysis. Regulatory authorities and policymakers use it to assess the level of competition within an industry. In highly concentrated markets, there may be concerns about monopolistic behavior, which can lead to antitrust actions. Conversely, in competitive markets, there is generally less regulatory intervention.</p>
            ]
             ,image:"https://drive.google.com/uc?export=download&id=10zvYj4lHxAJxp97z-iwf6ynORdPSL90i"
        },
            {word: 'HyperText Markup Language (HTML)', definition: ["HTML (HyperText Markup Language) is the standard language for creating web pages. It uses tags to structure content, create links, embed multimedia, and build forms. HTML provides a framework for web development, and it's essential for creating accessible and consistent web content across browsers and devices."]
             ,image:"https://drive.google.com/uc?export=download&id=1DPqs_Ev6fUr5bAo8bnMRNsD9o3XQF3Nm"
        }
        //     ,{word: 'Hypothesis ', definition: [" In data science, a hypothesis is proven through data analytics. This means, once the hypothesis is defined, you can collect data to determine whether it provides enough evidence to support the hypothesis."]
        //      ,image:"https://drive.google.com/uc?export=download&id=1u6z7WQQZXz-wq7ltORFmMj78LO1mSp0v"
        // }
        ]
    },
    {
        label:"I",
        content:[

//             {word: 'Innovation', definition: ["Innovation is the process of creating and implementing new ideas, products, processes, or services that add value to an organization. It fuels growth, competitiveness, and adaptability in dynamic markets."]
//             ,image:"https://drive.google.com/uc?export=download&id=17VqqU7X5ygXqKPUTCP2aI43i5MLQ0d7t"
//         }, {word: 'Innovation Pipeline', definition: ["The innovation pipeline is a structured process for generating, evaluating, and developing new ideas and innovations within an organization. It helps manage the flow of ideas from inception to implementation, ensuring that promising innovations are nurtured and brought to market."]
//         ,image:"https://drive.google.com/uc?export=download&id=1iJifwOVv9WyYbemhkZ62E2qi90x4A8p4"
//     }, {word: 'Intangible Assets', definition: ["Intangible assets are non-physical assets with economic value, such as intellectual property (patents, trademarks, copyrights), brand reputation, customer relationships, and proprietary technology. These assets contribute to a company's competitive advantage and financial worth."]
//     ,image:"https://drive.google.com/uc?export=download&id=1y6U6Yrz512x-aQSgQmfTkyG72QG5FNVH"
// }, {word: 'Intrapreneurship', definition: ["Intrapreneurship refers to the practice of fostering entrepreneurial behavior, creativity, and innovation within an organization. It encourages employees to act as entrepreneurs, identifying opportunities, and driving new initiatives or projects within the corporate framework."]
// ,image:"https://drive.google.com/uc?export=download&id=1n0jiem6jEr378sWCJ8qjUSpk9XuB13Dw"
// }
        ]
    },
    {
        label:"J",
        content:[
            {word: 'Joint Application Development', definition: ["Joint Application Development is a requirements-definition and software system design methodology in which stakeholders, subject matter experts (SME), end-users, business analysts, software architects and developers attend collaborative workshops (called JAD sessions) to work out a system's details."]
                     ,image:"https://drive.google.com/uc?export=download&id=1M0EcrZVWd6RP25jJDIc0__jMbE_zo_l5"
                 },
               ]
    },
    {
        label:"K",
        content:[

            
{word: 'Knowledge Areas', definition: [
    "Specific domains or categories within a particular subject or discipline that encompass fundamental aspects of that field. Knowledge areas serve to organize and define the scope of knowledge within a subject, facilitating structured study, teaching, and understanding.",
    <p>Example: In project management, knowledge areas include project integration management, scope management, time management, and others, each addressing distinct aspects of project execution and control.</p>,
    <p>Knowledge areas provide a framework for education, certification, and practice within a field, aiding individuals and organizations in systematically mastering essential concepts and skills.</p>
]
            ,image:"https://drive.google.com/uc?export=download&id=1d7n-yqv4rRoc6iX8vtIqlATiAIQnPcfU"
}
// ,{word: 'Knowledge Management', definition: ["Knowledge Management is the process of capturing, storing, organizing, and sharing an organization's collective knowledge and expertise to improve decision-making, problem-solving, and innovation. It involves the use of technology, practices, and culture to leverage knowledge assets effectively."]
//     ,image:"https://drive.google.com/uc?export=download&id=1ukYYAxaRxH9Di24DQVX_qUttcl2h1nF-"
// },{word: 'Knowledge Transfer', definition: ["Knowledge Transfer is the practice of sharing knowledge and expertise within an organization to improve performance, foster learning, and ensure continuity when employees transition or depart. It helps preserve institutional knowledge and ensures that critical skills are passed on to others."]
// ,image:"https://drive.google.com/uc?export=download&id=1MTMCMD58Abpv5aPesSaAJ_eUJh5VH9zQ"
// }
 ]
    },
    {
        label:"L",
        content:[

            
{word: 'Logical Data Dictionary', definition: ["A centralized repository of logical data elements and other metadata about them. This may include the meaning of a piece of data, relationships to other logical data, origin, usage, type and length. Logical data usually models the real world far more closely that of physical data since physical data and its structure is usually optimized for system performance purposes."]
            ,image:"https://drive.google.com/uc?export=download&id=1XBbovPT8TUD_iy-jiT10GLU6hC4ZbYoK"
}
// ,{word: 'Lean Management', definition: ["Lean Management is an approach that focuses on minimizing waste, optimizing processes, and maximizing value in operations and production. It emphasizes principles such as continuous improvement, customer value, and efficient resource allocation."]
//     ,image:"https://drive.google.com/uc?export=download&id=1348Hxjxt46ttJMwUDETUMtXyT1F0rUce"
// }
// ,{word: 'Logistics Regression ', definition: ["A statistical analysis method to predict a binary outcome, such as yes or no, based on prior observations of a data set. "]
//     ,image:"https://drive.google.com/uc?export=download&id=186mtFYZ0ltZkW6ja1pVpix173qx14Odu"
// }
 ]
    },
    {
        label:"M",
        content:[
            {word: 'Management By Walking Around', definition: ["Management By Walking Around (MBWA) is a popular management technique used by top-level managers in traditional brick and mortar businesses where managers walk around and observe the work, culture, atmosphere, and problems that may exist."]
            ,image:"https://drive.google.com/uc?export=download&id=1lLKnij4bz4DolXQcUeWW8WNFAgKEgCqN"
        }
,{word: 'Meta-Data Repository ', definition: ["A 'metadata repository' is a centralized database for storing and managing descriptive information (metadata) about data assets and information resources. It helps users understand, organize, and utilize data effectively by providing insights into data characteristics, relationships, and usage. This repository supports data management, governance, and search, enhancing overall data practices."]
        ,image:"https://drive.google.com/uc?export=download&id=1DdXjbIo2qi0lzOVwxiyZ5Yj9aD4N63h7"
}
,{word: 'Model-Based-Management', definition: ["Model-Based Management refers to the activity of managing and making informed decision regarding the future direction of a business, process, or system(s) based on information gleaned and understood from models that document the current state."]
    ,image:"https://drive.google.com/uc?export=download&id=1E-TDv2VxtYZ8wmzu_jxGkJ8VdF5orp-1"
}
,{word: 'Model-View-Controller', definition: ["Model-View-Controller, or MVC, is a design and architectural pattern used to ensure that the modeling of the domain, the presentation information, and the actions taken based on user input are loosely coupled and maintained as separate classes."]
    ,image:"https://drive.google.com/uc?export=download&id=1MpHiMSWyROHVB3T6c1arAztCzmu73MHK"
}
// ,{word: 'Median ', definition: ["It is the middle point in a dataset—half of the data points are smaller than the median and half of the data points are larger."]
//     ,image:"https://drive.google.com/uc?export=download&id=1rvZychkj9wV3xFQ0G2qbaXgx8yW1z_GI"
// }
// ,{word: 'Mode', definition: ["The value which occurs the maximum number of times in a given data set."]
//     ,image:"https://drive.google.com/uc?export=download&id=1mwd_O5Qv69mmtxYNgu_an1_Wefvm3-Y0"
// }
// ,{word: 'Model Tuning ', definition: ["The experimental process of finding the optimal values of hyperparameters to maximize model performance."]
//     ,image:"https://drive.google.com/uc?export=download&id=1vkUfQqDM8P-A4fjFheCCnoMKwIu7ZN1B"
// }
// ,{word: 'Multivariate Modeling ', definition: ["A Statistical procedure for analysis of data involving more than one type of measurement or observation."]
//     ,image:"https://drive.google.com/uc?export=download&id=1IN_vK2pxY7PKiqgdNMOmPo-qs_QB2Zci"
// }
      ]
    },
    {
        label:"N",
        content:[
            {word: 'Non-Functional Requirement', definition: ["Non-functional requirements are characteristics of a system or solution which describe non-behavioral characteristics or qualities of a system.  Non Functional Requirements have also been called the 'ilities': usability, reliability, interoperability, scalability, extensibility, etc.  Non-functional requirements are also commonly referred to as quality of service (QoS) requirements or service-level requirements. More info on non-functional requirements."]
            ,image:"https://drive.google.com/uc?export=download&id=1uNRG1Y9sEJV92I7o7yncIS3_pJQ9tM3N"
        },
        //     {word: 'Natural Language Processing ', definition: ["Natural Language Processing (NLP) is a subfield of artificial intelligence (AI). It helps machines process and understands human language."]
        //     ,image:"https://drive.google.com/uc?export=download&id=14XBxShW44O8BLZv8Z6vVVfsBUKdEYSNy"
        // }
        //     ,{word: 'Normalization ', definition: ["The process of transforming the values in a dataset to the same scale is referred to as normalization."]
        //     ,image:"https://drive.google.com/uc?export=download&id=1am_K2B-sVfQ742gZtITR_NP0m9twAG4B"
        // }
        //     ,{word: 'NoSQL', definition: ["An approach to database design that enables the storage and querying of data outside the traditional structures found in relational databases."]
        //     ,image:"https://drive.google.com/uc?export=download&id=1mr3WYcbtYeYoVSlDqr3OeqbUB98-vx5v"
        // }
        //     ,{word: 'Null Hypothesis', definition: ["A type of statistical hypothesis that proposes that no statistical significance exists in a set of given observations."]
        //     ,image:"https://drive.google.com/uc?export=download&id=1x_LD_OpIPRgfADWe72A-21F7MWmQ5k7Y"
        // }
        ]
    },
    {
        label:"O",
        content:[
//             {word: 'Organizational Culture', definition: ["Organizational Culture represents the shared values, beliefs, attitudes, and behaviors that define the working environment and character of an organization. It shapes how employees interact, make decisions, and align with the organization's mission and vision."]
//             ,image:"https://drive.google.com/uc?export=download&id=1Ur8JbcFh5rfm_-s_BWq-SloejSq7JNH6"
//         }
// ,{word: 'Organizational Development', definition: ["Organizational Development involves planned efforts to enhance an organization's effectiveness, adaptability, and overall performance. It focuses on improving systems, structures, and processes, as well as nurturing employee skills and collaboration."]
//         ,image:"https://drive.google.com/uc?export=download&id=12QYXdU7aaF_4aNHZHlbDdjv8KresK6oz"
// }
// ,{word: 'Organizational Learning', definition: ["Organizational Learning is the process of acquiring, sharing, and applying knowledge within an organization to improve performance and adapt to change. It encourages a culture of continuous learning and knowledge utilization to stay competitive."]
//     ,image:"https://drive.google.com/uc?export=download&id=1mHtxRxZqQmP1gicJ8GuONf32DqcbU6CZ"
// },
// {word: 'Overfitting ', definition: ["An undesirable machine learning behavior that occurs when the machine learning model gives accurate predictions for training data but not for new data."]
//     ,image:"https://drive.google.com/uc?export=download&id=10QD9gvOQ_geFbQrLCXQjXG4Dn4Oop_e2"
// }
]
    },
    {
        label:"P",
        content:[
            
{word: 'PDCA Method', definition: [

    "A 4-step, iterative method commonly used for Business Process Improvement.  The PDCA method, also known as Plan-Do-Check-Act, is a problem-solving and improvement cycle. It consists of four stages:",
    <p>Plan: Identify the problem, set goals, and create a plan.</p>,
    <p>Do: Implement the plan.</p>,
    <p>Check: Evaluate the results.</p>,
    <p>Act: Take action based on the results.</p>,
    <p>This iterative process fosters continuous improvement and adaptability in various fields and industries.</p>
]
            ,image:"https://drive.google.com/uc?export=download&id=1_rsYOV7XMgv9Y6lmRCVYPrw4qqXQm1X4"
}
,{word: 'Primary Actor', definition: ["Primary actors are people, or at times even other systems, that require the assistance of the system under consideration to achieve their goal.  They initiate the use cases of the system (business processes or application functionality).  A use case within the system may have more than one primary actor, since more than one type of role may initiate the processes or functionality of the system."]
    ,image:"https://drive.google.com/uc?export=download&id=1P5LohYSv_6vwXcL4kUlFn71GDGBM6M0C"
}
,{word: 'Problem Domain', definition: ["Problem Domain describes the area undergoing analysis, and includes everything that needs to be understood in order to achieve the goal of the project.  This may includes all inputs and outputs of a process, any related systems, and internal and external project stakeholders."]
    ,image:"https://drive.google.com/uc?export=download&id=1EgKCOQa3e0-UWnOgOXXWFSZTuFhC_m_Z"
}
,{word: 'Pseudocode', definition: ["Pseudocode is a notation that combines some of the structure of a programming language, such as IF-ELSE and DO WHILE constructs, with a natural language, such as plain English.  This allows writers of specification to eliminate a lot of the ambiguity that typically arises when trying to describe logic and computations using strictly a natural language."]
    ,image:"https://drive.google.com/uc?export=download&id=1HfUFsHiJk0myKfJiKg5vFA6Te9O471fy"
}
// ,{word: 'PERT Chart', definition: ['It stands for program evaluation and review technique - a tool used to schedule, organize, and map out tasks within a project.']
//     ,image:"https://drive.google.com/uc?export=download&id=10QD9gvOQ_geFbQrLCXQjXG4Dn4Oop_e2"
// }
 
// ,{word: 'Python ', definition: ["An interpreted, object-oriented, high-level programming language with dynamic semantics developed by Guido van Rossum."]
//     ,image:"https://drive.google.com/uc?export=download&id=1hKbuC66VqhC6Ook1HJSM6as9gSilgIaY"
// }
    ]
    },
    {
        label:"Q",
        content:[
            {word: 'Quality Assurance', definition: ["Quality Assurance (QA) is a systematic process that ensures products or services meet specific quality standards. It involves setting standards, planning, monitoring, preventing issues, taking corrective actions, and continuously improving processes. QA is critical for delivering consistent, safe, and high-quality results across various industries and fields."]
            ,image:"https://drive.google.com/uc?export=download&id=1ggMyMCmjfsKp_BL-5XdR6pbczpYteiJo"
        }
        ,{word: 'Quality Control', definition: ["Quality Control (QC) is a process that checks and verifies the quality of products or services during production or delivery. It involves inspection, testing, data analysis, and reporting to identify and fix defects or deviations from quality standards. QC works alongside quality assurance (QA) to ensure products or services meet customer expectations and quality criteria."]
            ,image:"https://drive.google.com/uc?export=download&id=1ZR1gc6IGXjv6eSD-CYIKtnOy3-zzdweV"
        }
        ]
    },
    {
        label:"R",
        content:[
            // {word: 'Rapid Application Development ', definition: ''}

{word: 'Requirement', definition: ["Requirement is a documented description of a condition or capability essential to solving a problem, achieving an objective, or meeting a contract, standard, or specification. It's a specific need expressed by a stakeholder that must be addressed or fulfilled by a solution. Requirements serve as the foundation for designing and developing solutions that meet these specified needs and expectations."]
            ,image:"https://drive.google.com/uc?export=download&id=10uYpPSYkkOR1Gubf-lIlytjLD0wxZD0z"
}
,{word: 'Requirements Analysis (BABOK knowledge area)', definition: [
    "Requirements Analysis in BABOK involves understanding and documenting stakeholder needs, validating and prioritizing requirements, resolving conflicts, and managing them throughout the project. It's crucial for aligning project outcomes with stakeholder expectations.",
    <p>It describes the activities and methods used to analyze stated requirements and transform them into a potential solution which possesses the capabilities that will fulfill the stakeholder needs.</p>
]
    ,image:"https://drive.google.com/uc?export=download&id=1A7DiSjaUyMpLU46-L81CcuGWFGMkkNXc"
}
// ,{word: 'Rational Product Management ', definition: ['Rational Product Mnagement entails to pursuing product/service development from a shared pool of improvement-oriented business resources, i.e., channeling available time, finances, personnel, equipment, and other necessary resources to a centralized and comprehensive product/service development initiative.']
//     ,image:"https://drive.google.com/uc?export=download&id=1hKbuC66VqhC6Ook1HJSM6as9gSilgIaY"
// }
 
,{word: 'Requirements Management and Communication', definition: ["Requirements Management and Communication is a critical knowledge area within the Business Analysis Body of Knowledge (BABOK). It focuses on systematically organizing, tracking, and communicating requirements throughout the project's lifecycle, ensuring that stakeholders' needs are met, and the project remains aligned with its objectives."]
    ,image:"https://drive.google.com/uc?export=download&id=1aQomdziZXiIz1hSwkLYWWTvlvNJopXwj"
}
,{word: 'Requirements Traceability Matrix', definition: ["A Requirements Traceability Matrix is a tabular format that provides the ability to follow and audit the life of a requirement, in both a forward and backward direction: from its origins, through its realization in the design and functional specifications, to its eventual development and deployment and use, and through subsequent rounds of modification and refinement."]
    ,image:"https://drive.google.com/uc?export=download&id=1vvb-HtHIuyWlAFOy24Y7WxNnS832YIUb"
}
,{word: 'Role', definition: ["In the context of Business Analytics, the term 'role' typically refers to a specific function or position within an organization that is responsible for performing certain tasks or activities related to data analysis and decision-making."]
,image:"https://drive.google.com/uc?export=download&id=1bf1ZrQuUbn7Pf4zemWu39m4aiHPYqdmp"
},
{word: 'RuleSpeak', definition: ["RuleSpeak is a set of guidelines for expressing business rules using a natural language (such as English). Rulespeak is not a language or syntax itself but rather a set of guidelines to facilitate the creation of business rules that are concise, consistent, and less ambiguous. RuleSpeak is fully consistent with the OMG’s SBVR standard."]
,image:"https://drive.google.com/uc?export=download&id=1r66TOKhIHDDr4HR01MsHcHGvnzskQdDN"
}
  ]
    },
    {
        label:"S",
        content:[
            {word: 'Scrum', definition: ["Scrum is an agile framework for managing projects and developing products. It includes roles (Product Owner, Scrum Master, Development Team), artifacts (Product Backlog, Sprint Backlog, Increment), and events (Sprint, Sprint Planning, Daily Scrum, Sprint Review, Sprint Retrospective). Scrum emphasizes transparency, inspection, and adaptation to deliver value iteratively and incrementally. It's widely used in software development and promotes flexibility and customer collaboration."]
            ,image:"https://drive.google.com/uc?export=download&id=1YaL-EShErutqkzGbrYsewWb62GyjooFW"
        }
,{word: 'Secondary Actor', definition: [
    "The secondary actor is a supporting actor who interacts with the system but is not the primary user or main stakeholder. They may trigger certain system functions or be involved in specific use cases, but their role is less central than that of the primary actor.",
    <p>Secondary actors can be important in defining the complete set of interactions and use cases for a system, as they represent additional parties or systems that the software must accommodate, even if they are not the primary beneficiaries of the system's functionality.</p>
]
        ,image:"https://drive.google.com/uc?export=download&id=1sDsiw2BAEWTz-EgQkx1l12bC2kevTB0B"
}
,{word: 'Sequence Diagram', definition: ["A Sequence Diagram is a visual representation in software engineering that illustrates the interactions and communication between various components or objects within a system or software application. It uses lifelines to represent objects, messages to depict interactions, and activation bars to show when an object is actively processing. Sequence diagrams are essential for understanding how different parts of a system collaborate to achieve specific tasks and are useful for design, communication, and performance analysis in software development."]
    ,image:"https://drive.google.com/uc?export=download&id=1S8517vyDax43LwBazAmUVLZLYozdK5d3"
}
,{word: 'SIPOC Diagram', definition: ["The SIPOC diagram is a tool that is used to outline the scope of a process improvement initiative (often as part of a Six Sigma improvement project). The tool captures all of the relevant elements of the process under consideration.  The diagram's name is an acronym for the elements that need to be identified and documented. (S) – Suppliers: Who supplies the inputs to the process under consideration, (I) – Inputs: What are the inputs to the process, (P) – Process: What are the steps of the process that is being improved upon, O – Outputs: What are the outputs of the process, C – Customers: Who are the customers or beneficiaries of the outputs of the process."]
    ,image:"https://drive.google.com/uc?export=download&id=11-jdV7C_fjySk86E_V3TWnTZfHp7ltJ6"
}
,{word: 'Six Sigma', definition: ["Six Sigma is a systematic methodology used in quality management and process improvement. It aims to reduce defects and improve process efficiency through the DMAIC (Define, Measure, Analyze, Improve, Control) approach. The goal is to achieve a high level of quality by minimizing defects, often to less than 3.4 defects per million opportunities. Six Sigma relies on data-driven decision-making and is implemented by certified professionals with varying levels of expertise."]
    ,image:"https://drive.google.com/uc?export=download&id=1FlHt2yBRJgDSkpfB0opFOY8GHywRWR4-"
}
,{word: 'Solution Assessment and Validation', definition: ["'Solution Assessment and Validation' is a knowledge area in business analysis that focuses on thoroughly evaluating and confirming the suitability of a proposed solution before implementation. This process involves assessing the solution's alignment with business needs, feasibility, risks, and benefits. It also includes validating the solution's functionality, performance, and compliance with requirements and standards. The outcomes of this assessment and validation are documented, and recommendations are made regarding the solution's readiness for implementation. This knowledge area ensures that the chosen solution will effectively address the business problem or opportunity and meet stakeholder expectations."]
    ,image:"https://drive.google.com/uc?export=download&id=1MOkzUEB2EQipsip8YK9MxXAnAulv7zdD"
},
{word: 'Stakeholder Analysis', definition: ["Stakeholder Analysis is the process of identifying project stakeholders, how their needs may impact the project, and the contributions that the stakeholders will make to the requirements elicitation process."]
    ,image:"https://drive.google.com/uc?export=download&id=1hPRsSFxIl_3upqsfzfLfCf-ISvHp4Qu0"
}
,{word: 'SWOT Analysis', definition: ["A structured method within business analytics and strategic management that involves the systematic examination and evaluation of an organization's internal Strengths and Weaknesses, as well as its external Opportunities and Threats, using data-driven insights. It assists businesses in leveraging data and analytics to gain a deeper understanding of their competitive position, market trends, and potential risks and opportunities. SWOT Analysis in Business Analytics aims to enhance decision-making and strategy development by incorporating data-driven assessments into the traditional SWOT framework."]
    ,image:"https://drive.google.com/uc?export=download&id=1HRk8cUcNllcelNfabBp1PB4zxC44CBQ_"
}
// ,{word: 'Sustainability', definition: ["Sustainability focuses on responsible resource use and environmental stewardship to meet current needs without compromising the ability of future generations to meet their needs. Sustainable practices encompass social, economic, and environmental dimensions."]
//     ,image:"https://drive.google.com/uc?export=download&id=1aqxs8VIG3I8ZTMiQThm-zG5B96b5I823"
// },{word: 'SWOT Analysis', definition: ["SWOT Analysis is a strategic planning tool used to assess an organization's strengths, weaknesses, opportunities, and threats. It helps identify internal and external factors that can impact strategy development and decision-making."]
// ,image:"https://drive.google.com/uc?export=download&id=1nr6BS4yTKZe-wJHh-uASmLe8NK0YnyFj"
// }
 ]
    },
    {
        label:"T",
        content:[
//             {word: 'Team Building', definition: ["Team Building involves activities and processes aimed at enhancing collaboration, communication, and cohesion among team members. It fosters a positive team dynamic and improves collective performance."]
//             ,image:"https://drive.google.com/uc?export=download&id=14bIrv9hldlHnSK7T93g5tYt9OsxE5y7d"
//         }
// ,{word: 'Time Management', definition: ["Time Management is the practice of efficiently planning and organizing tasks and activities to maximize productivity and achieve goals within a specified timeframe. It involves prioritization, goal setting, and effective scheduling."]
//         ,image:"https://drive.google.com/uc?export=download&id=1bLPLeotjHOUeo-BjMb05feongMTOC016"
// }
// ,{word: 'Total Quality Management (TQM)', definition: ["Total Quality Management is a comprehensive approach to continuous improvement, focusing on delivering high-quality products and services. It involves employee involvement, process optimization, and customer-centered practices."]
//     ,image:"https://drive.google.com/uc?export=download&id=1ctpvW7XYcyKQ204X56XgeZGaV_HTR2gM"
// }
// ,{word: 'Training Set', definition: ["A portion of a data set used to fit (train) a model for prediction or classification of values that are known in the training set, but unknown in other (future) data."]
//     ,image:"https://drive.google.com/uc?export=download&id=1Nw6LeLHB7qeJvYdkjbn6EKj5cm3ENm3F"
// }
// ,{word: 'True Negative (TN)', definition: ["An outcome where the model correctly predicts the negative class"]
//     ,image:"https://drive.google.com/uc?export=download&id=11AmqI3lgsfUgbc_e8kNYeYm4qkGHL9_Y"
// }
// // ,{word: 'Top-Down Product Strategy ', definition: ['A strategy where high-level objectives and a long-term vision are defined first and then used to inform what follows']
// //     ,image:"https://drive.google.com/uc?export=download&id=1yjHQ5eU-EEAgUs5QtW-QEEr0CgFXoJCz"
// // }
 
// ,{word: 'True Positive (TP)', definition: ["An outcome where the model correctly predicts the positive class"]
//     ,image:"https://drive.google.com/uc?export=download&id=1GJyJSSX-MGi7p9oKJkrf02k4nk_LcXtm"
// }
  ]
    },
    {
        label:"U",
        content:[
            {word: 'Use Case Diagram ', definition: ["A use case diagram is a UM L diagram that provides a high-level graphical view of the functionality (use cases) supported by the system and shows which roles (actors) can invoke each use case. This high-level view of the system provides a context for the readers of the more detailed use case specifications. "]
            ,image:"https://drive.google.com/uc?export=download&id=1nd4DlzI6agJZ1SoNgd_KZ7QzxGXxMqhT"
        }
,{word: 'Use Case Specification', definition: ["A 'Use Case Specification' is a detailed document that describes a specific function or process within a system or software application. It includes information such as the use case's name, description, actors involved, conditions, triggers, main flow of events, alternative and exceptional scenarios, postconditions, and other relevant details. This document serves as a vital reference for developers and stakeholders, providing a comprehensive understanding of how a particular aspect of the system functions and how it interacts with users and other system components."]
        ,image:"https://drive.google.com/uc?export=download&id=1ldZI0pCMz0V6MIpq91zCpffEqgHvGBEQ"
}
,{word: 'User Story', definition: ["A 'User Story' is a brief and user-centered description of a specific feature or functionality in agile software development. It follows the format 'As a [user role], I want [an action] so that [benefit or goal].' User Stories help teams and stakeholders understand and prioritize requirements by focusing on who the feature is for, what it should do, and why it's valuable. They are commonly used in agile methodologies to capture user needs and guide development."]
    ,image:"https://drive.google.com/uc?export=download&id=1FlwZRSxaRNYBrBtzeAGOMLBVYPL9xOe7"
}
,{word: 'User Interface Design Patterns', definition: ["User Interface (UI) Design Patterns are established solutions to common design challenges in user interface and user experience design. They include patterns for navigation, input forms, content display, feedback, search, call to actions, mobile design, and more. These patterns improve usability, consistency, and efficiency in designing digital products by applying proven solutions to recurring design problems. They help create user-friendly interfaces that adhere to best practices and conventions, enhancing the overall user experience."]
,image:"https://drive.google.com/uc?export=download&id=13ONt7kwd3h4mS8TdFcmksmGd17HLUavM"
}
 
 ]
    },
    {
        label:"V",
        content:[
            {word: 'View', definition: ["A view organizes diagrams into logical groups to describe a particular aspect of the system.  It is the abstraction of the system organized is such a way as to give a perspective of a related set of concerns."]
            ,image:"https://drive.google.com/uc?export=download&id=1UE3zsCLWFCxabx_tgc73FG2gQzmxYj-Y"
        },
        // {word: 'Vendor Management', definition: ["Vendor Management involves overseeing relationships with suppliers and vendors to ensure the timely delivery of quality products and services. It includes supplier evaluation, contract management, and performance monitoring."]
        //     ,image:"https://drive.google.com/uc?export=download&id=1TK5oyVz9-mx3vezx0VwoBZwdIWX-FU7p"
        // },
        // {word: 'Virtual Team', definition: ["A Virtual Team is a group of individuals who collaborate and work together on projects or tasks from different locations, often using technology and remote communication tools. Managing virtual teams requires effective communication and coordination."]
        //     ,image:"https://drive.google.com/uc?export=download&id=1y9JlsuWRMY945XwnVKST0Q3M6yW-cGEP"
        // }
   ]
    },
    {
        label:"W",
        content:[
            {word: 'Work Breakdown Structure', definition: ["The Work Breakdown Structure (WBS) documents the subdivision of tasks and effort required to complete an objective or project. It is most often depicted as a tree structure where high level tasks break down into lower level tasks. Low level tasks are typically grouped in various logical ways such as by system, subsystems, project phase, or a combination of these."]
            ,image:"https://drive.google.com/uc?export=download&id=1DqyfJWn6CLBXylOjX5M5aCAS3T5pqlMs"
        },
    //     {word: 'Working Capital', definition: ["Working Capital represents the capital available for day-to-day operational expenses and short-term obligations. It is calculated as current assets minus current liabilities and reflects a company's short-term financial health."]
    //     ,image:"https://drive.google.com/uc?export=download&id=1Fnc09GTLmMTrIirmKUNE7Ukmsof5KeCi"
    // },
//     {word: 'Work-Life Balance', definition: ["Work-Life Balance refers to the equilibrium between an employee's work responsibilities and personal life. It emphasizes the importance of maintaining mental and physical well-being while meeting professional obligations."]
//     ,image:"https://drive.google.com/uc?export=download&id=1tTu-kWfqUqurvB0ptqKl9RYQdX5e7TxH"
// }
 ]
    },
    {
        label:"X",
        content:[
            {word: 'XML', definition: ["XML stands for EXtensible Markup Language. XML was designed to transport and store data. It is a self descriptive markup language. This means that the tags used to describe the content of the XML file are not predefined, but instead the author defines his own tags and document structure."]
             ,image:"https://drive.google.com/uc?export=download&id=1O035mxQVNDZtyA71-p0F0OSigrSotyuM"
             },
        ]
    },
    {
        label:"Y",
        content:[
           
        ]
    },
    {
        label:"Z",
        content:[
        //     {word: 'Zero-Based Budgeting (ZBB)', definition: ["Zero-Based Budgeting is a budgeting approach where each expense must be justified from scratch for each budgeting cycle. It helps organizations allocate resources efficiently by evaluating every cost item, regardless of prior budgets."]
        //     ,image:"https://drive.google.com/uc?export=download&id=1yJPlUHLxJVxLTFYvUGGANry6V2F_sED8"
        // },
        // {word: 'Zero Defects', definition: ["Zero Defects is a quality management philosophy that aims to eliminate defects or errors in processes, products, or services. It emphasizes the importance of preventing issues rather than relying on inspections or corrections."]
        //     ,image:"https://drive.google.com/uc?export=download&id=1vj5edZx5OE2AQPjYgIQmBCJIaXnodiov"
        // }
     ]
    }
]
    

export default BA_Data