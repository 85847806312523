const GM_search=
// 20230223165241
// http://localhost:3001/glossary/product-glossary?vertical=GM&status=1

[
    {
      "id": 310,
      word: 'Agile Methodology', definition:[
        "Agile is an iterative and flexible approach to project management and product development that encourages collaboration, adaptability, and customer feedback throughout the process. It is particularly suited for projects where requirements may change and allows teams to respond quickly to evolving needs."
      ],
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1JU5wXrdCynT31fMruIkrVRLkTgbtMPgK",
      "label": "A"
    },
//     {
//       "id": 311,
//       "word": "Action ",
//       "definition": ["In data science, taking action is a critical step in the process of turning insights and analyses into real-world outcomes. It involves using the findings from data analyses to make informed decisions, develop strategies, and take actions that have a positive impact on the organization or business."
//     ],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=124n1ObBS6U3cGWKGZH40bkE80xY-Ihyi",
//       "label": "A"
//     },
//     {
//       "id": 312,
//       "word": "Activation Function",
//       "definition": ["In artificial neural networks, an activation function is a mathematical function that determines the output of a neuron based on its input. The activation function applies a non-linear transformation to the input, allowing the neuron to model complex, non-linear relationships between inputs and outputs."],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1CD7hGWq7mZPBheWyvFrzQb6BjQT_RSHf",
//       "label": "A"
//     },
//     {
//       "id": 313,
//       "word": "Active Learning ",
//       "definition":["Active learning is a machine learning technique that involves actively selecting the most informative data points to label and adding them to the training dataset to improve the accuracy of the model. The goal of active learning is to minimize the amount of labeled data needed to achieve a high-performing model."],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1jUTtkovdJx8upG6lcmDGEEpSehtPG-dy  ",
//       "label": "A"
//     },
//     {
//       "id": 314,
//       "word": "AdaGrad",
//       "definition": ["The basic idea behind AdaGrad is to scale the learning rate for each parameter based on the inverse of the square root of the sum of the squared gradients for that parameter up to the current time step. In other worGM, AdaGrad adapts the learning rate to the features based on how frequently they occur in the training data. The advantage of AdaGrad is that it automatically adapts the learning rate to the scale of the gradients for each parameter, which can speed up the convergence of the optimization process and reduce the risk of diverging from the optimal solution."],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1e5lfIEcwlIl-1pp9gc07TqF3jJMCPJ5p",
//       "label": "A"
//     },
//     {
//       "id": 315,
//       "word": "Adam Optimization",
//       "definition": ["An optimization algorithm that can be used instead of the classical stochastic gradient descent procedure to update network weights iteratively based on training data. It computes individual adaptive learning rates for different parameters from estimates of the first and second moments of the gradients." ],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1nKLngaSa3mB4sWICTSV-2byCCcLss91k  ",
//       "label": "A"
//     },
//     {
//       "id": 316,
//       "word": "Air Flow",
//       "definition": ["Apache Airflow is an open-source platform for creating, scheduling, and monitoring workflows, also known as pipelines, in data science. Airflow is designed to automate and orchestrate complex workflows that involve multiple tasks, dependencies, and data sources.",
//     <p>The core of Airflow is its DAG (Directed Acyclic Graph) scheduler, which allows users to define a set of tasks and dependencies between them, and then schedule and monitor the execution of those tasks. Each task in the DAG is defined as a Python function or a script that performs a specific action, such as running a Spark job, executing a SQL query, or sending an email.</p>
//     ],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1jZZUO_wclVbUs2Akff09RYoXA8VBCusi",
//       "label": "A"
//     },
//     {
//       "id": 317,
//       "word": "Algorithm",
//       "definition": ["In data science, an algorithm refers to a set of rules or procedures used by a computer program to solve a particular problem or perform a specific task. These rules or procedures are usually expressed in the form of code, which is executed by a computer to process and analyze data.",
//     <p>Data scientists use algorithms to build predictive models, which can be used to forecast future trenGM, identify patterns in data, or make decisions based on historical data. The choice of algorithm depenGM on the nature of the problem and the characteristics of the data being analyzed.
//     </p>
//     ],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1bjAWyl6M3R3tghTsRDtCDO5-6WQcoere",
//       "label": "A"
//     },
//     {
//       "id": 318,
//       "word": "Anaconda",
//       "definition": ["Anaconda is a popular data science platform that provides a collection of open-source tools and libraries for data analysis, scientific computing, and machine learning. It is designed to simplify the process of setting up and managing data science environments by providing an all-in-one solution that includes a wide range of tools and packages.",
//       <p>Anaconda includes the following components:</p>,
//       <p>Conda: A package management system that allows users to easily install, update, and remove software packages.</p>,
//       <p>Python: A popular programming language used for data analysis and machine learning.</p>,
//       <p>Jupyter Notebook: A web-based interactive computational environment for creating and sharing data analyses.</p>,
//       <p>Spyder: An integrated development environment (IDE) for data science that provides a powerful code editor and debugging tools.</p>,
//       <p>Pandas: A library for data manipulation and analysis.</p>,
//       <p>NumPy: A library for numerical computing in Python.</p>,
//       <p>Matplotlib: A library for creating visualizations and plots.</p>,
//       <p>Scikit-learn: A library for machine learning in Python.</p>
//       ],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1DHiW86d_NZeyNesW3nDGPwezNgbhtdcE",
//       "label": "A"
//     },
//     {
//       "id": 319,
//       "word": "Analytics",
//       "definition": ["Analytics refers to the systematic analysis of data, using statistical and computational methoGM, to derive insights and make informed decisions. In the context of data science, analytics involves the process of collecting, cleaning, processing, and analyzing large volumes of data to uncover patterns, trenGM, and relationships that can be used to improve business outcomes, optimize processes, or gain a deeper understanding of a phenomenon."],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1ygyEKrxzUTJ4_OEBX1_5Rplh6xl68_pY",
//       "label": "A"
//     },
//     {
//       "id": 320,
//       "word": "Apache Spark",
//       "definition": ["Apache Spark is an open-source, distributed computing framework designed for large-scale data processing. Spark has gained immense popularity in the data science community due to its ability to handle large volumes of data, processing speed, and support for a wide range of data sources and processing models.",
//       <p>In data science, Apache Spark can be used for a variety of tasks such as data preprocessing, data cleaning, feature extraction, and machine learning. Spark's ability to process data in-memory and its support for distributed computing allows data scientists to work with large datasets without having to worry about memory limitations or processing delays.
//       </p>],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1V9ZbSm9dt7NGITrzXUANkKW_rbVnFdvS",
//       "label": "A"
//     },
//     {
//       "id": 321,
//       "word": "Autoregression",
//       "definition": ["Autoregression is a statistical modeling technique used in data science to model time-series data. It is a type of regression analysis where the dependent variable is regressed on one or more lagged values of itself. In other worGM, autoregression models the relationship between a variable and its own past values."],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1mPBAraxvhjz0_MkSZRfHCAgjQv3ezKKX",
//       "label": "A"
//     },
//     {
//       "id": 322,
//       "word": "API",
//       "definition": ["API stanGM for Application Programming Interface, and it is a set of rules, protocols, and tools used for building software applications. In data science, APIs are used to connect different software systems, allowing them to exchange data and information.",
//       <p>APIs can be used to access and manipulate data from various sources, such as social media platforms, databases, web services, and other applications. Data scientists often use APIs to retrieve data from these sources, process and analyze it, and then present the results to end-users or other applications.</p>
//        ],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1fv2SPwcWMgV6MODoo8y5C0kmZqfiX-GD",
//       "label": "A"
//     },
//     {
//       "id": 323,
//       "word": "Artificial General Intelligence",
//       "definition": ["Artificial General Intelligence (AGI) refers to a hypothetical form of artificial intelligence (AI) that can perform any intellectual task that a human being can. AGI would be able to reason, understand natural language, learn from experience, and solve complex problems without being explicitly programmed for each task.",
//       <p>In data science, AGI represents the ultimate goal of AI research, as it would enable machines to perform a wide range of tasks across different domains, such as natural language processing, image recognition, and decision making. AGI could also potentially surpass human intelligence in certain areas and help solve some of the world's most pressing problems.</p>
//       ],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1m7WLH-iCf4NSPN0I35XXr08Jj3Rh2Txd",
//       "label": "A"
//     },
//     {
//       "id": 324,
//       "word": "Artificial Intelligence",
//       "definition": ["A branch of computer science that involves using machine learning, programming, and data science techniques that enable computers to behave intelligently. Artificial Intelligence (AI) refers to the development of intelligent machines that can perform tasks that typically require human intelligence, such as speech recognition, decision-making, and visual perception."],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=12NMVv59_8AwmHR9C_vYhRjDx1qUFpQNp",
//       "label": "A"
//     },
//     {
//       "id": 325,
//       "word": "Artificial Neural Networks",
//       "definition": ["Artificial Neural Networks (ANNs) are a type of AI algorithm modeled after the structure and function of the human brain. ANNs are used in data science to analyze and interpret complex data sets, learn patterns, and make predictions.",
//       <p>ANNs are made up of layers of interconnected nodes, or "neurons," which process and transmit information. The input layer receives data, and the output layer produces the final result. In between, there can be one or more hidden layers that process the information and extract patterns.</p>
//   ],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1ZY1AxxMsjJTo5CMw63d3ariEMM5GA_11",
//       "label": "A"
//     },
//     {
//       "id": 326,
//       "word": "Attention",
//       "definition": ["Any of a wide range of neural network architecture mechanisms that aggregate information from a set of inputs in a data-dependent manner. A typical attention mechanism might consist of a weighted sum over a set of inputs, where the weight for each input is computed by another part of the neural network."],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1uGuHkzjFWq6eYFT-HuTLiyN7ZNWKsxG4",
//       "label": "A"
//     },
//     {
//       "id": 327,
//       "word": "Attribute Sampling",
//       "definition": ["Attribute Sampling is a statistical sampling technique used in data science to estimate the proportion of a specific characteristic, or attribute, in a population. This technique is used to determine whether a population meets certain quality standarGM, such as the proportion of defective items in a manufacturing process."],
//       "vertical": "GM",
//       "status": "1",
//       "image": "https://drive.google.com/uc?export=download&id=1zxbOR3FkbmO1p0gH3ocBFlXvs7S-mOLj",
//       "label": "A"
//     },
    {
      "id": 328,
      word: 'Balanced Scorecard', definition: ["The Balanced Scorecard is a strategic performance management framework that measures an organization's performance from four key perspectives: Financial (profitability and revenue), Customer (satisfaction and loyalty), Internal Processes (efficiency and quality), and Learning and Growth (employee development and innovation). It provides a comprehensive view of an organization's health and helps align strategies with objectives.",
    ],
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1r5KPeiS3uvR9mypm5r5YZZgKiY7o2X9c",
      "label": "B"
    },
    {
      "id": 329,
      word: 'Benchmark', definition: ["A benchmark serves as a standard or reference point used for comparing the performance, quality, or characteristics of a particular entity, such as a product, service, or organization. Benchmarks are essential for evaluating progress, identifying areas for improvement, and setting competitive goals."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1qb3YRE0GE2EvIlozNcv0Qo0PJG-chm0X",
      "label": "B"
    },
    {
      "id": 330,
      word: 'Benchmarking', definition: ["Benchmarking is the systematic process of comparing an organization's performance metrics, processes, or practices with those of industry leaders or competitors to gain insights and drive improvements. It helps organizations identify best practices and areas where they can enhance their competitiveness."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1j1udWNHCbx9JDS9kL7yGVnGCJxn6Sda3",
      "label": "B"
    },
    {
      "id": 331,
      word: 'Big Data', definition: ["Big Data refers to the vast and complex datasets generated by various sources, including digital platforms, sensors, and user interactions. Managing and analyzing Big Data can provide valuable insights, inform decision-making, and uncover trends or patterns that might otherwise remain hidden."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1F6aTL2HQqyCfgLbJEBt1MO6RueT68AK0",
      "label": "B"
    },
    {
      "id": 332,
      word: 'Brainstorming', definition: ["Brainstorming is a creative problem-solving technique that encourages individuals or groups to generate a wide range of ideas and solutions in a free and open environment. It fosters creativity and innovation by suspending judgment and promoting collaboration."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1-6d4GaZurO3FLQF5xjEJ5P99bIF6XhBY",
      "label": "B"
    },
    {
      "id": 333,
      word: 'Brand Equity', definition: ["Brand equity represents the intangible value of a brand, including its reputation, customer loyalty, and perceived quality. Strong brand equity can lead to higher customer trust, increased sales, and a competitive advantage in the market."]
      ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1nlkdmHMOeSPMnDQoVEpp6ALTDH_JT6n3",
      "label": "B"
    },
    {
      "id": 334,
      word: 'Budgeting', definition: ["Budgeting is the process of creating a financial plan that outlines expected revenues and expenditures for a specific period, typically a fiscal year. It serves as a financial roadmap for organizations, helping them allocate resources effectively and control costs."
    
    ], "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1Yq_6BJF5ie0kHbq9PhrxCGOsAnjF6PW3",
      "label": "B"
    },
    {
      "id": 335,
      word: 'Business Continuity Planning (BCP)', definition: ["Business Continuity Planning involves developing strategies, protocols, and contingency measures to ensure an organization's critical functions can continue or resume smoothly in the face of disruptions, such as natural disasters or cyberattacks. It aims to minimize downtime and safeguard data, personnel, and operations."]
       , "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1_WqYjlkPHYx_2EGo2iwp8yP7dKd8PleL",
      "label": "B"
    },
    {
      "id": 336,
      word: 'Business Ethics', definition: ["Business ethics encompasses the principles and standards of conduct that guide decision-making and behavior within an organization. It focuses on moral and ethical considerations, promoting integrity, fairness, and social responsibility in business practices."]
      ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1GQuvpabYWOBJyr7D-Ec0EqgCtub7dzlk",
      "label": "B"
    },
    {
      "id": 337,
      word: 'Business Intelligence (BI)', definition: ["Business Intelligence refers to the technologies and practices used to collect, analyze, and present data to support informed decision-making within an organization. It transforms raw data into actionable insights, enabling executives and managers to make strategic choices based on empirical evidence."]
      ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1qptvnb87loJYkO0VnqI7EtTmnouKvzQj",
      "label": "B"
    },
    {
      "id": 338,
      word: 'Business Model Canvas', definition: ["The Business Model Canvas is a visual tool that helps organizations describe, analyze, and design their business models. It consists of nine key building blocks, including customer segments, value propositions, channels, customer relationships, revenue streams, key resources, key activities, key partnerships, and cost structure."]
       ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1F8qIPGIRCbT6pkBzEd_ruZwb1lvlCSYm",
      "label": "B"
    },
    {
      "id": 339,
      word: 'Business Process Reengineering (BPR)', definition: ["Business Process Reengineering is the radical redesign and reorganization of an organization's business processes to improve efficiency, reduce costs, enhance quality, and respond more effectively to customer needs. It often involves rethinking workflows, technology adoption, and organizational structure."]
      ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1J1EDMUGySqp7GSsUaZUOx6BHooX2AABw",
      "label": "B"
    },
    // {
    //   "id": 340,
    //   "word": "Business Analytics ",
    //   "definition": ["The process of transforming data into insights to improve business decisions. Data management, data visualization, predictive modeling, data mining, forecasting simulation, and optimization are some of the tools used to create insights from data."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1JES9PAXAXVDC3le0JzrNOn_UwjK7hODp  ",
    //   "label": "B"
    // },
    // {
    //   "id": 341,
    //   "word": "Business Intelligence ",
    //   "definition": ["The process of transforming data into insights to improve business decisions. Data management, data visualization, predictive modeling, data mining, forecasting simulation, and optimization are some of the tools used to create insights from data."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1T4Ydy8lUSkMjzwitK0zxNKLIelb3iZmV",
    //   "label": "B"
    // },
    {
      "id": 342,
      word: 'Cash Flow', definition: ["Cash flow represents the movement of money into and out of a business over a specific period, reflecting its liquidity and ability to meet financial obligations. It comprises cash inflows from operations, investments, and financing activities, and it's crucial for financial stability and growth."]
           ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1eQ9ZibtJ2pCNJvEWDHnxfov4vjx8M69n",
      "label": "C"
    },
    {
      "id": 343,
      word: 'Cash Flow Statement ', definition: ["The Cash Flow Statement is a financial statement that provides a detailed account of an organization's cash inflows and outflows over a specified period, typically a fiscal quarter or year. It's divided into three sections: operating activities, investing activities, and financing activities, offering a clear picture of how cash is generated and utilized."]
      ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1XZRFvY4ed3iESCRMraFiS58jaiogE0pU",
      "label": "C"
    },
    {
      "id": 344,
      word: 'Competitive Advantage ', definition: ["Competitive advantage refers to the unique strengths and capabilities that enable a company to outperform its competitors in a particular market or industry. It can arise from factors like superior product quality, cost leadership, innovation, or brand recognition."]
      ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1lkpXHM3wCHWNXKURY9R_MbnV1g6U9Ptt",
      "label": "C"
    },
    {
      "id": 345,
      word: 'Competitive Intelligence', definition: ["Competitive intelligence involves the systematic gathering and analysis of information about competitors, industry trends, market dynamics, and external factors that impact an organization. It provides insights for strategic decision-making, helping companies anticipate competitive moves and identify growth opportunities."]
      ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1bcuFFwP-KffpymbzJ0dgFycYRNbShJwi",
      "label": "C"
    },
    {
      "id": 346,
      word: 'Conflict Resolution', definition: ["Conflict resolution is the process of addressing and resolving disputes, disagreements, or conflicts within an organization to restore harmony and productivity. It often involves techniques such as negotiation, mediation, or arbitration to reach mutually acceptable solutions."]
            ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1LojfwSsfbKD87QBo0ZM1opKT7pEPPyYn",
      "label": "C"
    },
    {
      "id": 347,
      word: 'Corporate Culture', definition: ["Corporate culture represents the shared values, beliefs, attitudes, and behaviors that define the working environment and character of an organization. It influences employee engagement, decision-making, and overall organizational performance."]
          , "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1DG_ovnp9i_XYl2NCwyj57lhpvfX_V0wg",
      "label": "C"
    },
    {
      "id": 348,
      word: 'Corporate Governance', definition: ["Corporate governance encompasses the system of rules, practices, and processes by which a company is directed and controlled. It includes the relationship between an organization's management, board of directors, shareholders, and other stakeholders, aiming to ensure transparency, accountability, and ethical conduct."]
      ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1jEoCSn2Tnp-1H0Jtu_yXMoG3LjBS_ryU",
      "label": "C"
    },
    {
      "id": 349,
      word: 'Cost-Benefit Analysis ', definition: ["Cost-Benefit Analysis is a systematic approach for evaluating the potential benefits of a decision, project, or course of action in comparison to its associated costs. It helps organizations make informed choices by assessing whether the benefits outweigh the expenses."]
     , "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1DfatbAXmD571XgxrTr-Fv_b9nKdBhQJE",
      "label": "C"
    },
    {
      "id": 350,
      word: 'Cost Leadership', definition: ["Cost leadership is a competitive strategy that focuses on becoming the lowest-cost producer or provider in an industry or market. Organizations pursuing this strategy aim to achieve cost efficiencies, often through economies of scale or operational excellence, to offer products or services at competitive prices."]
           ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1O4N3TJIdJE7ksgYmPqCWMQivRKOpuLpP",
      "label": "C"
    },
    {
      "id": 351,
      word: 'Cross-Functional Team', definition: ["A cross-functional team consists of individuals from different departments or functional areas within an organization who collaborate on a specific project or task. These teams bring diverse skills and perspectives to problem-solving and project execution."]
           ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1G06wpgRadrppQ092omBBwyNa0nUaKCwB",
      "label": "C"
    },
    {
      "id": 352,
      word: 'Customer Acquisition Cost (CAC)', definition: ["Customer Acquisition Cost represents the total expenses incurred by a company to acquire a new customer. It includes marketing and sales expenditures, customer onboarding costs, and related expenses, helping organizations assess the efficiency of their customer acquisition efforts."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1pid2MfZK_72vERTaei6rJdub6PypzyLD",
      "label": "C"
    },
    {
        "id": 352,
        word: 'Customer Relationship Management (CRM)', definition: ["CRM encompasses the practices and technologies used to manage interactions with current and potential customers. It includes capturing and analyzing customer data, improving customer service, and fostering strong customer relationships to drive sales and loyalty."]
        ,
        "vertical": "GM",
        "status": "1",
        "image": "https://drive.google.com/uc?export=download&id=1dtt2L2qi8pEyCoWXLzFRrIMDGbxC3HLY",
        "label": "C"
      },
    {
      "id": 353,
      word: 'Customer Lifetime Value (CLV)', definition: ["Customer Lifetime Value predicts the net profit generated from a customer over their entire relationship with a business. It factors in their purchasing behavior, loyalty, and retention, guiding marketing and customer relationship strategies."]
      ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=17MbLkCryFWPgTHmG7h-EcY9_Mw1lE4Fx",
      "label": "C"
    },
    {
      "id": 354,
      word: 'Decision-Making', definition:["Decision-making is the process of choosing between alternative courses of action to achieve a specific goal or solve a problem. It involves gathering information, evaluating options, considering risks, and making choices based on available data and analysis."] 
           ,"vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1ekwtnbeNKfJP_G3VdeZTNLmN2QmpwKeH",
      "label": "D"
    },
    {
      "id": 355,
      word: 'Demand Forecasting', definition: ["Demand forecasting is the process of estimating future customer demand for a product or service. It helps organizations plan production, inventory management, and supply chain activities to meet customer needs efficiently."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1ZH7Z-Q-VdSCi4dfPCf2hFf6eDpA6j1x-",
      "label": "D"
    },
    {
      "id": 356,
      word: 'Digital Transformation', definition:["Digital transformation is the integration of digital technologies into all aspects of an organization's operations and processes to fundamentally change how it operates and delivers value to customers. It involves adopting digital tools, automating workflows, and reimagining business models to stay competitive in the digital age."] 
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1TmD3LTIKHDAk8uGlFO2JbRarXVcIZBC7",
      "label": "D"
    },
    {
      "id": 357,
      word: 'Diversity and Inclusion', definition: ["Diversity and inclusion strategies aim to create a workplace culture that values and respects differences among employees, including those related to race, gender, age, ethnicity, and background. These efforts promote equal opportunities, diversity of perspectives, and a more inclusive work environment."]
  ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=11GnsYOfe8veluLweUZkT1pfEFRpdzQvQ",
      "label": "D"
    },
    {
      "id": 358,
      word: 'Diversification', definition: ["Diversification is a growth strategy in which a company expands by entering new markets, industries, or product categories. It helps spread risk and reduces dependency on a single market or product line."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1dKiKfd4coo3sNPP2v6szhzxEjYQjYjbp",
      "label": "D"
    },
    // {
    //   "id": 359,
    //   "word": "Data Engineer",
    //   "definition": ["An IT worker whose primary job is to prepare data for analytical or operational uses. These software engineers are typically responsible for building data pipelines to bring together information from different source systems.",
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1yhveau-Ju9cXDYc_A_a5CWscK871rDBU",
    //   "label": "D"
    // },
    // {
    //   "id": 360,
    //   "word": "Data Engineering ",
    //   "definition": ["The practice of designing and building systems for collecting, storing, and analyzing data at scale.",
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1OXZOYfirzP9giQVaCdHmBDzZK1X67z7g",
    //   "label": "D"
    // },
    // {
    //   "id": 361,
    //   "word": "Dataframe",
    //   "definition": ["A data structure that organizes data into a 2-dimensional table of rows and columns, much like a spreaGMheet." ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1U13Iqq9kCjEdcx0TNrik3Uqk2ijKe8Wa",
    //   "label": "D"
    // },
    // {
    //   "id": 362,
    //   "word": "Data Governance",
    //   "definition": ["Data governance is everything you do to ensure data is secure, private, accurate, available, and usable. It includes the actions people must take, and the processes they must follow.",
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1dEOQBM-UqvsEGZfHBvp_vBTRvQ6GpnJh",
    //   "label": "D"
    // },
    // {
    //   "id": 363,
    //   "word": "Data Journalism ",
    //   "definition": ["A type of journalism reflecting the increased role that numerical data is used in the production and distribution of information in the digital era.",
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1X5nT4T56sptr8TNBy5kYFC6KfG5Xqrd4",
    //   "label": "D"
    // },
    // {
    //   "id": 364,
    //   "word": "Data Lake ",
    //   "definition": ["A centralized repository designed to store, process, and secure large amounts of structured, semi-structured and unstructured data.",
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1dqwcnRHKl1S4Q9fTR9DQiguswMVERyFn",
    //   "label": "D"
    // },
    // {
    //   "id": 365,
    //   "word": "Data Literacy ",
    //   "definition": ["The ability to read, write and communicate data in context, including an understanding of data sources and constructs, analytical methoGM and techniques applied, and the ability to describe the use case, application, and resulting value.",
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1mqlmd5i9LQgJ3WvSm3G0EvzMN77YouoA",
    //   "label": "D"
    // },
    // {
    //   "id": 366,
    //   "word": "Data Mining ",
    //   "definition": ["Data mining is the process of discovering hidden patterns, correlations, and insights from large datasets using various statistical and computational techniques. The goal of data mining is to extract valuable information and knowledge from data that can be used for decision-making and prediction."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1suOGQ-sTMFOOOZsX5yGUnWdgRTBtPbm9  ",
    //   "label": "D"
    // },
    // {
    //   "id": 367,
    //   "word": "Data Modeling ",
    //   "definition": ["The process of creating a visual representation of either a whole information system or parts of it to communicate connections between data points and structures."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=18Z_blvnL3oNidCdOd_Y1jb7EO5rFeXdB",
    //   "label": "D"
    // },
    // {
    //   "id": 368,
    //   "word": "Data Pipeline ",
    //   "definition": ["A method in which raw data is ingested from various data sources and then ported to data store, like a data lake or data warehouse, for analysis."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1ibnzFkY3WKBFagzcOAGQ1qtpjJrgDP5R",
    //   "label": "D"
    // },
    // {
    //   "id": 369,
    //   "word": "Data Science ",
    //   "definition": ["The study of data to extract meaningful insights for business. It is a multidisciplinary approach that combines principles and practices from the fielGM of mathematics, statistics, artificial intelligence, and computer engineering to analyze large amounts of data."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1t9qVIFoGKPNAMnh4KRfjmY4X4Uy3nmn3",
    //   "label": "D"
    // },
    // {
    //   "id": 370,
    //   "word": "Data Scientist ",
    //   "definition": ["An analytics professional who is responsible for collecting, analyzing and interpreting data to help drive decision-making in an organization."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1jjuh4pFnQgXre5kRj6uKvo3_PoWGagKj",
    //   "label": "D"
    // },
    // {
    //   "id": 371,
    //   "word": "Data Set",
    //   "definition": ["A collection of related, discrete items of related data that may be accessed individually or in combination or managed as a whole entity."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1UovZipMSgamY8nAQnyYvNTa9iE-BA1E8",
    //   "label": "D"
    // },
    // {
    //   "id": 372,
    //   "word": "Data Structure ",
    //   "definition": ["In data science, a data structure refers to the way that data is organized and stored in a computer system or database. A well-designed data structure is important for efficient and effective data storage, retrieval, and manipulation."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1s944_MRM7TlrP8kTXts-14HRuGHX_vzT",
    //   "label": "D"
    // },
    // {
    //   "id": 373,
    //   "word": "Data Visualization ",
    //   "definition": ["Data visualization is the graphical representation of data and information using charts, graphs, and other visual elements. The goal of data visualization is to help users understand complex data and patterns more easily and to identify trenGM, outliers, and relationships."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1iS8v5wq3f2GCnYX2t4PErz3KX_dt3XdV",
    //   "label": "D"
    // },
    // {
    //   "id": 374,
    //   "word": "Data Warehouse",
    //   "definition": ["A data warehouse is a large, centralized repository of data that is used for reporting, data analysis, and business intelligence purposes. The data in a data warehouse is typically organized around specific subjects or domains, such as sales, marketing, or finance, and is often used to support decision-making processes."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1Bv0AgwQys3WoSenc7KNiTa8uz8dYwDjM",
    //   "label": "D"
    // },
    // {
    //   "id": 375,
    //   "word": "Data Wrangling ",
    //   "definition": ["The process of removing errors and combining complex data sets to make them more accessible and easier to analyze."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=14CLg3CdeLhiSn4HTGOVEYPvPhoLyU4Ur",
    //   "label": "D"
    // },
    // {
    //   "id": 376,
    //   "word": "Decision Tree",
    //   "definition": ["A non-parametric supervised learning algorithm, which is utilized for both classification and regression tasks. It has a hierarchical, tree structure, which consists of a root node, branches, internal nodes and leaf nodes."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1WxJqRax5-4BwsndqaeBt3OfGZtrxi61I",
    //   "label": "D"
    // },
    // {
    //   "id": 377,
    //   "word": "Deep Learning ",
    //   "definition": ["A subset of machine learning that trains a computer to perform human-like tasks, such as speech recognition, image identification and prediction making. It improves the ability to classify, recognize, detect and describe using data."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1C0j_zCq31p9MC308ybjYwcnH7NN5mUvv",
    //   "label": "D"
    // },
    // {
    //   "id": 378,
    //   "word": "Dimensionality Reduction ",
    //   "definition": ["It is the transformation of data from a high-dimensional space into a low-dimensional space so that the low-dimensional representation retains some meaningful properties of the original data, ideally close to its intrinsic dimension."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1txgASKjvdyIo2bVaOGALE6TREfZjtLlD",
    //   "label": "D"
    // },
    {
      "id": 379,
      word: 'Employee Engagement', definition: ["Employee engagement measures the degree to which employees are motivated, committed, and emotionally invested in their work and the organization. Engaged employees tend to be more productive, innovative, and satisfied with their jobs."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1mFkuCOSsrV1jfhhaw5CjVu_FaSDIdMIm",
      "label": "E"
    },
    {
      "id": 380,
      word: 'Employee Onboarding', definition: ["Employee onboarding is the process of integrating new employees into an organization. It includes activities such as orientation, training, and cultural assimilation to help newcomers adapt to their roles and become effective team members."]
        ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1OP5CIquc5krj8xKRDAZH25A2DgP-ZdUg",
      "label": "E"
    },
    {
      "id": 381,
      word: 'Ethics', definition: ["Ethics refer to the principles and standards of conduct that guide decision-making and behavior within an organization. Ethical practices promote fairness, honesty, integrity, and responsibility in all business activities."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=11N3T9pupU6fkSwSRZYKtVck65Tp0ObCJ",
      "label": "E"
    },
    {
        "id": 381,
        word: 'Exit Interview', definition: ["An exit interview is a structured interview conducted with an employee who is leaving the organization. Its purpose is to gather feedback, insights, and suggestions from departing employees to improve HR practices and the work environment."]
        ,
        "vertical": "GM",
        "status": "1",
        "image": "https://drive.google.com/uc?export=download&id=1AojwhBJVkkIDKhEWLM3ubNUJA2EqmhH9",
        "label": "E"
      },
    {
      "id": 382,
      word: 'Feedback Loop', definition: ["A feedback loop is a mechanism for obtaining input, opinions, or data from stakeholders, customers, or employees to inform decision-making, make improvements, and enhance products or services."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1TASIr299sgv_zx9n1D5B8Hu6p4EEQLTi",
      "label": "F"
    },
    {
      "id": 383,
      word: 'Franchise', definition: ["A franchise is a business arrangement in which an individual or entity (franchisee) is granted the right to operate a business using the branding, products, services, and support of another company (franchisor). Franchising allows entrepreneurs to leverage established brand recognition and business models."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1_XWbUI_RpJ1zhywKhQcmd5RAG-zz3I5s",
      "label": "F"
    },
    // {
    //   "id": 384,
    //   "word": "Feature",
    //   "definition": ["A measurable property of the object you're trying to analyze.",
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1UvXZ5vTPAO1OI-z2LF_9iCsDCzGRnSZ7  ",
    //   "label": "F"
    // },
    // {
    //   "id": 385,
    //   "word": "Feature Engineering",
    //   "definition": ["The process of selecting, transforming, extracting, combining, and manipulating raw data to generate the desired variables for analysis or predictive modeling.",
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=187WmHHfDQNP6WJn8YGM_mvPvlxuNlYtd",
    //   "label": "F"
    // },
    // {
    //   "id": 386,
    //   "word": "Feature Selection ",
    //   "definition": ["The method of reducing the input variable to your model by using only relevant data and getting rid of noise in data.",
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1gqJiF-rB3B2Psy94P7M7kFV3PyrWi-ze",
    //   "label": "F"
    // },
    // {  
    //   "id": 387,
    //   "word": "Gradient Descent ",
    //   "definition": ["An iterative first-order optimisation algorithm used to find a local minimum/maximum of a given function.",
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1SajqQET7Se_zXTBCjqiwvFnSuSwxfTwT",
    //   "label": "G"
    // },
    {
      "id": 388,
      word: 'Horizontal Integration', definition: ["Horizontal integration is a growth strategy in which a company expands by acquiring or merging with competitors operating in the same industry or market. It aims to increase market share, gain economies of scale, and reduce competition."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1Yn-AO26tgxqP8poFz71Fncc74M3AxiC_",
      "label": "H"
    },
    {
      "id": 389,
      word: 'Human Resource Management (HRM)', definition: ["Human Resource Management is the function within an organization responsible for managing personnel, including recruitment, training, performance evaluation, compensation, and employee relations. It plays a crucial role in attracting, developing, and retaining talent to support organizational objectives."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=16rFj0OGBcTwcST1uLeN_7UF-NRaguH1R",
      "label": "H"
    },
    // {
    //   "id": 390,
    //   "word": "Hypothesis ",
    //   "definition": ["In data science, a hypothesis is proven through data analytics. This means, once the hypothesis is defined, you can collect data to determine whether it provides enough evidence to support the hypothesis.",
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1u6z7WQQZXz-wq7ltORFmMj78LO1mSp0v",
    //   "label": "H"
    // },
    {
      "id": 391,
      word: 'Innovation', definition: ["Innovation is the process of creating and implementing new ideas, products, processes, or services that add value to an organization. It fuels growth, competitiveness, and adaptability in dynamic markets."]
            ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=17VqqU7X5ygXqKPUTCP2aI43i5MLQ0d7t",
      "label": "I"
    },
    {
      "id": 392,
      word: 'Innovation Pipeline', definition: ["The innovation pipeline is a structured process for generating, evaluating, and developing new ideas and innovations within an organization. It helps manage the flow of ideas from inception to implementation, ensuring that promising innovations are nurtured and brought to market."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1iJifwOVv9WyYbemhkZ62E2qi90x4A8p4",
      "label": "I"
    },
    {
      "id": 393,
      word: 'Intangible Assets', definition: ["Intangible assets are non-physical assets with economic value, such as intellectual property (patents, trademarks, copyrights), brand reputation, customer relationships, and proprietary technology. These assets contribute to a company's competitive advantage and financial worth."]
   ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1y6U6Yrz512x-aQSgQmfTkyG72QG5FNVH",
      "label": "I"
    },
    {
      "id": 394,
      word: 'Intrapreneurship', definition: ["Intrapreneurship refers to the practice of fostering entrepreneurial behavior, creativity, and innovation within an organization. It encourages employees to act as entrepreneurs, identifying opportunities, and driving new initiatives or projects within the corporate framework."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1n0jiem6jEr378sWCJ8qjUSpk9XuB13Dw",
      "label": "I"
    },
    {
      "id": 395,
      word: 'Key Account Management (KAM)', definition: ["Key Account Management is a strategic approach to managing and nurturing relationships with a select group of high-value customers or clients. KAM focuses on understanding and meeting the unique needs and expectations of these important accounts to maintain long-term partnerships and revenue growth."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1a7ZfbUAs8Xi1869uN9RW45txmty2oAly",
      "label": "K"
    },
    {
      "id": 396,
      word: 'Knowledge Management', definition: ["Knowledge Management is the process of capturing, storing, organizing, and sharing an organization's collective knowledge and expertise to improve decision-making, problem-solving, and innovation. It involves the use of technology, practices, and culture to leverage knowledge assets effectively."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1ukYYAxaRxH9Di24DQVX_qUttcl2h1nF-",
      "label": "K"
    },
    {
      "id": 397,
      word: 'Knowledge Transfer', definition: ["Knowledge Transfer is the practice of sharing knowledge and expertise within an organization to improve performance, foster learning, and ensure continuity when employees transition or depart. It helps preserve institutional knowledge and ensures that critical skills are passed on to others."],
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1MTMCMD58Abpv5aPesSaAJ_eUJh5VH9zQ",
      "label": "K"
    },
    {
      "id": 398,
      word: 'Leadership', definition: ["Leadership refers to the ability to influence and guide individuals or teams toward the achievement of common goals or objectives. Effective leadership involves inspiring, motivating, and empowering others to excel and contribute to the organization's success."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1Kw5pLgF1cEoEVukABL8w0pSmVgOef8gP",
      "label": "L"
    },
    {
      "id": 399,
      word: 'Lean Management', definition: ["Lean Management is an approach that focuses on minimizing waste, optimizing processes, and maximizing value in operations and production. It emphasizes principles such as continuous improvement, customer value, and efficient resource allocation."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1348Hxjxt46ttJMwUDETUMtXyT1F0rUce",
      "label": "L"
    },
    {
      "id": 345,
      word: 'Mergers and Acquisitions (M&A)', definition: ["Mergers and Acquisitions encompass various financial transactions through which one company combines with another or acquires its assets. These activities can include mergers (combining two equal companies), acquisitions (one company acquiring another), or takeovers (acquiring a controlling interest in another company)."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=13Xli0Jfyy7v2dg1_pbbrA5uKtAHUXerL",
      "label": "M"
    },
    // {
    //   "id": 345,
    //   "word": "Median",
    //   "definition": ["It is the middle point in a dataset—half of the data points are smaller than the median and half of the data points are larger."
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1rvZychkj9wV3xFQ0G2qbaXgx8yW1z_GI",
    //   "label": "M"
    // },
    // {
    //   "id": 345,
    //   "word": "Mode",
    //   "definition": ["The value which occurs the maximum number of times in a given data set."
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1mwd_O5Qv69mmtxYNgu_an1_Wefvm3-Y0",
    //   "label": "M"
    // },
    // {
    //   "id": 345,
    //   "word": "Model Tuning ",
    //   "definition": ["The experimental process of finding the optimal values of hyperparameters to maximize model performance."
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1vkUfQqDM8P-A4fjFheCCnoMKwIu7ZN1B",
    //   "label": "M"
    // },
    // {
    //   "id": 345,
    //   "word": "Multivariate Modeling",
    //   "definition": ["A Statistical procedure for analysis of data involving more than one type of measurement or observation."],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1IN_vK2pxY7PKiqgdNMOmPo-qs_QB2Zci",
    //   "label": "M"
    // },
    // {
    //   "id": 345,
    //   "word": "Naive Bayes",
    //   "definition": ["A simple learning algorithm that utilizes Bayes rule together with a strong assumption that the attributes are conditionally independent, given the class. "
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1sSx7iiIOkz_5qMPXHgWDOQJaGZ0qyVrb",
    //   "label": "N"
    // },
    // {
    //   "id": 345,
    //   "word": "Natural Language Processing ",
    //   "definition": ["Natural Language Processing (NLP) is a subfield of artificial intelligence (AI). It helps machines process and understanGM human language."
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=14XBxShW44O8BLZv8Z6vVVfsBUKdEYSNy",
    //   "label": "N"
    // },
    // {
    //   "id": 345,
    //   "word": "Normalization ",
    //   "definition": ["The process of transforming the values in a dataset to the same scale is referred to as normalization."
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1am_K2B-sVfQ742gZtITR_NP0m9twAG4B",
    //   "label": "N"
    // },
    // {
    //   "id": 345,
    //   "word": "NoSQL",
    //   "definition": ["An approach to database design that enables the storage and querying of data outside the traditional structures found in relational databases."
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1mr3WYcbtYeYoVSlDqr3OeqbUB98-vx5v",
    //   "label": "N"
    // },
    // {
    //   "id": 345,
    //   "word": "Null Hypothesis",
    //   "definition": ["A type of statistical hypothesis that proposes that no statistical significance exists in a set of given observations."
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1x_LD_OpIPRgfADWe72A-21F7MWmQ5k7Y",
    //   "label": "N"
    // },
    {
      "id": 345,
      word: 'Organizational Culture', definition: ["Organizational Culture represents the shared values, beliefs, attitudes, and behaviors that define the working environment and character of an organization. It shapes how employees interact, make decisions, and align with the organization's mission and vision."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1Ur8JbcFh5rfm_-s_BWq-SloejSq7JNH6",
      "label": "O"
    },
    {
      "id": 345,
      word: 'Organizational Development', definition: ["Organizational Development involves planned efforts to enhance an organization's effectiveness, adaptability, and overall performance. It focuses on improving systems, structures, and processes, as well as nurturing employee skills and collaboration."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=12QYXdU7aaF_4aNHZHlbDdjv8KresK6oz",
      "label": "O"
    },
    {
      "id": 310,
      word: 'Organizational Learning', definition: ["Organizational Learning is the process of acquiring, sharing, and applying knowledge within an organization to improve performance and adapt to change. It encourages a culture of continuous learning and knowledge utilization to stay competitive."]
  ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1mHtxRxZqQmP1gicJ8GuONf32DqcbU6CZ",
      "label": "O"
    },
    // {
    //   "id": 311,
    //   "word": "Overfitting ",
    //   "definition": ["An undesirable machine learning behavior that occurs when the machine learning model gives accurate predictions for training data but not for new data."
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=10QD9gvOQ_geFbQrLCXQjXG4Dn4Oop_e2",
    //   "label": "O"
    // },
    {
      "id": 312,
      word: 'Performance Appraisal ', definition: ["Performance Appraisal is the systematic process of evaluating an employee's job performance, often conducted through periodic reviews or assessments. It assesses individual contributions, identifies areas for improvement, and supports career development."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1tPWnurpKrKfey1-g-ZyPnm1kExKhkhxU",
      "label": "P"
    },
    {
      "id": 313,
      word: 'Performance Management ', definition: ["Performance Management is the process of setting goals, assessing progress, providing feedback, and improving individual and organizational performance. It involves aligning employee performance with organizational objectives and facilitating ongoing improvement."]
  ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1lF0qkv5L33YJQrF5Sa5Py4d3rET2LPhT",
      "label": "P"
    },
    {
      "id": 314,
      word: 'Pareto Principle (80/20 Rule)', definition: ["The Pareto Principle, often referred to as the 80/20 Rule, suggests that roughly 80% of outcomes result from 20% of causes or efforts. It is used in prioritization and resource allocation to focus efforts on the most significant factors or activities."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=14-RmYOSMcvS5A88fC2funSIwNDuSw3Jz",
      "label": "P"
    },
    {
      "id": 315,
      word: 'Product Life Cycle', definition: ["The Product Life Cycle comprises the stages a product goes through, including Introduction (launch), Growth (expansion), Maturity (plateau), and Decline (decreasing sales). It guides marketing and product strategies, as each stage requires different approaches."]
   ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1TYvSylDfiedlatZ5rFAiXNb8dd2DiOM_",
      "label": "P"
    },
    // {
    //   "id": 316,
    //   "word": "Python ",
    //   "definition": ["An interpreted, object-oriented, high-level programming language with dynamic semantics developed by Guido van Rossum."
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1hKbuC66VqhC6Ook1HJSM6as9gSilgIaY",
    //   "label": "P"
    // },
    {
      "id": 317,
      word: 'Quality Control', definition: ["Quality Control is the systematic processes and activities used to ensure that products or services meet established quality standards. It involves inspections, testing, and adherence to quality criteria to deliver consistent and reliable results to customers."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1QFR4dOyNyZKygr7z2tJrCRfY2vPWGJ4Q",
      "label": "Q"
    },
    {
      "id": 318,
      word: 'Quality Management System (QMS)', definition: ["A Quality Management System is a structured approach to managing an organization's quality processes, procedures, and standards. It ensures that products or services consistently meet or exceed customer expectations and comply with relevant regulations."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1msIfArUxEjHFJcSf3HyWwvU3unllq08q",
      "label": "Q"
    },
    // {
    //   "id": 319,
    //   "word": "Regression ",
    //   "definition": ["The estimation of a continuous dependent variable or response from a list of input variables, or features."
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=18FQjAhRYkXoEIraK9_0MEXKl4xsj5pdl",
    //   "label": "R"
    // },
    // {
    //   "id": 320,
    //   "word": "Reinforcement Learning ",
    //   "definition": ["A type of machine learning technique that enables an agent to learn in an interactive environment by trial and error using feedback from its own actions and experiences."
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=19iqtiH719QCLMJEMEZZTeVL-SZpTuivg",
    //   "label": "R"
    // },
    // {
    //   "id": 321,
    //   "word": "Root Mean Squared Error ",
    //   "definition": ["A standard way to measure the error of a model in predicting quantitative data. "
    // ],
    //   "vertical": "GM",
    //   "status": "1",
    //   "image": "https://drive.google.com/uc?export=download&id=1MJYsPgQKz8Nb-3n7kPpVJSiJRA54IK0f",
    //   "label": "R"
    // },
    {
      "id": 322,
      word: 'Return on Assets (ROA)', definition: ["Return on Assets is a financial metric that measures a company's profitability by comparing its net income to its total assets. It assesses how efficiently an organization utilizes its assets to generate earnings."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1pkHz15srW-3I3sETzeBgUKU7RDjhE3ln",
      "label": "R"
    },
    {
      "id": 323,
      word: 'Return on Equity (ROE)', definition: ["Return on Equity is a financial metric that measures a company's profitability by comparing its net income to shareholders' equity. It indicates how effectively a company generates returns for its equity investors."]
,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=15L7slGWrm2NjSDf99XECkexrhsLt2Q6O",
      "label": "R"
    },
    {
      "id": 324,
      word: 'Return on Investment (ROI)', definition: ["Return on Investment is a measure of the profitability of an investment, calculated as the gain or loss relative to the initial cost of the investment. It helps evaluate the effectiveness and financial viability of projects or initiatives."]
 ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1xLprLqKzIOLlxJ2KCg92rBpYr1DTD9mo",
      "label": "R"
    },
    {
      "id": 325,
      word: 'Risk Assessment', definition: ["Risk Assessment is the evaluation of potential risks and their impact on an organization. It identifies and analyzes risks to prioritize and develop strategies for risk mitigation and management."]
 ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1Jhet9va8N0U3ERqp6oG3uBPTApJNyCNA",
      "label": "R"
    },
    {
      "id": 326,
      word: 'Risk Management', definition: ["Risk Management is the process of identifying, assessing, and mitigating potential risks to minimize negative impacts on organizational goals and operations. It involves planning, monitoring, and adapting strategies to handle uncertainty effectively."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1ynZcumdY96qyiZlAeP94pySDU8Rj5ZT3",
      "label": "R"
    },
    {
      "id": 327,
      word: 'Scenario Planning', definition: ["Scenario Planning is a strategic planning technique that involves creating and analyzing multiple possible future scenarios to inform decision-making. It helps organizations anticipate and prepare for a range of potential outcomes, enhancing adaptability and resilience."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1z2J8KN-SJo4SM8_fBM7Yv2_5BJmMsjES",
      "label": "S"
    },
    {
      "id": 328,
      word: 'Stakeholder', definition: ["A Stakeholder is an individual, group, or entity that has an interest or concern in the outcomes, decisions, or activities of an organization. Stakeholders can include employees, customers, investors, regulators, and the community."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1yqHGg-P33QtSm7u9XkCYt-eKCIeglV9A",
      "label": "S"
    },
    {
      "id": 329,
      word: 'Strategic Alignment', definition: ["Strategic Alignment refers to the process of ensuring that an organization's actions, initiatives, and resources are aligned with its strategic goals and objectives. It involves regular assessment and adjustment to maintain consistency and focus."]
 ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1BVPjB3hSNfBxKkOBZU27GNvaDs69WLOs",
      "label": "S"
    },
    {
      "id": 330,
      word: 'Strategic Alliances', definition: ["Strategic Alliances are collaborative agreements between organizations to achieve mutually beneficial goals or objectives. These partnerships may involve resource sharing, joint ventures, or co-development of products or services."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1PimgoWmgsLkOFO8P4oGitPoZcRtibDEb",
      "label": "S"
    },
    {
      "id": 331,
      word: 'Strategic Intent', definition: ["Strategic Intent is a statement of ambitious and aspirational goals that guide an organization's long-term vision and direction. It provides a clear sense of purpose and motivation for pursuing strategic objectives."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1eUePdIHPuZTETaBnRw9Eu71jA9_IOzey",
      "label": "S"
    },
    {
      "id": 332,
      word: 'Strategic Management', definition: ["Strategic Management involves formulating, implementing, and monitoring an organization's strategies and plans to achieve its mission and objectives. It encompasses strategic analysis, planning, execution, and evaluation."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1fQ8huOYy_s5E9WX43GA8FrN6-zvy_hnh",
      "label": "S"
    },
    {
      "id": 332,
      word: 'Strategic Planning', definition: ["Strategic Planning is the process of defining an organization's mission, vision, goals, and strategies to guide decision-making and resource allocation. It provides a roadmap for achieving long-term success."]
 ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1jjuCb2CFqi6J-FC2nqCFGdSfpiSfRsWa",
      "label": "S"
    },
    {
      "id": 330,
      word: 'Strategic Sourcing', definition: ["Strategic Sourcing is the systematic approach to procurement and supplier management to optimize costs, quality, and supplier relationships. It involves evaluating suppliers, negotiating contracts, and managing the supply chain strategically."]
  ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1CgYQ3Ls5yDxT1L1LIlaZomo2UksIlpzM",
      "label": "S"
    },
    {
      "id": 331,
      word: 'Sustainability', definition: ["Sustainability focuses on responsible resource use and environmental stewardship to meet current needs without compromising the ability of future generations to meet their needs. Sustainable practices encompass social, economic, and environmental dimensions."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1aqxs8VIG3I8ZTMiQThm-zG5B96b5I823",
      "label": "S"
    },
    {
      "id": 332,
      word: 'SWOT Analysis', definition: ["SWOT Analysis is a strategic planning tool used to assess an organization's strengths, weaknesses, opportunities, and threats. It helps identify internal and external factors that can impact strategy development and decision-making."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1nr6BS4yTKZe-wJHh-uASmLe8NK0YnyFj",
      "label": "S"
    },
    {
      "id": 332,
      word: 'Team Building', definition: ["Team Building involves activities and processes aimed at enhancing collaboration, communication, and cohesion among team members. It fosters a positive team dynamic and improves collective performance."]
            ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=14bIrv9hldlHnSK7T93g5tYt9OsxE5y7d",
      "label": "T"
    },
    {
      "id": 332,
      word: 'Time Management', definition: ["Time Management is the practice of efficiently planning and organizing tasks and activities to maximize productivity and achieve goals within a specified timeframe. It involves prioritization, goal setting, and effective scheduling."]
  ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1bLPLeotjHOUeo-BjMb05feongMTOC016",
      "label": "T"
    },
    {
      "id": 332,
      word: 'Total Quality Management (TQM)', definition: ["Total Quality Management is a comprehensive approach to continuous improvement, focusing on delivering high-quality products and services. It involves employee involvement, process optimization, and customer-centered practices."]
      ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1ctpvW7XYcyKQ204X56XgeZGaV_HTR2gM",
      "label": "T"
    },
    {
      "id": 332,
      word: 'Value Chain ', definition: ["The Value Chain represents the sequence of activities involved in creating, delivering, and supporting a product or service from inception to the customer's hands. It helps organizations identify opportunities for cost reduction and value creation."]
           ,
      "vertical": "GM",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1i2kCRwguDJtuvGSw-s-34MBOhL0bk3uZ",
      "label": "V"
    },
    {
        "id": 333,
        word: 'Vendor Management', definition: ["Vendor Management involves overseeing relationships with suppliers and vendors to ensure the timely delivery of quality products and services. It includes supplier evaluation, contract management, and performance monitoring."]
        ,
        "vertical": "GM",
        "status": "1",
        "image": "https://drive.google.com/uc?export=download&id=1TK5oyVz9-mx3vezx0VwoBZwdIWX-FU7p",
        "label": "V"
      },
      { 
        "id": 334,
        word: 'Virtual Team', definition: ["A Virtual Team is a group of individuals who collaborate and work together on projects or tasks from different locations, often using technology and remote communication tools. Managing virtual teams requires effective communication and coordination."]
        ,
        "vertical": "GM",
        "status": "1",
        "image": "https://drive.google.com/uc?export=download&id=1y9JlsuWRMY945XwnVKST0Q3M6yW-cGEP",
        "label": "V"
      },
      {
        "id": 335,
        word: 'Work-Life Balance', definition: ["Work-Life Balance refers to the equilibrium between an employee's work responsibilities and personal life. It emphasizes the importance of maintaining mental and physical well-being while meeting professional obligations."]
               ,
        "vertical": "GM",
        "status": "1",
        "image": "https://drive.google.com/uc?export=download&id=17eEQjco-1uNBljjoEwJRF4l3EeolBy9C",
        "label": "W"
      },
      {
        "id": 336,
        word: 'Working Capital', definition: ["Working Capital represents the capital available for day-to-day operational expenses and short-term obligations. It is calculated as current assets minus current liabilities and reflects a company's short-term financial health."]
            ,
        "vertical": "GM",
        "status": "1",
        "image": "https://drive.google.com/uc?export=download&id=1Fnc09GTLmMTrIirmKUNE7Ukmsof5KeCi",
        "label": "W"
      },
      {
        "id": 337,
        word: 'Zero-Based Budgeting (ZBB)', definition: ["Zero-Based Budgeting is a budgeting approach where each expense must be justified from scratch for each budgeting cycle. It helps organizations allocate resources efficiently by evaluating every cost item, regardless of prior budgets."]
        ,
        "vertical": "GM",
        "status": "1",
        "image": "https://drive.google.com/uc?export=download&id=1yJPlUHLxJVxLTFYvUGGANry6V2F_sED8",
        "label": "Z"
      },
      {
        "id": 338,
        word: 'Zero Defects', definition: ["Zero Defects is a quality management philosophy that aims to eliminate defects or errors in processes, products, or services. It emphasizes the importance of preventing issues rather than relying on inspections or corrections."]
        ,
        "vertical": "GM",
        "status": "1",
        "image": "https://drive.google.com/uc?export=download&id=1vj5edZx5OE2AQPjYgIQmBCJIaXnodiovx",
        "label": "Z"
      },
  ]



export default GM_search