const search_data=

// 20230223104150
// http://localhost:3001/glossary/product-glossary?vertical=PM&status=1

[
  {
    "id": 1,
    "word": "A/B Test ",
    "definition": ["A/B testing, also known as split testing, is a method of comparing two versions of a product or feature to determine which one performs better. In product management, A/B testing is often used to evaluate changes to a product or feature, such as a new user interface, pricing strategy, or marketing message." ,
    <p> The A/B test involves randomly dividing a group of users into two separate groups, each of which sees a different version of the product or feature. The results are then measured and compared to determine which version performs better based on the specific goal or metric being evaluated, such as conversion rate or engagement. </p>,
    <p>By using A/B testing, product managers can make data-driven decisions about which version of a product or feature to implement or make changes to, instead of relying on assumptions or intuition. This allows them to continually improve the product and optimize its performance to better meet the needs and preferences of users.</p>
],

    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1MKWly1nYBnWWkDaAubVYqFj-6cBIzME-",
    "label": "A"
  },
  {
    "id": 2,
    "word": "AARRR Private Metrics Framework ",
    "definition": [
      "AARRR, also known as the Pirate Metrics Framework, is a popular model for tracking and analyzing the performance of a product or service. It is a framework of private metrics used in product management that focuses on the user journey and aims to help product teams understand how users interact with a product at different stages of the customer lifecycle.",
      <p>The AARRR framework consists of five key metrics, each representing a stage in the customer journey:</p>,
      <p>1. Acquisition: This refers to the number of users who discover and visit the product or service, typically through marketing or advertising efforts.</p>,
      <p>2. Activation: This refers to the number of users who have taken an action that indicates they have gained value from the product or service, such as creating an account or completing a key onboarding step.</p>,
      <p>3. Retention: This refers to the number of users who continue to use the product or service over time, indicating a level of satisfaction and engagement.</p>,
      <p>4. Revenue: This refers to the monetary value generated by the product or service, such as through sales, subscriptions, or advertising.</p>,
      <p>5. Referral: This refers to the number of users who recommend the product or service to others, helping to drive new acquisition.</p>,
      <p>The AARRR framework helps product managers identify areas where they may be experiencing low engagement or high churn and focus on improving those areas to optimize performance and increase user satisfaction. By tracking and analyzing these private metrics, product teams can make data-driven decisions to improve the overall user experience and drive growth.
      </p>
  ],
  
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1PAFF8a_IvovXlYrt3Yubu1FGDmDlxTCh",
    "label": "A"
  },
  {
    "id": 3,
    "word": "Acceptance Criteria ",
    "definition": ["In product development, acceptance criteria refer to a set of conditions or requirements that must be met for a deliverable to be considered complete and acceptable by the customer or end user. Acceptance criteria are used to define the specific features, functionalities, and quality standards that a product must meet in order to satisfy the needs and expectations of the customer.\nAcceptance criteria can be thought of as a checklist of requirements that a product or feature must meet in order to be considered complete. They typically include specific, measurable, and testable conditions that can be used to validate whether a product or feature has met the required standard.\nBy defining acceptance criteria early in the development process, product teams can ensure that they are building a product that is aligned with the customer's needs and expectations and that is of high quality and usability.\n"
  ],
  
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1_6OTGs2kFNyGGSx7V2WLxAxT_bU7qP2I",
    "label": "A"
  },
  {
    "id": 4,
    "word": "Acceptance Test ",
    "definition": ["Acceptance testing is a type of software testing that is performed to determine if a software product meets the required specifications and standards and is ready for delivery to the end user. The purpose of acceptance testing is to ensure that the software product meets the business requirements and user expectations.\nDuring the acceptance testing phase, the software is tested for its functional and non-functional aspects, such as usability, performance, security, reliability, and compatibility with different platforms and systems. The testing is usually performed by the end-user or a representative of the end user, and it is carried out in a real-world environment, with real data and scenarios.\n\n"
  ],
  
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1fsU9byOHXcJcXQextKvh1skrCU5B_1ZK",
    "label": "A"
  },
  {
    "id": 5,
    "word": "Action Priority Matrix ",
    "definition": ["A diagram that helps people determine which tasks to focus on, and in which order. By using the Action Priority Matrix, project managers can prioritize their tasks and focus their time and resources on the tasks that are most important and urgent, while avoiding wasting time on tasks that are not important or urgent. This can help improve productivity, reduce stress, and improve the overall quality of the project."
  ],

    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1yr3XYYX1YMBlJmqrCCVU0iKgI9ahDjvV",
    "label": "A"
  },
  {
    "id": 6,
    "word": "Adaptive Software Development (ASD)",
    "definition": ["A flexible approach to the Agile development of complex software systems that encourages emergence, continuous testing and deeply integrated user feedback and collaboration. ASD involves a series of iterative and incremental cycles, each of which consists of three phases: speculating, collaborating, and learning. The speculating phase involves defining the scope, goals, and objectives of the project, and identifying the key risks and uncertainties. The collaborating phase involves developing and testing the software in small increments, with regular feedback from stakeholders. The learning phase involves reviewing and evaluating the results of the previous phase, and making any necessary changes to the plan or approach."
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ZYOR2Nj1XScpsxh20hUvlN-6h4RzfePB",
    "label": "A"
  },
  {
    "id": 7,
    "word": "Affinity Diagram ",
    "definition": [
      "An affinity diagram helps a team visualize and review large amounts of information by grouping items into categories. It involves the following steps:",
      <p>Gather Data: Collect a large number of ideas, issues, or observations related to a problem or topic.</p>,
      <p>Record Data: Write each idea or observation on a separate sticky note or card.</p>,
      <p>Group Data: Sort the sticky notes or cards into broad categories based on common themes or similarities. The categories are usually written on larger sticky notes or flip chart sheets.</p>,
      <p>Label Categories: Label each category with a descriptive title that accurately reflects its contents.</p>,
      <p>Identify Insights: Review the categories to identify insights, patterns, and trends that emerge from the groupings.</p>
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1F_7IGJkB9i5ks0f5QizZhvrC2CSlcZ7b",
    "label": "A"
  },
  {
    "id": 8,
    "word": "Affinity Grouping ",
    "definition": ["An affinity group is a group formed around a shared interest or common goal, to which individuals formally or informally belong. It refers to the practice of gathering and grouping together pieces of qualitative (non-numeric) data, based on shared similarities."
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1vJU69-P1URsUnI57E4s0_XpAwSPFm9vA",
    "label": "A"
  },
  {
    "id": 9,
    "word": "Agile",
    "definition": ["Agile is an iterative and incremental approach to software development that values flexibility, customer collaboration, and rapid response to change. It is a methodology that emphasizes working in small, cross-functional teams to deliver high-quality software quickly and frequently, with a focus on continuous improvement. "
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1WPbFsWkwQ8_mclZweNxyVj346vixKO1g",
    "label": "A"
  },
  {
    "id": 10,
    "word": "Agile Framework",
    "definition": ["It is a set of guiding principles that prioritize working software, customer collaboration, and response to change over rigid processes, documentation, and contract negotiation. The different variations and approaches used by development teams that are based on the values and principles of the Agile Manifesto."
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1mBoKxFzOwG2ZO9apEVEmd05NMXcbFaXz",
    "label": "A"
  },
  {
    "id": 11,
    "word": "Agile Manifesto",
    "definition": ["The Agile Manifesto values individuals and interactions, working software, customer collaboration, and response to change over rigid processes, documentation, and contract negotiation. A document that sets out the key values and principles behind the Agile philosophy and serves to help development teams work more efficiently and sustainably."
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ZWwuYyp3Tq9D2wWQgP3sKEO3Rw0c0Age",
    "label": "A"
  },
  {
    "id": 12,
    "word": "Agile Principles ",
    "definition": ["Agile principles are the guiding values and beliefs that underpin Agile software development. Agile principles emphasize collaboration, communication, continuous delivery, and continuous improvement, creating a more customer-focused, efficient, and adaptable approach to software development."
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=10rn3hdJnbt5lqOd-aqizs0rhsGKKXtS-",
    "label": "A"
  },
  {
    "id": 13,
    "word": "Agile Product Management",
    "definition": ["Agile product management is an approach to product management that is based on the principles and values of Agile software development. It emphasizes collaboration, iteration, and responsiveness to customer needs in order to create products that are better suited to the market and customer demands."
    
  ],"vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=11c2qlvQ35EVxuTQ6pl5e9j307W0iSv8l",
    "label": "A"
  },
  {
    "id": 14,
    "word": "Agile Product Owner ",
    "definition": ["The Agile Product Owner is a critical role in Agile software development that is responsible for defining and prioritizing the product backlog, communicating with stakeholders, and ensuring that the development team is working on the most valuable work at any given time."
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1hmXNS5oo1PiB-RWQNyt6GLY3TgaIZ4qA",
    "label": "A"
  },
  {
    "id": 15,
    "word": "ART: Agile Release Train ",
    "definition":[ "The Agile Release Train (ART) is a framework used in Agile software development that allows large-scale development projects to be managed in a more coordinated and integrated way. The ART is a timeboxed, cross-functional team that delivers a potentially shippable increment of the product at the end of each iteration."
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1P-bx4oaLgg4hnWCUp44YpmSDOdiktkVk",
    "label": "A"
  },
  {
    "id": 16,
    "word": "Agile Transformation ",
    "definition": ["Agile transformation refers to the process of adopting and implementing Agile practices and values across an organization. The goal of Agile transformation is to create a culture of continuous improvement, collaboration, and innovation that enables organizations to respond to changing market and customer needs more quickly and effectively.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Jp3NwV7V3oTEKO4QOEkQPbPp92GsGyRR",
    "label": "A"
  },
  {
    "id": 17,
    "word": "Agile Values ",
    "definition": ["Articulated in the Agile Manifesto, they prioritize people over processes, working software over documentation, customer cooperation over contract negotiations, and adapting to change over sticking to a plan.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1qigSoOAsEV8WEZrIXyMozv0-swYsx0HM",
    "label": "A"
  },
  {
    "id": 18,
    "word": "Alpha Training",
    "definition": ["The first end-to-end testing of a product to ensure it meets the business requirements and functions correctly. it refers to a type of training that focuses on the foundational or fundamental skills required to succeed in a particular field or activity.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1cJxZPzlz-TjAan0Z04QsFcFiU8pg-iFG",
    "label": "A"
  },
  {
    "id": 19,
    "word": "Annual Recurring Revenue ",
    "definition": ["Annual Recurring Revenue (ARR) is a key metric used in business to track the revenue generated by a subscription-based business model. ARR refers to the annualized revenue that a company expects to receive from its current subscriptions in the upcoming year.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1e0k0l85Uu_S-WVumWxC0opGCgVf0Tb2U",
    "label": "A"
  },
  {
    "id": 20,
    "word": "Backlog Grooming ",
    "definition":["Backlog grooming is a process that agile teams use to review, refine, and prioritize items in their product backlog. The product backlog is a list of features, requirements, and enhancements that the team plans to deliver over a series of sprints.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1jKuGz0nRVYsy8VvcjAMHjPM4wblZWp-R",
    "label": "B"
  },
  {
    "id": 21,
    "word": "Behavioral Product Management ",
    "definition": ["An approach to product management that involves understanding and influencing user behavior to improve the effectiveness of a product. It combines principles from behavioral psychology and product management to create products that are more engaging, useful, and effective for users.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1wYC4XXs20wLHQVS5OCrQMbAu63HL80os",
    "label": "B"
  },
  {
    "id": 22,
    "word": "Beta Testing ",
    "definition": ["Beta testing is the process of testing a product or service in a real-world environment with a group of users who are representative of the product's target audience. The purpose of beta testing is to gather feedback on the product's performance, usability, and reliability from real users before it is released to the general public.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1eAIE72dDg4XZ9sozChXjAIuXSCLDfAmM",
    "label": "B"
  },
  {
    "id": 23,
    "word": "Bill of Material (BOM)",
    "definition": ["A comprehensive inventory of the raw materials, assemblies, subassemblies, parts, and components, as well as the quantities of each needed to manufacture a product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Cg1032pnS86Y75PrrluJa5DZbPbM3CPV",
    "label": "B"
  },
  {
    "id": 24,
    "word": "Bubble Sort ",
    "definition": ["Bubble Sort is a simple sorting algorithm that works by repeatedly swapping adjacent elements if they are in the wrong order. The algorithm gets its name from the way smaller elements \"bubble\"\" to the top of the list.\"",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1jLT1b59yiZxg5LWZmd-lGW5QuEIeObCF",
    "label": "B"
  },
  {
    "id": 25,
    "word": "Bugs",
    "definition": ["An error, flaw, or fault in a computer program or system that causes it to produce an incorrect or unexpected result, or to behave in unintended ways. bugs in product management can lead to delays, increased costs, and a product that does not meet the needs of the target audience. Therefore, it is essential to have proper communication, clear goals, and adequate testing and risk management to ensure a successful product development process.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1SmJKBUU3RpLisKKfll_AToVZvr6W9q2m",
    "label": "B"
  },
  {
    "id": 26,
    "word": "BI: Business Intelligence",
    "definition": ["Business Intelligence (BI) is a set of tools, technologies, and practices that help organizations collect, analyze, and interpret data to make informed business decisions. BI involves gathering and transforming data from various sources into meaningful insights and actionable information.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1rhh6I_KCUdgDxJEkJXg4-JYUPLnrP9s4",
    "label": "B"
  },
  {
    "id": 27,
    "word": "Burndown Chart ",
    "definition": ["A burndown chart is a graphical representation of the progress of work over time, typically used in agile software development methodologies. The burndown chart is used to provide visibility into the progress of the project, and to help the team identify any issues or impediments that are slowing down progress. ",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=11H41ztMtSYnrWM9jZfmmaNSu9bKy27XQ",
    "label": "B"
  },
  {
    "id": 28,
    "word": "Business Agility ",
    "definition": ["Business agility is the ability of an organization to quickly and effectively adapt to changes in the business environment. This includes the ability to respond to changing customer needs, new competitive threats, regulatory changes, and other external factors that may impact the organization.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1OMBzMe64Idh6h2SWLNrEe2KDyVsFRPCw",
    "label": "B"
  },
  {
    "id": 29,
    "word": "Business Model Canvas ",
    "definition": ["The Business Model Canvas (BMC) is a strategic tool used to visualize, design, and analyze a business model. It is a one-page document that helps entrepreneurs and organizations to identify the key elements of their business and understand how they all fit together.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Es7QdaSj-0F0sgvyF0rRkH_f81u6zsuV",
    "label": "B"
  },
  {
    "id": 30,
    "word": "Business Transformation ",
    "definition":["Business transformation is the process of fundamentally changing the way a business operates in order to achieve a specific strategic goal or respond to significant changes in the business environment. It used to describe what happens when a company makes fundamental changes to how it operates, typically with the aim of enhancing both operational and financial performance.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1JGsq5fWjI5dU1M8AZuFkPQgtcdgui5Vh",
    "label": "B"
  },
  {
    "id": 31,
    "word": "Buy-a-Feature",
    "definition": ["An approach to prioritizing a productâ€™s development in which the product team works directly with a group of customers or other stakeholders to learn which features they value most.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1PD7DZx90zjtZUPIcAkWzX1HT5K7jsKiJ",
    "label": "B"
  },
  {
    "id": 32,
    "word": "Buyer Persona ",
    "definition": ["A buyer persona is a detailed description of a target customer that includes demographic, psychographic, and behavioral characteristics, as well as their pain points, challenges, and goals.\n\nThe purpose of creating buyer personas is to better understand the target audience and their needs, which helps to guide marketing and sales strategies.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1FXLOP4hDDD6ZcTqTW8Jzy5dNs_9eluHJ",
    "label": "B"
  },
  {
    "id": 33,
    "word": "Cannibalization ",
    "definition": ["Cannibalization refers to a situation where a new product or service eats into the sales of an existing product or service offered by the same company. This typically happens when the new product is similar to the existing one, and the customers who would have purchased the existing product end up buying the new one instead.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=14dQEV0xD9KV8pgeEuE0Kd7YXLJxnQP8r",
    "label": "C"
  },
  {
    "id": 34,
    "word": "Certified Product Manager ",
    "definition": ["A Certified Product Manager is a professional who has obtained a certification demonstrating their expertise in managing and overseeing the development of products from ideation to market launch.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1PiLNVn_QP0I8mI4sPo6IczYP5JeK7hup",
    "label": "C"
  },
  {
    "id": 35,
    "word": "Change Enablement",
    "definition": ["Change Enablement refers to providing people with the necessary information and supportâ€“alongside tools, processes, and strategiesâ€“ to help them adapt and transition to change within an organization.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1T0fPBE_Kmjd-HiBCj5Oe4K5tLbYORGux",
    "label": "C"
  },
  {
    "id": 36,
    "word": "Change Management ",
    "definition": ["Change management is the process of planning, implementing, and managing changes within an organization. It is an approach that helps organizations transition from their current state to a desired future state while minimizing the negative impact on employees and customers. It involves guiding your product team through changes in the core product vision, like how the product is designed and developed and the users it targets.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=188wTlhQGez--yAP8eRA7HOKJ1OnHuDSl",
    "label": "C"
  },
  {
    "id": 37,
    "word": "Change Management Principles",
    "definition": ["Change management principles are a set of guidelines and best practices used to manage and facilitate changes within an organization.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1BXL4-HYT1yq6kZIOhcwCA7nawEYZ4ShX",
    "label": "C"
  },
  {
    "id": 38,
    "word": "Channels of Distribution ",
    "definition": ["Channels of distribution refer to the various paths through which products or services are moved from the manufacturer or producer to the end customer or user. The paths that products and services take on their way from the manufacturer or service provider to the end consumer.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1n7pZpLLcGECukJ_7WPIMpLkQlnH9pB4w",
    "label": "C"
  },
  {
    "id": 39,
    "word": "Chief Product Officer ",
    "definition": ["A Chief Product Officer (CPO) is a high-level executive responsible for overseeing the development and management of a company's products or services. The CPO typically reports to the CEO and is responsible for setting the strategic direction for the company's product development and management.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1iZ51IX5rrbofTk3liu-GxfrMVGWq8sj-",
    "label": "C"
  },
  {
    "id": 40,
    "word": "Churn",
    "definition": ["Churn in product management refers to the rate at which customers stop using a product or service and switch to a competitor. As a product manager, churn is an important metric to track because it can impact the success and growth of a product or business.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1acHZDNAw6hczWNrg3MIKUnslqgafC_AP",
    "label": "C"
  },
  {
    "id": 41,
    "word": "CIRCLED Method ",
    "definition": ["A framework that guides complete and thoughtful interview answers concerning product development and design. CIRCLES acronym: Comprehend the situation; identify the customer; report the customer's needs; cut, through prioritization; list solutions; evaluate tradeoffs, and summarize your recommendation.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1VJBAf_xEP6NKvEoj1flZSiE2jD0wn9DZ",
    "label": "C"
  },
  {
    "id": 42,
    "word": "Competitive Intelligence ",
    "definition": ["Competitive intelligence is an important aspect of product management, as it enables companies to understand their competitors and the market they operate in. By gathering and analyzing information about their competitors, companies can identify opportunities and threats, and make informed decisions about their products.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1HC7w9e44fGsUgr_w5DJ4-E6n_uBIqj3R",
    "label": "C"
  },
  {
    "id": 43,
    "word": "Competitive Analysis ",
    "definition": ["Competitive analysis is an important aspect of product management. It involves researching and analyzing competing products or services to understand their strengths, weaknesses, and unique selling points. The goal of competitive analysis is to help a product manager identify opportunities for differentiation, improve the product's value proposition, and develop a strategy for gaining market share.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1DcY-O41z1fleccZiwrQt4f7Vn-rhW3PD",
    "label": "C"
  },
  {
    "id": 44,
    "word": "Competitive Landscape ",
    "definition": ["In product management, the competitive landscape refers to the market landscape, which includes all the current and potential competitors of a product. Understanding the competitive landscape is essential in developing and executing a successful product strategy.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1omfpywcSraNSEApaJid-YD72R_u95hrn",
    "label": "C"
  },
  {
    "id": 45,
    "word": "Concept Review ",
    "definition": ["Concept review is a crucial process in product management, where the product team evaluates and reviews the feasibility, viability, and desirability of a product idea. The goal of concept review is to determine whether a product idea is worth pursuing and if it aligns with the company's overall goals and objectives.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1SvIE3gUcbydU5OBGCLeonwOKr6KVM1nL",
    "label": "C"
  },
  {
    "id": 46,
    "word": "Continuous Delivery ",
    "definition": ["Continuous Delivery is a software development practice that involves delivering software updates frequently and reliably to users. It's a critical part of product management as it helps ensure that new features and improvements are delivered quickly and efficiently to customers.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1QbBzyRs2NeJvyb22_3PddO9eleHMOBNa",
    "label": "C"
  },
  {
    "id": 47,
    "word": "Continuous Deployment",
    "definition": ["Continuous Deployment is a software development practice that involves automatically deploying code changes to production environments once they pass automated tests. It's a critical part of product management as it enables product teams to deliver new features and improvements to customers quickly and continuously.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1egAMObWxlcXn6n4paTkxrg-Pg782vk8A",
    "label": "C"
  },
  {
    "id": 48,
    "word": "Continuous Improvement ",
    "definition": ["Continuous Improvement is a critical part of product management that involves continuously analyzing, evaluating, and improving the product and the development process. It's a practice that aims to enhance the product's quality, usability, and value, while reducing the time and cost required to deliver it.\n\nContinuous Improvement involves several key activities, including analyzing user feedback, monitoring product metrics, identifying areas of improvement, and implementing changes to the product or development process.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=15iw4nkGnR2bl6B3Rrtub5OdbNSbunC1S",
    "label": "C"
  },
  {
    "id": 49,
    "word": "Continuous Integration ",
    "definition": ["Continuous Integration (CI) is a software development practice that involves integrating code changes from multiple developers into a shared code repository frequently and automatically. It's a critical part of product management as it helps ensure that code changes are compatible and can be tested quickly and efficiently.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1q6zBHuKst5VjLxTYMUS0q6YP3PIIe6Tz",
    "label": "C"
  },
  {
    "id": 50,
    "word": "Conversion Rate ",
    "definition": ["Conversion rate is a key metric in product management that measures the percentage of users who take a desired action, such as making a purchase, signing up for a service, or downloading an app, out of the total number of users who interact with a product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1YyjnKm7UYf2RrYv5hPYH7C7dxPP9TBcq",
    "label": "C"
  },
  {
    "id": 51,
    "word": "Cost of Delay",
    "definition": ["The Cost of Delay is a concept in product management that refers to the cost of waiting to deliver a product or feature. It is a critical metric that helps product managers understand the financial impact of delaying a product release or feature development.\n\nIt includes both the direct and indirect costs of delaying a product or feature. Direct costs include lost revenue, additional development costs, and increased marketing expenses. Indirect costs include lost market share, decreased customer satisfaction, and missed opportunities for growth.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=12usAY1OsB5JOCfLisVIKB0QKN7LRgPEJ",
    "label": "C"
  },
  {
    "id": 52,
    "word": "Crystal Agile Framework ",
    "definition": ["The Crystal Agile Framework is an Agile software development methodology that is focused on delivering high-quality software in small teams while emphasizing team communication, continuous improvement, and personal interaction. It is a lightweight methodology that prioritizes adaptability and flexibility over strict processes and procedures.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1vB10jluNkOX8X1v66PUHT67c5EYWVzCf",
    "label": "C"
  },
  {
    "id": 53,
    "word": "Cross-Functional Team",
    "definition": ["In product management, a cross-functional team is a group of individuals with diverse skills, knowledge, and expertise who work together to achieve a common goal. Cross-functional teams are typically composed of members from different departments, such as product management, engineering, design, marketing, sales, and customer support.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1IQ8Bs-LGP_IQHRYfxhrPxiEwbSmgWl57",
    "label": "C"
  },
  {
    "id": 54,
    "word": "Customer Empathy ",
    "definition": ["Customer empathy is the ability to understand and feel the emotions and experiences of customers. In product management, customer empathy is a critical skill that helps product managers better understand their customers and design products that meet their needs.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1jccoA5tpVMOeVJu_Or68YrXp6jL93mc_",
    "label": "C"
  },
  {
    "id": 55,
    "word": "Customer Acquisition Cost ",
    "definition": ["Customer Acquisition Cost (CAC) is a key performance metric in product management that measures the cost of acquiring a new customer. CAC is calculated by dividing the total cost of sales and marketing by the number of new customers acquired during a specific period.",
    
  ],"vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1hN6jBJZN79vWrQvlV5NKmtmxhHEMvzcn",
    "label": "C"
  },
  {
    "id": 56,
    "word": "Customer Advisory Board ",
    "definition": ["A Customer Advisory Board (CAB) is a group of selected customers who provide feedback and insights to a company's product management team. The CAB is typically made up of a small group of customers who are considered experts in their respective fields, and who have a strong interest in the company's products and services. The goal of a CAB is to gather information and feedback from customers that can be used to improve products and services, and to ensure that the company is meeting the needs of its customers.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1kkGiVyrFoRNO2CgZM619xwcwZzKBJKQc",
    "label": "C"
  },
  {
    "id": 57,
    "word": "Customer Experience",
    "definition": ["Customer experience (CX) is an essential aspect of product management. It refers to the overall experience that a customer has with a company's products or services, from the initial interaction to the post-purchase follow-up.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1C8fmaq6xIK-yq4d_YKuBbsJFijL2TUey",
    "label": "C"
  },
  {
    "id": 58,
    "word": "Customer Feedback",
    "definition": ["Customer feedback is an essential part of product management as it helps to ensure that the product is meeting the needs and expectations of its target audience. Gathering customer feedback provides product managers with insights that can help them make informed decisions about the product's development, marketing, and overall strategy.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1RflWZAxv7B2UrN4ipNU0wIayG9lSRm8K",
    "label": "C"
  },
  {
    "id": 59,
    "word": "Customer Journey Map ",
    "definition": ["A customer journey map is a visual representation of the various touchpoints and interactions a customer has with a product or service throughout their journey. It helps product managers understand the customer experience, identify pain points, and opportunities to improve the product or service. ",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1sJF7ti9sbBkDdLvVDkRRcxJ-87tuf7uc",
    "label": "C"
  },
  {
    "id": 60,
    "word": "Captive Product Pricing ",
    "definition": ["Captive product pricing is a pricing strategy where a company sells a product at a low price or even at a loss to attract customers, with the intention of making a profit on complementary products or services that are required to use the initial product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1wFUG4-KOdDGAgGW3ebbMhn7WOw5SQY-D",
    "label": "C"
  },
  {
    "id": 61,
    "word": "Customer Retention",
    "definition": ["Customer retention is the process of keeping existing customers engaged with a product or service over time. It is a critical aspect of product management, as retaining customers is often more cost-effective than acquiring new ones. ",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1AcRhBCkFzE__s7pEtL11Q8JEFBW1WnIG",
    "label": "C"
  },
  {
    "id": 62,
    "word": "Customer Success ",
    "definition": ["Customer success is the process of ensuring that customers are achieving their desired outcomes with a product or service. It is a critical aspect of product management, as it helps to increase customer satisfaction and loyalty, and ultimately drives business growth. ",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1k7xdlxVUdBijRQUz0wse6yNI-Z3SvYhP",
    "label": "C"
  },
  {
    "id": 63,
    "word": "Customer Validation ",
    "definition": ["Customer validation is the process of testing and validating a product or service with potential customers before launching it to the market. It is a critical aspect of product management, as it helps to ensure that the product meets customer needs and addresses their pain points.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1PG9tXbP_oQDArPO2cYc467rME3QhZHKl",
    "label": "C"
  },
  {
    "id": 64,
    "word": "DACI Decision Making Framework ",
    "definition": [
      "The DACI decision-making framework is a method used to clarify decision-making roles and ensure that everyone involved in a decision-making process is clear about their role and responsibilities. DACI stands for:",
      <p>Driver: The person who is accountable for making the final decision and ensuring that it is executed.</p>,
      <p>Approver: The person who has the authority to approve or reject the decision.</p>,
      <p>Contributor: The person or people who provide input and information to help inform the decision.</p>,
      <p>Informed: The person or people who are informed about the decision but do not have a say in the outcome.</p>
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1-ba5sAQuPNKL9t0Fzr0lTFnA2uWYId_P",
    "label": "D"
  },
  {
    "id": 65,
    "word": "DAU: Daily Active Users ",
    "definition": ["It refers to the number of unique users interacting with your application on a specific day.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1qzRAJUlHNpqryTqNoBepyKSD4AMsaXqx",
    "label": "D"
  },
  {
    "id": 66,
    "word": "Daily Scrum ",
    "definition": [
      "The Daily Scrum is a core practice in the Scrum framework for agile software development. It is a daily meeting where the development team gets together to review progress, discuss any issues or obstacles they are facing, and plan their work for the day.",
      <p>During the Daily Scrum, each team member answers three questions:</p>,
      <p>What did you do yesterday?</p>,
      <p>What are you planning to do today?</p>,
      <p>Are there any obstacles or issues that are blocking your progress?</p>,
      <p>The purpose of the Daily Scrum is to ensure that the team is working effectively and efficiently, and that everyone is aware of what is going on. It also helps the team identify and address any obstacles or issues that may be hindering progress.</p>
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1kaYftfj_X451742q1z9M76DeCag6bTTF",
    "label": "D"
  },
  {
    "id": 67,
    "word": "Data Product Manager ",
    "definition": ["A product professional responsible for data democratization and increasing the time to value the data itself. They design, build and manage the cross-functional development of a data platform, or a suite of specific data tools, to serve multiple customers.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1EcVC-UHfmsFhp9HNi8Dy0gqSDKrTjx5l",
    "label": "D"
  },
  {
    "id": 68,
    "word": "DEEP Backlog ",
    "definition": ["The acronym stands for: Detailed appropriately, Estimated, Emergent, and Prioritized that refers to the grooming characteristics a product backlog has.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ilmfDcalQL9NS7cw3vKvCr7rhSGt4dFo",
    "label": "D"
  },
  {
    "id": 69,
    "word": "Definition of Done ",
    "definition": ["A collection of criteria that must be completed for a project to be considered \"done\"\".\"",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1RffcYxn0wPFDhzulj1HQFIrZtCAEXlhj",
    "label": "D"
  },
  {
    "id": 70,
    "word": "Definition of Ready ",
    "definition": ["In product management, Definition of Ready (DoR) refers to the set of criteria that a product feature or requirement must meet before it is considered ready to be worked on by the development team.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1JsS0nmlh54CgknA6VyTdNgp0CHvUwM0K",
    "label": "D"
  },
  {
    "id": 71,
    "word": "Dependency Management",
    "definition": ["It describes a relationship between two initiatives that must be executed in a particular order. If Initiative A is dependent on Initiative B, then Initiative B must be completed first.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Fqzz65DBucQhlqEHkl0IOqFdKOIkFYIp",
    "label": "D"
  },
  {
    "id": 72,
    "word": "Design",
    "definition": ["The process of imagining, creating, and iterating products that solve users' problems or address specific needs in a given market.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "",
    "label": "D"
  },
  {
    "id": 73,
    "word": "Designers ",
    "definition": ["A product professional responsible for the user experience of a product, usually taking direction on the business goals and objectives from product management.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1avAQ6cnvY_w3og6cwewyXHvyGM0eKAPq",
    "label": "D"
  },
  {
    "id": 74,
    "word": "Design Thinking ",
    "definition": ["A problem-solving framework that starts with empathizing with users who experience a specific need, to collect deep user insight for clearly defining the need in focus for the organization.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1_BNlPEpz81xUHTggsEcTAZKOnxpI72-M",
    "label": "D"
  },
  {
    "id": 75,
    "word": "Design Concept",
    "definition": ["In product management, a design concept is a preliminary idea or vision for the overall design of a product or service. It is a high-level concept that captures the essence of the product or service and serves as a starting point for further design work.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1SQyp1q3jIutuvPrgUsapyb07U7WpAowB",
    "label": "D"
  },
  {
    "id": 76,
    "word": "Design Ops ",
    "definition": ["It refers to the orchestration and optimization of people, processes, and craft in order to amplify design's value and impact at scale.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1F-csMwZqbw4p1xAJWsH7H97DVi30E-wr",
    "label": "D"
  },
  {
    "id": 77,
    "word": "Development ",
    "definition": ["A series of steps that includes the conceptualization, design, development and marketing of newly created or newly rebranded goods or services.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1N5qgJOoTkqyxSXM9xl-Ijf09lJIJ4JGZ",
    "label": "D"
  },
  {
    "id": 78,
    "word": "Developers ",
    "definition": ["The ones that help create new products or improve existing ones, which helps the company effectively meet consumer's needs.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1w0w9nDApYLOsGkwNa_6ybMvxMcmIINT1",
    "label": "D"
  },
  {
    "id": 79,
    "word": "DevOps ",
    "definition": ["It is a natural extension of the Agile ethosâ€”smaller, quicker development cycles that leverage feedback to influence future development.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1f_3LQ6QCVg7DkmDDsVEgws7JurkitLDw",
    "label": "D"
  },
  {
    "id": 80,
    "word": "Digital Product Manager ",
    "definition": ["A product professional who is accountable for innovation planning, revenue growth, margin improvement, product experience and compliance across an entire product portfolio.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Xlpk014J1GOc7AJ_Zv_aQcGZxFQUhR0x",
    "label": "D"
  },
  {
    "id": 81,
    "word": "Digital Transformation",
    "definition": ["It is the integration of digital technology into all areas of a business, fundamentally changing how you operate and deliver value to customers.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1voKtCVO13zRgJtF9TkHryvMOfyE_B1MW",
    "label": "D"
  },
  {
    "id": 82,
    "word": "Disciplined Agile (DA)",
    "definition": ["It is a process decision framework that puts individuals first and offers only lightweight guidance to help teams optimize their processes according to the unique needs of each specific project.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1grg35q05CkanTycHKvy2nC6cdNpKqDFu",
    "label": "D"
  },
  {
    "id": 83,
    "word": "Disruption ",
    "definition": ["An innovation that represents a change in a product's direction, business model, or value proposition.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1jTfO7dkBi3mSYu2Rt8NZI0pU0wYatndI",
    "label": "D"
  },
  {
    "id": 84,
    "word": "Distinctive Competence ",
    "definition": ["It refers to a superior characteristic, strength, or quality that distinguishes a company from its competitors.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1DxkJ9gObdM4H1RUkr96lrPyKnEGm6ggK",
    "label": "D"
  },
  {
    "id": 85,
    "word": "Divergent Thinking ",
    "definition": ["A multi-lensed cognitive approach that encourages teams to think in terms of all possibilities, and not just what sits in their comfort zone.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1UafGZQQX2nKp68kb5ierw_c34CP75JEV",
    "label": "D"
  },
  {
    "id": 86,
    "word": "Documentation ",
    "definition": ["It consists of all the information on an organizationâ€™s product, how it functions, and all the features.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1BRajK5sTGs4Iso0_GbU7J0gJxbbeswUg",
    "label": "D"
  },
  {
    "id": 87,
    "word": "Dual-Track Agile ",
    "definition": ["A type of agile development in which the cross-functional product team breaks its daily development work into two tracks: discovery and delivery.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=10NPMiQ-y7nVr_zud7Do9puH6LvI_yh36",
    "label": "D"
  },
  {
    "id": 88,
    "word": "Dynamic Systems Development Method (DSDM)",
    "definition": ["It is an iterative Agile product development framework. It aims to work efficiently, delineate stages of the development life cycle and provide tangible benefits to all parties involved with a project.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1MrycDxrW5GiojZcEj_T2oAHzc-kGfvFN",
    "label": "D"
  },
  {
    "id": 89,
    "word": "Eisenhower Matrix",
    "definition": ["The Eisenhower Matrix is a simple but powerful productivity tool used in project management and personal time management. It helps individuals and teams prioritize tasks and make decisions by dividing tasks into four categories based on their level of importance and urgency.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Y_VqL3P-cv-3C0UIiizgdw6Lfu5hkQpe",
    "label": "E"
  },
  {
    "id": 90,
    "word": "End-User Era ",
    "definition": ["It refers to a new trend in how businesses buy software by changing from a revenue-driven model to a strategy of product-led growth. For example: Let the public find our product, use it free, and get comfortable with it (and maybe even dependent on it). When the free-trial period ends, theyâ€™ll be willing to pay to continue using it.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1phlSkzbEaX9bajO2JIe9oiuJTteOrCVw",
    "label": "E"
  },
  {
    "id": 91,
    "word": "Engineering Backlog ",
    "definition": ["Any list of unfinished, actionable tasks to be completed to achieve a strategic goal.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1eashw_DQBMZUEp72y-BjodoI2j2iygYW",
    "label": "E"
  },
  {
    "id": 92,
    "word": "Enterprise Architecture Planning ",
    "definition": ["A blueprint that defines the use of IT infrastructure and information systems to achieve business strategies and objectives.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=161zBGY6MuY5GedTtlZqdbLjpHfV07eYh",
    "label": "E"
  },
  {
    "id": 93,
    "word": "Enterprise Architecture Roadmap ",
    "definition": ["A roadmap that communicates how an organization will develop its infrastructure to prepare for changes and competition in the market.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1fy29YkklgiHjSd1-RlwTKz7hYioWvj1X",
    "label": "E"
  },
  {
    "id": 94,
    "word": "Enterprise Feedback Management ",
    "definition": ["The process of collecting, analyzing, and using customer feedback to improve your products and services.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1xF-pTFGr3qSyVnhZm39XOKLPA7yUFz3v",
    "label": "E"
  },
  {
    "id": 95,
    "word": "Enterprise Transformation ",
    "definition": ["An initiative that makes a fundamental change to how a company operates.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1A8d3F7pMuQ1jo2cwKZYC7FxosT0syEX-",
    "label": "E"
  },
  {
    "id": 96,
    "word": "Epic ",
    "definition": ["A body of work that a product team devises as they break down a strategic theme into smaller initiatives.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1BGFIYpijbVSZl-ISANc-s8lpKEcn3poF",
    "label": "E"
  },
  {
    "id": 97,
    "word": "eXtreme Programming (XP)",
    "definition": ["An agile software development framework that aims to produce higher quality software, and higher quality of life for the development team.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=13UATMZ0Q94Ylp1bCGKzMOkiLoR57fhfJ",
    "label": "E"
  },
  {
    "id": 98,
    "word": "Feature ",
    "definition": ["A product's traits or attributes that deliver value to end-users and differentiate a product in the market.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Cettbow1HZPopillLWxh6oiLF8svrNwH",
    "label": "F"
  },
  {
    "id": 99,
    "word": "Feature Audit ",
    "definition": ["An easy-to-use but powerful product management tool that provides businesses with a quick snapshot of their product's feature usage.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1SiugCmM3yuQBh7zXSOVXQMicyrnmxdaD",
    "label": "F"
  },
  {
    "id": 100,
    "word": "Feature Bloat ",
    "definition": ["A term used to denote what happens when a product has too many features and functionalities.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Xxe8DwPimTTJIkvl5RYwV4zUDLSr-sYk",
    "label": "F"
  },
  {
    "id": 101,
    "word": "Feature Creep",
    "definition": ["A phenomenon that happens when a product team continues adding features to the point that they undermine the product's value.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1zvCkIhJvZKkdHLovLlDKKAgiATl3gfzM",
    "label": "F"
  },
  {
    "id": 102,
    "word": "Feature Driven Development ",
    "definition": ["An Agile methodology for developing software, that is customer-centric, iterative, and incremental, with the goal of delivering tangible software results efficiently.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1vY-y_Be8noi6XyKwcJy_cqTGRInUGbLk",
    "label": "F"
  },
  {
    "id": 103,
    "word": "Feature Factory",
    "definition": ["A term used to describe a company that is more interested in pushing out features while caring less about solving real problems.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1C8gyZShDTG1O-Lfdv_fm18nrluFtiKsb",
    "label": "F"
  },
  {
    "id": 104,
    "word": "Feature Flag ",
    "definition": ["A software development technique that turns certain functionality on and off during runtime, without deploying new code.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1fuIX5P3VXvdk2ewFX3X0WpUksS8xjhCF",
    "label": "F"
  },
  {
    "id": 105,
    "word": "Feature Kickoff",
    "definition": ["A meeting in which a product manager and relevant stakeholders set plans, goals, and responsibilities for the team's work on a new feature.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1lUWk7S1J5HQh7A-62HC-TZK_nEfiGCL3",
    "label": "F"
  },
  {
    "id": 106,
    "word": "Feature-Less Roadmap",
    "definition": ["A roadmap that communicates the upcoming plans for the product without simply listing a bunch of features in chronological order.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1J15nEx-8o-m2pAbcjuokIZJ-MigpkjHW",
    "label": "F"
  },
  {
    "id": 107,
    "word": "Feature Outcome Assessment ",
    "definition": ["An assessment that focuses on specific, measurable outcomes rather than product features.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1cK49PWTZzAI6hLr2YfSDKQo2aoQ_0Bkb",
    "label": "F"
  },
  {
    "id": 108,
    "word": "Feedback Management ",
    "definition": ["Feedback management is the process of collecting, analyzing, and using feedback from customers, stakeholders, and team members to improve the quality of a product or service. It is an essential part of the product management process and helps to ensure that the product or service is meeting the needs of its users and achieving its goals.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1cvEj_DfBn-EE2iEFGE1xyObw9QaqV2NC",
    "label": "F"
  },
  {
    "id": 109,
    "word": "Fibonacci Agile Estimation ",
    "definition": ["A point-based prioritization method that helps product managers estimate the time and resources needed to complete their tasks.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1JBkx5_fRXy9LhLviAzSVNptcmKvQM7UV",
    "label": "F"
  },
  {
    "id": 110,
    "word": "Finance Product Manager ",
    "definition": ["A product professional that helps conceptualize, develop, test, and launch new financial systems and products, while overseeing the marketing and progress of existing products.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=12mu2_jLwGyrWCF6yH_o1A6zXoqOL607P",
    "label": "F"
  },
  {
    "id": 111,
    "word": "Fundamentally New Product ",
    "definition": ["A breakthrough product that delivers functionality and a user experience unlike any other product currently available.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Z5gHvSBk3IdksDzNwTHVTO5LMHmDEyQ_",
    "label": "F"
  },
  {
    "id": 112,
    "word": "Gantt Chart ",
    "definition": ["A visualization that helps in scheduling, managing, and monitoring specific tasks and resources in a project.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1GiWHedCfucK9-IIJiFKth8EwDpMYTKca",
    "label": "G"
  },
  {
    "id": 113,
    "word": "General Availability (GA)",
    "definition": ["The step in the product development process when the product is made publicly available for sale.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1wXhkjCVXSkxnZJ14jEPtHKRA7yekixwH",
    "label": "G"
  },
  {
    "id": 114,
    "word": "GIST Planning ",
    "definition": ["It stands for Goals, Ideas, Step-Projects, and Tasks. It is a lightweight approach to product planning, with the goal of reducing management overhead, increasing velocity, and producing products that better meet the needs of the market.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1lM32exPlTjd-T7vAhQNdNk6w1sktsrNg",
    "label": "G"
  },
  {
    "id": 115,
    "word": "Goal ",
    "definition": ["A measurable, time-bound objective that supports your product vision.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1b6bvDbEwTGVgj01yMfD-d9-bCIMKG1ye",
    "label": "G"
  },
  {
    "id": 116,
    "word": "GOOB: Get-Out-Of-The-Building ",
    "definition": ["Coined by Steve Blank, it is aimed at startups directing their energy towards customer research, by getting to the customers in their own environment.\n",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1kN9h1cbnYR9AwIidwFzGY4zOAIw1XoRa",
    "label": "G"
  },
  {
    "id": 117,
    "word": "GTM: Go-To-Market Strategy ",
    "definition": ["A plan that details how an organization can engage with customers to convince them to buy their product or service and to gain a competitive advantage.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1R5c87GIDiWfmpcTz85vnvEgjou3-SHoC",
    "label": "G"
  },
  {
    "id": 118,
    "word": "Greenfield Project ",
    "definition": ["A Greenfield project is a type of project that involves building a new system, application, or product from scratch, without any pre-existing infrastructure or legacy components. ",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=11HThLk78Icl3cTKnZTBaCG2fFqkoG4yD",
    "label": "G"
  },
  {
    "id": 119,
    "word": "Group Product Manager ",
    "definition": ["A product leader who manages the product team responsible for a particular group of products.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1C05YoIXQsPjF7CxJ-nJIpjtDBE4iQ4v4",
    "label": "G"
  },
  {
    "id": 120,
    "word": "Growth Product Manager ",
    "definition": ["The process of optimizing a product by tracking and using data to make evidence-based improvements.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1vM2med8_QmTshT6hnPBXjxAQOxF8Ipih",
    "label": "G"
  },
  {
    "id": 121,
    "word": "Hardware Product",
    "definition": ["A computing device with a specific function and limited configuration ability.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1M8YX2BnlX75FtZbq8gszsMLHY1YSqp9c",
    "label": "H"
  },
  {
    "id": 122,
    "word": "HEART Framework",
    "definition": ["A methodology for aligning a product's goals with a set of five core metrics represented by the acronym HEART: Happiness, Engagement, Adoption, Retention, and Task success.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1kKavcvkXFqgMxed0SyRN0lamsKX84kHJ",
    "label": "H"
  },
  {
    "id": 123,
    "word": "Hook Model",
    "definition": ["A four-phase process that businesses can use to create products or services used habitually by customers: a trigger to begin using the product, an action to satisfy the trigger, a variable reward for the action, and some type of investment that, ultimately, makes the product more valuable to the user",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Xz1maah9vErOyT4zDLQ_96nBNRCfO9B3",
    "label": "H"
  },
  {
    "id": 124,
    "word": "ICE Scoring Model",
    "definition": ["This model helps to prioritize features and ideas by multiplying three numerical values assigned to each project: Impact, Confidence and Ease. Each item being evaluated gets a ranking from one to ten for each of the three values, those three numbers are multiplied, and the product is that item's ICE Score",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1vGmDvesIZKGSEBgXf0AI5dQaDIng0iAq",
    "label": "I"
  },
  {
    "id": 125,
    "word": "Idea Backlog",
    "definition": ["A list of ideas that need more discussion or vetting before the company can decide whether to move forward with them.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "",
    "label": "I"
  },
  {
    "id": 126,
    "word": "Idea Management",
    "definition": ["A systematic framework for collecting, analyzing, and prioritizing ideas to improve existing products or create new ones.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1kqfrnB0kovNS8PPFQtoDvSO4pO_T9GB-",
    "label": "I"
  },
  {
    "id": 127,
    "word": "Ideation",
    "definition": ["An intentional exercise to generate a high volume of ideas for a business's products, services, and customer experience.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1qPI24-rNkznH-8dJuX3kTkMOxq858M0x",
    "label": "I"
  },
  {
    "id": 128,
    "word": "Impact Mapping ",
    "definition": ["A graphic strategy planning method to decide which features to build into a product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1A8kGO0xl_sGrUhpCy2QntL196vzhAPqV",
    "label": "I"
  },
  {
    "id": 129,
    "word": "Implicit Requirements ",
    "definition": ["The features of a product or app which are so fundamental that they don't need to be explicitly specified during development.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1EbV6_NfdBArShj73HpJLhWpyrjf6MfZl",
    "label": "I"
  },
  {
    "id": 130,
    "word": "Incident Management Practice ",
    "definition": ["The process used by DevOps and IT Operations teams to respond to an unplanned event or service interruption and restore the service to its operational state.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1BwLlwvK9fNMsizuczTHgkijE5YgDa9iC",
    "label": "I"
  },
  {
    "id": 131,
    "word": "Increment Product ",
    "definition": ["In product management, an increment product is a product that is built and released in stages or increments, with each increment adding new functionality or features to the product. The purpose of building a product incrementally is to quickly deliver value to users and to gather feedback, which can be used to improve subsequent increments.\n\n",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1EX5oLNCKaaWo0owBaHHJqbRcjP6U24vU",
    "label": "I"
  },
  {
    "id": 132,
    "word": "Incremental Innovation ",
    "definition": ["A series of small improvements made to a company's existing products or services.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1_ihncl8Czkw1XjfALdd_rXAiTM9fWm8I",
    "label": "I"
  },
  {
    "id": 133,
    "word": "Information Flows ",
    "definition": ["A two-step process for creating a shared understanding of product strategy.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1RP8R3IhQedETsJSb81sqKOcMqtDmXQFL",
    "label": "I"
  },
  {
    "id": 134,
    "word": "Information Technology ",
    "definition": ["The hardware and software used to create, store, transmit, manipulate, and display information and data.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1lPxwlV7wwrJ829Ra0iq6stvZxSO7BwIP",
    "label": "I"
  },
  {
    "id": 135,
    "word": "Innovation Management ",
    "definition": ["It involves the process of managing an organization's innovation procedure, starting at the initial stage of ideation, to its final stage of successful implementation.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1hKNoJq97JxjQoFCLuS3szsO5rEC4386-",
    "label": "I"
  },
  {
    "id": 136,
    "word": "Intuition",
    "definition": ["The observation of human behavior, trained by data, and applied to software.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1XzO29b5-iXQwBAIpbt7npVMA17F8s1Zr",
    "label": "I"
  },
  {
    "id": 137,
    "word": "Intuitive Design ",
    "definition": ["It advocates that the use of the design should be easy to understand, regardless of the users' experience, knowledge, language skills, or concentration level.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1rF9O_70MQJKm6lMjsMWczUkmNjDK3G-P",
    "label": "I"
  },
  {
    "id": 138,
    "word": "Integration ",
    "definition": ["The act of bringing together smaller components or information stored in different subsystems into a single functioning unit.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1N1wgXy1Xta37TQV-r6mjP2Bm92NvcXOj",
    "label": "I"
  },
  {
    "id": 139,
    "word": "Internet of Things (IoT) Product Manager ",
    "definition": ["A product professionals who drive the business strategy and development of products connected to the internet.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1OyPqFZN9INTUClC4NpwlABwYG1_Mm6KE",
    "label": "I"
  },
  {
    "id": 140,
    "word": "IT Project Manager ",
    "definition": ["A professional charged with overseeing the process of planning, executing and delegating responsibilities around an organization's information technology pursuits and goals.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Pb5NXr7gfuThTQBo4jsvmNzVUzddMwfs",
    "label": "I"
  },
  {
    "id": 141,
    "word": "Iteration ",
    "definition": ["In product management and software development, an iteration is a period of time during which a product or project is developed and improved. An iteration typically involves several stages, including planning, development, testing, and feedback, and is often part of an agile development methodology.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1uRh-ulpCqYiPYYrcKBcIbaci_WnkQ1Pp",
    "label": "I"
  },
  {
    "id": 142,
    "word": "Iterative Testing ",
    "definition": ["The process of making small changes to products, based on insights gained from previous changes.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=18j63ZHw7kCnnZQVcckop-mc-eGCdV9pk",
    "label": "I"
  },
  {
    "id": 143,
    "word": "Jira",
    "definition": ["Derived from the Japanese word 'Gojira', meaning Godzilla. It allows product teams to track issues, manage projects, and automate workflows.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1F4PnEHbWMOykpz0J3a8N6vBwYcwPOUeH",
    "label": "J"
  },
  {
    "id": 144,
    "word": "JTBD: Jobs-To-Be-Done",
    "definition": ["A framework based on the idea that customers 'hire' services or products to get their 'jobs' done.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1jin0JFhVrSzpl1G2G4FjYPN0TbEpqi3_",
    "label": "J"
  },
  {
    "id": 145,
    "word": "Kaizen",
    "definition": ["An approach to creating continuous improvement based on the idea that small, ongoing positive changes can reap significant improvements.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Df5PI9mTRELSkEbvVWViEUEoWDuiKojC",
    "label": "K"
  },
  {
    "id": 146,
    "word": "Kanban",
    "definition": ["An approach you can use to design, manage, and improve flow systems for product development work.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1aLDx8sUiKDDSzrjoSY1HWWhS10jd44rE",
    "label": "K"
  },
  {
    "id": 147,
    "word": "Kanban Board",
    "definition": ["An agile project management tool designed to help visualize work, limit work-in-progress, and maximize efficiency (or flow).",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1LkXQULW1JuKlLQhu3ZC1meyfcgefazfZ",
    "label": "K"
  },
  {
    "id": 148,
    "word": "Kanban Roadmap ",
    "definition": ["A roadmap to structure product development plans around tasks and gain at-a-glance visual insights into progress.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1omZrQxscKMZ8L4lJpWRhY0DJUOhGHnf-",
    "label": "K"
  },
  {
    "id": 149,
    "word": "Kano Model",
    "definition": ["A unique way of prioritizing new features that puts customers are the heart of product or service development.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1l2_R0VqA7f-YdKyvQFClEL18DS7VzmdJ",
    "label": "K"
  },
  {
    "id": 150,
    "word": "KPIs: Key Performance Indicators ",
    "definition": ["A set of quantifiable measurements used to gauge a company's overall long-term performance.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1KU9M-FpRNQ02_gYS1V7544ITtRqVl369",
    "label": "K"
  },
  {
    "id": 151,
    "word": "Lead Product Manager",
    "definition": ["A senior product professional in charge of managing the conception, development, testing, and launch of a new product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1r6W8SNvnoGMK_uoep5N0QqM1TVuRxOOu",
    "label": "L"
  },
  {
    "id": 152,
    "word": "Lean Software Development",
    "definition": ["A concept that emphasizes optimizing efficiency and minimizing waste in the software development process.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1xqjfJqIggmym2HSFbF5JFCmfDfhUcKGB",
    "label": "L"
  },
  {
    "id": 153,
    "word": "Learn Product Development ",
    "definition": ["A series of steps that includes the conceptualization, design, development and marketing of newly created or newly rebranded goods or services.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=149Lnjdf8eKSNyIf-cHd6L1Pu7lQ_Fy-j",
    "label": "L"
  },
  {
    "id": 154,
    "word": "LeSS (Large Scale Scrum)",
    "definition": ["A framework for scaling scrum to multiple teams who work together on a single product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=19YFB7lKoUd0s2kGwthF-3OYryYVX1C4i",
    "label": "L"
  },
  {
    "id": 155,
    "word": "Lifetime Value (LTV)",
    "definition": ["An estimate of the average revenue that a customer will generate throughout their lifespan as a customer.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1q6kOGwdHoeoDAs74ZWDTIWVfg16WGrTh",
    "label": "L"
  },
  {
    "id": 156,
    "word": "Lifestyle ",
    "definition": ["Items that have a unique selling proposition that is appealing to your target market.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1KLB3hrQbfYlmMkg2QB7TsBz3lQE0XQ8U",
    "label": "L"
  },
  {
    "id": 157,
    "word": "MRD: Market Requirements Documents ",
    "definition": ["It describes the overall market opportunity - the size of the market, the customers you will target, and competitors.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "",
    "label": "M"
  },
  {
    "id": 158,
    "word": "Market Validation ",
    "definition": ["The process of determining if there's a need for your product in your target market.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1idea3PXyDLJgqyyqC8PPfaXTCQJ0WMjk",
    "label": "M"
  },
  {
    "id": 159,
    "word": "Method of Procedure",
    "definition": ["A step-by-step project plan, guiding employees or teams to the completion of a specific task.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1hQkF2-v6OciRGoGK0KU94lzQI728xOKu",
    "label": "M"
  },
  {
    "id": 160,
    "word": "MVE: Minimum Viable Experience ",
    "definition": ["It refers to the minimum 360 brand experience from interfering with the product, including marketing and customer support.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1S5nsZ3mYT6igzLm-xit37lgpSBguydGX",
    "label": "M"
  },
  {
    "id": 161,
    "word": "MVF: Minimum Viable Feature",
    "definition": ["A small-scale feature that can quickly be built and rolled outâ€”using minimal resourcesâ€”to a target population to test the feature's usefulness and adoption.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1chJPAogOX46ohVhpw5YW9jevSyXSpS3a",
    "label": "M"
  },
  {
    "id": 162,
    "word": "MVP: Minimum Viable Product ",
    "definition": ["A product with enough features to attract early-adopter customers and validate a product idea early in the product development cycle.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1chJPAogOX46ohVhpw5YW9jevSyXSpS3a",
    "label": "M"
  },
  {
    "id": 163,
    "word": "Mockup",
    "definition": ["In product design, a mockup is a visual representation of a product, typically created as a rough draft or prototype. Mockups are used to convey the design, layout, and functionality of a product to stakeholders, including designers, developers, and clients.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1n7ujFIvj9Mp8EXBICvaxjyqlacyUwInP",
    "label": "M"
  },
  {
    "id": 164,
    "word": "MRR: Monthly Recurring Revenue ",
    "definition": ["A calculation of revenue generation by month and conveys an up-to-date measurement of financial health.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1RG6sRr4pNuGKD2R-VLCfz8izLikbdXXA",
    "label": "M"
  },
  {
    "id": 165,
    "word": "MoSCoW Prioritization",
    "definition": ["A tool for establishing a hierarchy of priorities during a project.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1llCz52myls2s1mQyaICO5G3hH4awhImU",
    "label": "M"
  },
  {
    "id": 166,
    "word": "Needfinding",
    "definition": ["A qualitative approach of research that is aimed at finding out unknown or unacknowledged needs of the users.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1_yqSRhNskjf_6Li4OgkLS1jeams9SLoh",
    "label": "N"
  },
  {
    "id": 167,
    "word": "Net Promoter Score",
    "definition": ["A metric companies use to measure their customers' propensity to advocate for the brand.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=17RphUhLUlOYoz10YTF1ghBm9iUUQhpHN",
    "label": "N"
  },
  {
    "id": 168,
    "word": "OKR:  Objectives and Key Results",
    "definition": ["A goal-setting practice that enables teams to achieve their goals by setting simple and measurable results.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=19xnGe5QMH9M6dLsPM0AenQAsxpE_osnt",
    "label": "O"
  },
  {
    "id": 169,
    "word": "Opportunity Scoring",
    "definition": ["A way to prioritize feature development by identifying the features that customers consider important but underdeveloped or otherwise disappointing.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1DYlmbSdXmYQWdhBVrA141EANIVo50YjY",
    "label": "O"
  },
  {
    "id": 170,
    "word": "Opportunity Solution Tree",
    "definition": ["A visual aid that helps enable the product discovery process through the non-linear organization of ideation flows, experimentation, and identification of gaps.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1okkB_uxnpUya9SdAd9Tt1I9zyEc6yZ1_",
    "label": "O"
  },
  {
    "id": 171,
    "word": "Pair Programming ",
    "definition": ["An agile software development practice in which two programmers team up at one workstation to maximize efficiency.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Cv_MgsZV_D1XNjXS0b2KWFC1qSvAE8fK",
    "label": "P"
  },
  {
    "id": 172,
    "word": "PDCA Cycle",
    "definition": ["It stands for Plan-Do-Check-Act, a problem-solving iterative technique that uses four steps to improve business processes.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1hwPcxJzEqFL2NBNY6gI7AcHsSiEc2QOs",
    "label": "P"
  },
  {
    "id": 173,
    "word": "Pendo",
    "definition": ["A product-analytics app built to help software companies develop products that resonate with customers.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1yEpQBctzJaWgwntmSS9Qzc8PeBWWDDm6",
    "label": "P"
  },
  {
    "id": 174,
    "word": "Persona",
    "definition": ["A fictional example of product users, including their key behaviors, goals, and responsibilities.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1-JlVvzYy_j44MOPiiTIF7oDecjAPywDI",
    "label": "P"
  },
  {
    "id": 175,
    "word": "PERT Chart",
    "definition":["It stands for program evaluation and review technique - a tool used to schedule, organize, and map out tasks within a project.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1rysXuqsfD0T5dzZCGw6KZlBCPVNP61pd",
    "label": "P"
  },
  {
    "id": 176,
    "word": "Pivot",
    "definition": ["A change in the direction of your business when you realise that your current products or services are no longer meeting the demands of your clients.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1wD-IhZy5S2k8ZuaCVsW3af9TwxKQ0f5P",
    "label": "P"
  },
  {
    "id": 177,
    "word": "Planning Poker ",
    "definition": ["A gamified technique that development teams use to guess the effort of project management tasks.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ZTJuIyFaP8F2-fOywA3wCNz0SXOa2DMZ",
    "label": "P"
  },
  {
    "id": 178,
    "word": "Platform Product Manager ",
    "definition": ["A product professional who is responsible for prioritizing and supporting the work of multiple consumer-facing products and providing a cohesive vision across the organization.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ovU5c1v9uzI70psEuOq75ZeK079u08C5",
    "label": "P"
  },
  {
    "id": 179,
    "word": "Prioritization ",
    "definition": ["A strategy used to decide what products, features, or initiatives product management or engineering teams should work on first.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1P10v4xI728YzLfqnJJFqVWc4dQEbYUAF",
    "label": "P"
  },
  {
    "id": 180,
    "word": "Product ",
    "definition": ["An item or a service offered on the market and designed to meet the needs or wishes of customers.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=14Z6Vq6XnUHrAXgoJKs_IANK7ilpwUfiT",
    "label": "P"
  },
  {
    "id": 181,
    "word": "Product Adoption ",
    "definition": ["The process by which people learn about your product or app and start using it to accomplish their goals.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1DyNryoMj6HPTrJaJVn9N8RvxvQUVlHd_",
    "label": "P"
  },
  {
    "id": 182,
    "word": "Product Analytics ",
    "definition": ["The process of understanding and answering questions related to customers' behaviors when they interact with your products.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1HBUN_wfk-DuGQcL4Vs7E0iaVCci72nAe",
    "label": "P"
  },
  {
    "id": 183,
    "word": "Product Architecture ",
    "definition": ["The assignment of the functional elements of a product to the physical elements of the product. ",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1mvw85ewdNagJAxGpSrgSOh9gnQiVLYtW",
    "label": "P"
  },
  {
    "id": 184,
    "word": "Product Backlog ",
    "definition": ["A prioritized list of work for the development team that is derived from the roadmap and its requirements.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1p2UpiaBcvdElaCMdE1yTS7-ApALyrloG",
    "label": "P"
  },
  {
    "id": 185,
    "word": "Product Brief",
    "definition": ["A brief document that contains information on a product's goals, attributes, and guidelines.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1W82HCuyXgEOGrzw8QqaeQWcMITaGVJmS",
    "label": "P"
  },
  {
    "id": 186,
    "word": "Product-centric ",
    "definition": ["It describes a company focused on the details of its products above other considerations, including its customers' needs.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1orbLTu-Y1HLvwUS3wm3PgCX8pOsRQe_u",
    "label": "P"
  },
  {
    "id": 187,
    "word": "Product Consolidation ",
    "definition": ["It refers to combining several products that sell separately but have overlapping functionality or user personas into one product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1H3YwZIX3FrpNan-KIqAzZBwj58i0umrO",
    "label": "P"
  },
  {
    "id": 188,
    "word": "Product Council",
    "definition": ["A forum or group of key stakeholders existing to set the direction for and review the product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1N_ir7i2fyCJwPQ31VdizFTorffFuT7uc",
    "label": "P"
  },
  {
    "id": 189,
    "word": "Product Critique",
    "definition": ["It objectively analyzes a product's functionality, design, and user experience.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1rFOFZ7Mig2dvbRFOYVpQm8dI3TZjoL8F",
    "label": "P"
  },
  {
    "id": 190,
    "word": "Product Development Cycle ",
    "definition": ["The process of taking a product from an idea through its market release and beyond, essentially be broken down into seven stages: ideation, research, planning, prototyping, sourcing, costing, and commercialization.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "",
    "label": "P"
  },
  {
    "id": 191,
    "word": "Product Development Manager",
    "definition": ["A product professional who is responsible for identifying new opportunities for developing a new marketable product from concept to distribution.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1JHK3krGXmI8rlRHUZSDK9zG3RYXqsQbh",
    "label": "P"
  },
  {
    "id": 192,
    "word": "Product Development Process",
    "definition": ["Product Development Process refers to the complete process of taking a product to market, starting from identifying a market need, researching the competition, ideating a solution, developing a product roadmap, and building a minimum viable product (MVP).",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=172XGNOiD0ZJOvnCdwP2FYe1qfIdd6RNt",
    "label": "P"
  },
  {
    "id": 193,
    "word": "Product Differentiation ",
    "definition": ["The characteristic or characteristics that make your product or service stand out to your target audience. ",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1WUwQwfzXfR7sW112MaLKxm5XsEDLBcEx",
    "label": "P"
  },
  {
    "id": 194,
    "word": "Product Discovery ",
    "definition": ["A process that helps product teams refine their ideas by deeply understanding real user problems and then landing on the best way to solve them.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Cj_kIOpK2WZpcCBneDvyGAHVuaMQoOAr",
    "label": "P"
  },
  {
    "id": 195,
    "word": "Product Disruptor",
    "definition": ["An innovation that represents a change in a product's direction, business model, or value proposition.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1aJGae96t0G_JROt0PAldbPPHmlhhnruH",
    "label": "P"
  },
  {
    "id": 196,
    "word": "Product Enablement ",
    "definition": ["A corporate learning program that helps the company's employees gain relevant knowledge about a certain product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1FRE5Z9XE3SzUapiLeWyne4qqs9fP0_AJ",
    "label": "P"
  },
  {
    "id": 197,
    "word": "Product Excellence ",
    "definition": ["An approach to product management that emphasizes getting the right products to market faster through deep user insight, a clear product strategy, and an inspiring roadmap.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=13PYyQHzq8iua5VOhidaxfAOve5Xpi7Fh",
    "label": "P"
  },
  {
    "id": 198,
    "word": "Product Launch",
    "definition": ["The process a company undertakes to bring its new or updated products to market.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1fmDXC0prF2xxRfFeo4UqOgOAKg7jYq6G",
    "label": "P"
  },
  {
    "id": 199,
    "word": "Product Launch Management ",
    "definition": ["The process of coordinating all strategic efforts needed for a successful market release",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1NN84we0Y_YpJ5w3S_6st6WXKw9iSdw7Z",
    "label": "P"
  },
  {
    "id": 200,
    "word": "Product Launch Manager ",
    "definition": ["A product professional primarily in charge of overseeing and spearheading the efforts in introducing new products and services to the market.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1dw4Gykkh1uQTX9WrW8KkK7-6p4dW0q_q",
    "label": "P"
  },
  {
    "id": 201,
    "word": "Product Launch Timeline",
    "definition":["A predetermined plan that is used by organizations to strategically plan their product launch for maximum benefit.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1QA6QdUgvoWRvajj2cE8AiUujNQok7hyW",
    "label": "P"
  },
  {
    "id": 202,
    "word": "Product Leadership ",
    "definition": ["Product leadership is a management approach that focuses on creating and leading high-performing product teams. It involves developing a product vision, aligning the team around that vision, and driving the team to achieve specific business goals.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1-MEAZLwIFJLeZ7pGlp3FSNiHYvnokT8D",
    "label": "P"
  },
  {
    "id": 203,
    "word": "Product-Led Growth ",
    "definition": ["A business strategy that relies on using your product as the main vehicle to acquire, activate, and retain customers.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ctyHv9rgg-ldtYuQmngw_DUyBm3KbfFc",
    "label": "P"
  },
  {
    "id": 204,
    "word": "Product Life Cycle",
    "definition": ["A management tool that evaluates a product's journey from development to withdrawal from the market.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1-8zXMTCZPB0zhQwRYpp3dbcDPvGXmKOc",
    "label": "P"
  },
  {
    "id": 205,
    "word": "Product Management Audit",
    "definition": ["A complete, objective review of a company's product strategy and product management processes.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=14FPvvm8ZjP8v41sI5WEUnqyobNAhNpF-",
    "label": "P"
  },
  {
    "id": 206,
    "word": "Product Management Talent ",
    "definition": ["The pool of potential candidates to consider for building the product team and filling key product roles.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=12RH1NTc4b224CArr61FxZYgWz_EkZb4H",
    "label": "P"
  },
  {
    "id": 207,
    "word": "Product Manager ",
    "definition": ["A product professional who identifies the customer need and the larger business objectives that a product or feature will fulfill, articulates what success looks like for a product, and rallies a team to turn that vision into a reality.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1bdQQQILt_uUjl8jdMJ-QIboyN7UxnSVR",
    "label": "P"
  },
  {
    "id": 208,
    "word": "Product-Market Fit",
    "definition": ["It describes a product or service that effectively fulfills the underserved needs of the target market in a way that can sustain growth and profitability.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=18y0kTFkArLedIf0AniDPnSyZrWMb2dT2",
    "label": "P"
  },
  {
    "id": 209,
    "word": "Product Marketing Manager",
    "definition": ["A leadership position that entails planning out, guiding, and taking accountability for marketing campaigns, strategies, and other marketing-related initiatives of (mostly) new products. ",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=10Md2eyEejgGmOBp7-yQu_i00e5jWMT3r",
    "label": "P"
  },
  {
    "id": 210,
    "word": "Product Metrics",
    "definition": ["The quantifiable data points that a business tracks and analyzes to gauge the success of its product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1p-J8isA_bkGMEwZk_vKhkfmTzjSQYoxu",
    "label": "P"
  },
  {
    "id": 211,
    "word": "Product Mission ",
    "definition": ["A clear, concise statement that explains the product's highest-level purpose.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=19RbfxGZO7nNp-MR0c5YKgXeFGD_T5fUx",
    "label": "P"
  },
  {
    "id": 212,
    "word": "Product Mix Strategy",
    "definition": ["The total number of product lines and individual products or services offered by a company.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ioPGYcsTIcLG7qiDpG72vYn5pU_HERoD",
    "label": "P"
  },
  {
    "id": 213,
    "word": "Product Operations ",
    "definition": ["An operational function that optimizes the intersection of product, engineering, and customer success.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1NKruAhtPcpD7bjz7dDCEwXxhAIeoGggh",
    "label": "P"
  },
  {
    "id": 214,
    "word": "Product Optimization ",
    "definition": ["The process of refining and improving a product to make it more valuable to current users and more attractive to new ones.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1uG2f611B_WdqVk-k_-6A-DXy4KovirCd",
    "label": "P"
  },
  {
    "id": 215,
    "word": "Product Owner ",
    "definition": ["A product professional who is responsible for maximizing the value of the product by creating and managing the product backlog.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1kfElBSUeDZeAJD3nbr89u6DUYI5rbDT8",
    "label": "P"
  },
  {
    "id": 216,
    "word": "Product Porfolio Management ",
    "definition": ["An approach to managing the balance of investments in a company's product initiatives to increase market share and revenues.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1C9VpofJ2uZHNMDqEiURf_uxlizNVRqz5",
    "label": "P"
  },
  {
    "id": 217,
    "word": "Product Portfolio Manager ",
    "definition": ["A product professional who strategically oversees all of the products in a business's portfolio and ensures alignment with the organization's overall strategy.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1mwHksCO7tWmDQD0-q9S4pSIeLvjZDhhe",
    "label": "P"
  },
  {
    "id": 218,
    "word": "Product Positioning ",
    "definition": ["A form of marketing that presents the benefits of your product to a particular target audience.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1X0bJ0k9D3rCrrp_DqG5PYKQt5tXLfN1P",
    "label": "P"
  },
  {
    "id": 219,
    "word": "Product Process Matrix",
    "definition": ["A tool that operations managers use to analyse and optimise the product development process.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1unS8YjpbrJAwZIg2SE67O-XeQfVDKx2d",
    "label": "P"
  },
  {
    "id": 220,
    "word": "Product Profitability ",
    "definition": ["The relation of a product's selling price and its cost. The higher the price or the lower the cost, the better is the product's profitability.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1eI_BRg-MrKzBYpbmjNfI6YPdW5OA-_tk  ",
    "label": "P"
  },
  {
    "id": 221,
    "word": "PRD: Product Requirements Document",
    "definition": ["An artifact used in the product development process to communicate what capabilities must be included in a product release to the development and testing teams. ",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Xq6EtFmieaMhiAV6_cXXq1J7KI77wS2H",
    "label": "P"
  },
  {
    "id": 222,
    "word": "Product Requirement Management ",
    "definition": ["The ongoing process of overseeing the implementation of all requirements needed to deliver a product to the market.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1GJhQW3a19-nrqU5QwiZlVKGgiwyAZtk7",
    "label": "P"
  },
  {
    "id": 223,
    "word": "Product Sense ",
    "definition": ["It is the skill of consistently being able to craft products (or make changes to existing products) that have the intended impact on their users. Product sense relies on (1) empathy to discover meaningful user needs and (2) creativity to come up with solutions that effectively address those needs.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ZqDjiMgYaUOcEq_3xPVUgHei_0sj_raY",
    "label": "P"
  },
  {
    "id": 224,
    "word": "Product Spec",
    "definition": ["An important product document that outlines key requirements for building a new feature, functionality, or product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1tSL6NqxnWZl4F15Yi26helM7Uez4rwzD",
    "label": "P"
  },
  {
    "id": 225,
    "word": "Product Stack",
    "definition": ["It refers to the apps, technologies, and other resources product managers use to bring their products to market.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1hwzN3ypydID3UZNbzLCU5yZR5qymF0IG",
    "label": "P"
  },
  {
    "id": 226,
    "word": "Product Strategist",
    "definition": ["A Product professional that identifies new opportunities, assesses the company's product performance, and helps develop its long-term strategic plans for future product lines.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=10S1zltvQRO5Uvs2BvfwqaIng8s5NkV5O",
    "label": "P"
  },
  {
    "id": 227,
    "word": "Product Strategy",
    "definition": ["The plan created by a company to define the vision for a product and identify how that vision will be realized.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1TCtyjQAl_ZLw72ST5skDqHXnv7n8HfsR",
    "label": "P"
  },
  {
    "id": 228,
    "word": "Product Strategy Framework ",
    "definition":["A high-level plan of what a product team hopes to accomplish in a given timeframe.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1-YmKC_KOG8oguc09d0-GZC4gJCOcwcPL",
    "label": "P"
  },
  {
    "id": 229,
    "word": "Product Tree",
    "definition": ["A visual tool used by product managers to prioritize and manage product feature inputs from both customers and stakeholders.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1u3hVruHiS8kjF54W_pyvsAAsbVexlY-8",
    "label": "P"
  },
  {
    "id": 230,
    "word": "Product Vision ",
    "definition": ["It describes the future state of a product that a company or team desires to achieve.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1A4jVxBcNDmbDIweFHSgt1ShwSXfkZPMZ",
    "label": "P"
  },
  {
    "id": 231,
    "word": "Product Vulnerability ",
    "definition": ["An exploitable glitch or flaw in a product, can happen to any product or service.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1hzehfxg-99unE3KDTz6Ay9R_kDQLg5wU",
    "label": "P"
  },
  {
    "id": 232,
    "word": "Program Management ",
    "definition": ["It involves identifying and coordinating the interdependencies among projects, products, and other important strategic initiatives across an organization. ",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1xH7gDdOq8uqsEbeTAVsAy2IiJHAOUZHr",
    "label": "P"
  },
  {
    "id": 233,
    "word": "Program Manager ",
    "definition": ["A strategic project-management professional whose job is to help oversee and coordinate the various projects, products, and other strategic initiatives across an organization.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=103KmXIsaVxAH8U5nn3Q5PwKG7n9T4VuR",
    "label": "P"
  },
  {
    "id": 234,
    "word": "Project Manager ",
    "definition": ["A professional who is responsible for planning, procuring, and executing a project with a defined scope from beginning to end.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1iS3OIWkVaBie3hBbmrGQed7AtW7HtFci",
    "label": "P"
  },
  {
    "id": 235,
    "word": "Prototype",
    "definition": ["It is a preview or early sample of a product or idea you plan to invest in and eventually reproduce in large quantities.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1koR7YDQpkNok3Z9dtgnnGfiyAppmHoSp",
    "label": "P"
  },
  {
    "id": 236,
    "word": "Quality Assurance ",
    "definition": ["Any systematic process of determining whether a product or service meets specified requirements.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ySxqsH2X_YPXp88CBHnNMlWDX7WsvQfK",
    "label": "Q"
  },
  {
    "id": 237,
    "word": "Quality Function Deployment ",
    "definition": ["A structured approach to defining customer needs or requirements and translating them into specific plans to produce products to meet those needs.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1MCIyaxX0j1OZXoVkI5oKY3xMXb8pNMdS",
    "label": "Q"
  },
  // {
  //   "id": 238,
  //   "word": "Rapid Application Development ",
  //   "definition": "",
  //   "vertical": "PM",
  //   "status": "1",
  //   "image": "",
  //   "label": "R"
  // },
  {
    "id": 239,
    "word": "Rapid Experimentation ",
    "definition": ["The process of continually testing hypotheses for ways to improve your product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1WewnxZlrfOxilUWSWwLKCbI52eGYzrYv",
    "label": "R"
  },
  {
    "id": 240,
    "word": "Rapid Prototyping ",
    "definition": ["It is the process of quickly creating a preliminary version of the product to evaluate the design and its functionality.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1yjZHOkhAfvpTSz8YrMtY9YjeZ2h3VPxx",
    "label": "R"
  },
  {
    "id": 241,
    "word": "Rational Product Management ",
    "definition": ["Rational Product Mnagement entails to pursuing product/service development from a shared pool of improvement-oriented business resources, i.e., channeling available time, finances, personnel, equipment, and other necessary resources to a centralized and comprehensive product/service development initiative.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1qaRYFZqcr_0qEI5Fri7qItZ_lC7mMR4Y",
    "label": "R"
  },
  {
    "id": 242,
    "word": "Refactoring ",
    "definition": ["The process by which development teams clean up a codebase or change the internal structure of a piece of software to improve it.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1nml4HbPdoVF5NTpYhuWMiOjze_8qQKdG",
    "label": "R"
  },
  {
    "id": 243,
    "word": "Release Demo",
    "definition": ["It shows the work, and the progress of a project, at the end of each iteration.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1K6M0epLnXD_4j1pfekBWnoaskcs_0Gql",
    "label": "R"
  },
  {
    "id": 244,
    "word": "Release Management ",
    "definition": ["The process of planning, designing, scheduling, testing, deploying, and controlling software releases.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=11Pj0OxdT9hYyll4My1gwAg_5KwWbA_DD",
    "label": "R"
  },
  {
    "id": 245,
    "word": "Release Notes ",
    "definition": ["The technical documentation is produced and distributed alongside the launch of a new software product or a product update.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1yu16QLNDUijrZPKajsQpT1XpqK5uMQlO",
    "label": "R"
  },
  {
    "id": 246,
    "word": "Release Plan ",
    "definition": ["A tactical document designed to capture and track the features planned for an upcoming release.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ArvPwBNPIR0xqroCKGMeid6SpdFM3XeT",
    "label": "R"
  },
  {
    "id": 247,
    "word": "Retention ",
    "definition": ["It is measured by comparing the number of customers at the start of a given time period with the number of customers at the end of that period.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Jg5WDv5A28z3aLd4u4yvmDpo-hvA6_eY",
    "label": "R"
  },
  {
    "id": 248,
    "word": "Retention Rate ",
    "definition": ["A metric that calculates the percentage of users who continue using your product or service over a given time period.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1XYl0BTtXVAWDUxYXq2WMRkX0lIcVKqze",
    "label": "R"
  },
  {
    "id": 249,
    "word": "Retrospective ",
    "definition": ["A meeting held after a product ships to discuss what happened during the product development and release process, with the goal of improving things in the future based on those learnings and conversations.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=15FA4QrR0I_5L-Zhor7aJH5RnaSgSeV6q",
    "label": "R"
  },
  {
    "id": 250,
    "word": "RICE Scoring Model",
    "definition": ["A prioritization framework designed to help product managers determine which products, features, and other initiatives to put on their roadmaps by scoring these items according to four factors - reach, impact, confidence, and effort.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=15WA0aQRuJZHfMf_xH7qRRdQ0OBhC3qVJ",
    "label": "R"
  },
  {
    "id": 251,
    "word": "Roadmap",
    "definition": ["A shared source of truth that outlines the vision, direction, priorities, and progress of a product over time.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1KBdiUz93f7HeApdrVtdx5rBvrsbjo9k_",
    "label": "R"
  },
  {
    "id": 252,
    "word": "Roadmap Milestones",
    "definition": ["A call out of a high-level date that the product team must keep in mind as they progress with the product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1OFtF1wTYxA7ceu-V320ioK4tUaSsxQ8D",
    "label": "R"
  },
  {
    "id": 253,
    "word": "Roadmap Revolution ",
    "definition": ["A complete re-evaluation of a product roadmap, commonly conducted at the beginning of the year.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1KmZ6HT2na_LM4KUAi4DPqXJa8TZbhWyx",
    "label": "R"
  },
  {
    "id": 254,
    "word": "Roadmapping Tool ",
    "definition": ["A strategic blueprint that captures and communicates the basic plan and goals for a project.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1-2ND4xS5cKThW9Mklu93qx8sdIQiZet2",
    "label": "R"
  },
  {
    "id": 255,
    "word": "Scaled Agile Framework",
    "definition": ["A set of organizational and workflow patterns for implementing agile practices at an enterprise scale.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1b7TDTuaEU_ofmMuSqJicl2dpzYQpkDiS",
    "label": "S"
  },
  {
    "id": 256,
    "word": "Scope Creep",
    "definition": ["The phenomenon in which a team's initial plan-the scope of work it agreed to completeâ€”slowly grows to include more goals, tasks, or requirements.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Kg2kN-rbQhhuU-0YrjcSVO5Z_2jkV_EJ",
    "label": "S"
  },
  {
    "id": 257,
    "word": "Scrum",
    "definition": ["A framework for project management that emphasizes teamwork, accountability, and iterative progress toward a well-defined goal.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1rDRChbUTInX0g9K5Rwm1ApXCtQnBUy-X",
    "label": "S"
  },
  {
    "id": 258,
    "word": "Scrum Master ",
    "definition": ["A product professional responsible for overseeing the development team, guiding and coaching the developers, and helping to clear any obstacles that could disrupt or slow down their work.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1EPzFvVexN84-qERmCBxbvYnI_JPk8BGj",
    "label": "S"
  },
  {
    "id": 259,
    "word": "Scrum Meeting ",
    "definition": ["A short daily meeting designed to let the team plan out its work for the day and identify any obstacles that could impact that work.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ZIh9vblKX4d3yAM4B5dYm2Py3RSDp1zJ",
    "label": "S"
  },
  {
    "id": 260,
    "word": "Scrumban",
    "definition": ["A framework that combines important features of two popular agile methodologies: Scrum and Kanban. It merges the structure and predictable routines of Scrum with Kanban's flexibility to make teams more agile, efficient, and productive.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1mNKoPuIN2BQ2JhbKqyOuD6BKDyyJ2szk",
    "label": "S"
  },
  {
    "id": 261,
    "word": "Shadow IT",
    "definition": ["The use and management of any IT technologies, solutions, services, projects, and infrastructure without formal approval and support of internal IT departments.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1mpX5Isx4dGIwFZ7yfWmDNENOXDl_eAxT",
    "label": "S"
  },
  {
    "id": 262,
    "word": "Shape Up Method ",
    "definition": ["It describes the specific processes used by product development teams to shape, bet, and build meaningful products.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1A50oyCzj_Jfdra-6_qLLUvQiRc1vgLcS",
    "label": "S"
  },
  {
    "id": 263,
    "word": "Shipyard Engine ",
    "definition": ["A format that allows teams to continuously update a product while notifying the rest of the team of these updates every step of the way.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1WkYW7kWxkmN0reOx9j17EqnulUqJtYjs",
    "label": "S"
  },
  {
    "id": 264,
    "word": "SMART Goal Setting ",
    "definition": ["It is the acronym of a goal-setting methodology that stands for specific, measurable, achievable, relevant, and timebound.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1CkPF3VXO8YnqumzJzszogQxeoWHTz0M7",
    "label": "S"
  },
  {
    "id": 265,
    "word": "Split Test & A/B Test",
    "definition": ["It is a method of experimentation where you compare two or more variants of a product to see which performs better. Typically, one variant is the control (the current version of the product), and the others are called treatments (the new versions you want to test).",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1kVTfi6P8P7TGAb25vdHvQeX75ctC0o9X",
    "label": "S"
  },
  {
    "id": 266,
    "word": "Sprint ",
    "definition": ["A short, time-boxed period when a scrum team works to complete a set amount of work.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1_XUv5tn98izzFmVylXdchnD-rB0brP5n",
    "label": "S"
  },
  {
    "id": 267,
    "word": "Sprint Goal ",
    "definition": ["A time-boxed periods of one week to one month, during which a product owner, scrum master, and scrum teamwork to complete a specific product addition.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=17lZ9CJRj4V-tsEun1Wqc9hqEkBr0IP4k",
    "label": "S"
  },
  {
    "id": 268,
    "word": "Sprint Planning ",
    "definition": ["An event in scrum that defines what can be delivered in the upcoming sprint and how that work will be achieved.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1TX06tK2msad2SV_g3tVpSn10Xw-CKb5g",
    "label": "S"
  },
  {
    "id": 269,
    "word": "Sprint Planning Meeting",
    "definition": ["An event that establishes the product development goal and plan for the upcoming sprint, based on the team's review of its product backlog.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ueDKGD2JWJl9csFv5PEJIU8CZG-65_et",
    "label": "S"
  },
  {
    "id": 270,
    "word": "Stage Gate Model",
    "definition": ["A technique applied to new product development projects that serves to create more value.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1xhTSQA3qt2a0UbKvwpDQHXTb9hqYdp9K",
    "label": "S"
  },
  {
    "id": 271,
    "word": "Stage Gate Process",
    "definition": ["A project management technique in which an initiative or project is divided into distinct stages or phases, separated by decision point.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1PNV0mSgfD_8IUKu51SdhFURANaAd-I8I",
    "label": "S"
  },
  {
    "id": 272,
    "word": "Stakeholder ",
    "definition": ["Anyone who has an interest in the product, can influence product decisions, or is affected by the outcome and deliverables of your work.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1qtV81ulHvko0JcllXmcwghTcgWomHQXk",
    "label": "S"
  },
  {
    "id": 273,
    "word": "Stakeholder Analysis ",
    "definition": ["A process of identifying these people before the project begins; grouping them according to their levels of participation, interest, and influence in the project; and determining how best to involve and communicate with each of these stakeholder groups throughout.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1YfGcAyhJz53UW3HttvMgiURxlPGrZuXV",
    "label": "S"
  },
  {
    "id": 274,
    "word": "Stakeholder Management ",
    "definition": ["It is the process of identifying, prioritizing, and engaging stakeholders throughout the product development process.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1_b6t16pqj_xnedRXll78jXUorNanyxBa",
    "label": "S"
  },
  {
    "id": 275,
    "word": "Story Point ",
    "definition": ["These are units of measurement used to determine how much effort is required to complete a product backlog item or any other piece of work.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1o7VfRVrmmOtcU-9-HIyFydsbiAR3szDt",
    "label": "S"
  },
  {
    "id": 276,
    "word": "Standup",
    "definition": ["A quick session where each member of the team shares what they accomplished yesterday, what they'll try to accomplish today, and what is blocking work from progressing.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1neMrWHUrUniSSne-4noZLtrskeanUkTe",
    "label": "S"
  },
  {
    "id": 277,
    "word": "Story Mapping ",
    "definition": ["A visual method in which teams outline the work requirements that achieve the best end-user experience.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1tBXESuvEHQH-r9LV4ZrDBanM-Vfe2RPj",
    "label": "S"
  },
  {
    "id": 278,
    "word": "Sunk Cost ",
    "definition": ["An expenditure that has already been incurred and cannot be recovered.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1uXHq6yuOhhvdF5KDBfzfQH4juRcol7eJ",
    "label": "S"
  },
  {
    "id": 279,
    "word": "SWOT Analysis ",
    "definition": ["A planning framework that a business can use to identify a strategic endeavor's strengths, weaknesses, opportunities, and threats.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1xVT1MdxUzn8PUjg7RHomPdslfsYdX6TU",
    "label": "S"
  },
  {
    "id": 280,
    "word": "Technical Debt ",
    "definition": ["The consequence of taking shortcuts during software development to achieve short-term results.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1y6QXEfLgpWistMi7qFeR_llS_yt9hovm",
    "label": "T"
  },
  {
    "id": 281,
    "word": "Technical Product Manager ",
    "definition": ["A product manager with a strong technical background that is typically focused on the more technical aspects of the product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1qFXQBpGxhXcDxXF5zunlZNmca1vIzlsy",
    "label": "T"
  },
  {
    "id": 282,
    "word": "The User Is Drunk",
    "definition": ["A design stresses the need to make things as simple as possible when designing products or websites.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1N8qw3lxpM3NDqrMoGOEdCkXcoP5naLyF",
    "label": "T"
  },
  {
    "id": 283,
    "word": "Theme ",
    "definition": ["It refers to a core objective of a product, specifying how it delivers value to the end-user.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1QCDg1vyXYzL5V2rs1XLo9ZuY6bjkdY7z",
    "label": "T"
  },
  {
    "id": 284,
    "word": "Timeline Roadmap",
    "definition": ["A shared source of truth that outlines the vision, direction, priorities, and progress of a product over time.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1-yX75DV3Dn0nfpSU3uxUV5LRS45kZekd",
    "label": "T"
  },
  {
    "id": 285,
    "word": "Top-Down Product Strategy ",
    "definition": ["A strategy where high-level objectives and a long-term vision are defined first and then used to inform what follows.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Ikocmd1iFLlac3Y0yOK9nOuluGx-3O3l",
    "label": "T"
  },
  {
    "id": 286,
    "word": "Total Addressable Market (TAM)",
    "definition": ["A calculation that represents the overall revenue opportunity for a given set of products or services.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1H-boLusPHqJ813Tk6GJHkI1N24pjc8FJ",
    "label": "T"
  },
  {
    "id": 287,
    "word": "Tribe Model Management ",
    "definition": ["A strategy where product development staff are separated into specified units so that they can then work on a particular aspect of a product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1vTRgcojfAC18v1jFZYsihzvDZrmvlOEh",
    "label": "T"
  },
  {
    "id": 288,
    "word": "Turnover Rate ",
    "definition": ["A measurement for the speed a company sells the products or inventory it has on hand.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1jbYsdJWLIRP9JOGhsiTzS3VL5rKnrtyj",
    "label": "T"
  },
  {
    "id": 289,
    "word": "Unique Selling Proposition ",
    "definition": ["It is the differentiating factor that separates your brand, product, or service from its competitors.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1txKY6KSUO2VE3n8_DnW0Vr10OkDpLeuA",
    "label": "U"
  },
  {
    "id": 290,
    "word": "Usability Testing ",
    "definition": ["A technique to evaluate how easy or difficult users find a company's product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=128Zfe6UZ9quKayu1br0NZANmUMoWjKFl",
    "label": "U"
  },
  {
    "id": 291,
    "word": "Use Case ",
    "definition": ["A hypothetical (but plausible) scenario showing how a product's user might interact with the product to achieve a specific goal.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1ycfm34zU_Po0hEQ7R5WkFQr9sdlDbDu9",
    "label": "U"
  },
  {
    "id": 292,
    "word": "User Flow ",
    "definition": ["The path taken by a prototypical user on a website or app to complete a task.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1Zt2XcFx3UUa3H4tQPTxI-GSxAaoVDEb-",
    "label": "U"
  },
  {
    "id": 293,
    "word": "User Interface ",
    "definition": ["Any method or means by which the end-user of a product interacts with, or controls, a product, software or hardware device.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=10McBG8FidfJp4Bl7QimVIIdOFiitVF5H",
    "label": "U"
  },
  {
    "id": 294,
    "word": "UX: User Experience ",
    "definition": ["The practice of creating products that address the needs of users, and which users can navigate easily and intuitively so that they can get value quickly.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1srBOs9CTxW-uNp3PaJkJq4pkDfZ7cMpX",
    "label": "U"
  },
  {
    "id": 295,
    "word": "User Persona",
    "definition": ["A fictional example of product users, including their key behaviors, goals, and responsibilities.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1UdyXbL5Z9b7BZSwlPEAChEVsL8l2u7R5",
    "label": "U"
  },
  {
    "id": 296,
    "word": "User Research ",
    "definition": ["The discipline of learning about users' needs and thought processes by studying how they perform tasks, observing how they interact with a product, or by using other data-driven strategies.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1lwRKBdkRx3FdGyvyjKGbtIVE6sBlbFaV",
    "label": "U"
  },
  {
    "id": 297,
    "word": "User Story",
    "definition": ["A small, self-contained unit of development work designed to accomplish a specific goal within a product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1INW3EBFYIgWfK4SHiZ8GxcWuA3JTnal5",
    "label": "U"
  },
  {
    "id": 298,
    "word": "UX Designer ",
    "definition": ["The branch of design that creates easy-to-use and delightful products that focus on the user's needs.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1VtRpU9oytgC6gL-VWd5_9O97hZtHb4Ak",
    "label": "U"
  },
  {
    "id": 299,
    "word": "Value Proposition ",
    "definition": ["A simple statement that summarizes why a customer would choose your product or service.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1bjLhEhOCmvmGtJ8xmBfnJ5zglWD9-PFz",
    "label": "V"
  },
  {
    "id": 300,
    "word": "Value vs. Complexity ",
    "definition": ["A prioritization model that enables product teams to weigh the value of new ideas against the complexity of implementing them into a product.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1OTq1NvVUtOlbij3iwQIOTL-y_28yBrNp",
    "label": "V"
  },
  {
    "id": 301,
    "word": "Vanity Metrics ",
    "definition":[ "A statistic that often seems good and shows growth but may not always reflect actual results.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1jmKjunyEX7qiv3YuS0P2XVJb_ISSWC0g",
    "label": "V"
  },
  {
    "id": 302,
    "word": "Velocity ",
    "definition":["A metric that calculates how long user stories within a particular sprint have taken to complete against their estimate.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1IFwhCWLQN_pwAQ0X0Bd13Yt2quwTdnPt",
    "label": "V"
  },
  {
    "id": 303,
    "word": "VOC: Voice of the Customer ",
    "definition": ["A term used to describe the in-depth process of capturing customer's expectations, preferences, and aversions.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1g_ARj4RsWYRt6Btmz-PLW4Co9TtlUzcC",
    "label": "V"
  },
  {
    "id": 304,
    "word": "Waterfall",
    "definition": ["A sequential model for planning, building, and delivering new products and features."],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=13MA79QRDB2YSpdJiO5pRNY0Khoqgjq_m",
    "label": "W"
  },
  {
    "id": 305,
    "word": "Weighted Scoring ",
    "definition": ["A framework designed to help teams prioritize outstanding tasks by assigning a numeric value to each based on cost-benefit analysis.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=15ShYPZd_9eP4wfslIitXZ1ix-NVv3PL_",
    "label": "W"
  },
  {
    "id": 306,
    "word": "WSJF: Weighted Shortest Job First ",
    "definition": ["A prioritization model used to sequence jobs (for example, Features, Capabilities, and Epics) to produce maximum economic benefit.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1nHlJg-mhxPKbhyHb9bwPiJRBy83xQ6BI",
    "label": "W"
  },
  {
    "id": 307,
    "word": "Wireframe ",
    "definition": ["A rough sketch that conveys high-level functionality, page or site layout, and user experience.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1wUb3vI-ak_ntSJkpoqxRnbn8Rq0NtxyF",
    "label": "W"
  },
  {
    "id": 308,
    "word": "YOY: Year-Over-Year ",
    "definition": ["Year-Over-Year (YOY) is a commonly used term in product management to compare the performance of a product or business over the course of a year. YOY analysis helps product managers to understand whether the product or business is growing, declining, or remaining stable over time.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=1R--k_2LNfCupwSS6NUhFEc-vskhOcccU",
    "label": "Y"
  },
  {
    "id": 309,
    "word": "Zeta Score ",
    "definition": ["The Zeta Score is a metric used to measure the overall health of a product or service. It is based on four key factors: customer satisfaction, product adoption, revenue growth, and product quality. By combining these factors, the Zeta Score provides an objective and comprehensive assessment of a product's performance.",
  ],
    "vertical": "PM",
    "status": "1",
    "image": "https://drive.google.com/uc?export=download&id=17402L_SWBOdW72qmbrt_ySsXbrVRxBZS",
    "label": "Z"
  }
]

export default search_data;