const DS_search=
// 20230223165241
// http://localhost:3001/glossary/product-glossary?vertical=DS&status=1

[
    {
      "id": 310,
      "word": "Accuracy",
      "definition": [ "Accuracy is an important metric in data science that measures how well a model or algorithm performs on a given task. Accuracy refers to the proportion of correct predictions made by a model or algorithm out of all predictions made."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1KbWzmkoKC1oy1bk9Ab7f8Part6XefGF4",
      "label": "A"
    },
    {
      "id": 311,
      "word": "Action ",
      "definition": ["In data science, taking action is a critical step in the process of turning insights and analyses into real-world outcomes. It involves using the findings from data analyses to make informed decisions, develop strategies, and take actions that have a positive impact on the organization or business."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=124n1ObBS6U3cGWKGZH40bkE80xY-Ihyi",
      "label": "A"
    },
    {
      "id": 312,
      "word": "Activation Function",
      "definition": ["In artificial neural networks, an activation function is a mathematical function that determines the output of a neuron based on its input. The activation function applies a non-linear transformation to the input, allowing the neuron to model complex, non-linear relationships between inputs and outputs."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1CD7hGWq7mZPBheWyvFrzQb6BjQT_RSHf",
      "label": "A"
    },
    {
      "id": 313,
      "word": "Active Learning ",
      "definition":["Active learning is a machine learning technique that involves actively selecting the most informative data points to label and adding them to the training dataset to improve the accuracy of the model. The goal of active learning is to minimize the amount of labeled data needed to achieve a high-performing model."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1jUTtkovdJx8upG6lcmDGEEpSehtPG-dy  ",
      "label": "A"
    },
    {
      "id": 314,
      "word": "AdaGrad",
      "definition": ["The basic idea behind AdaGrad is to scale the learning rate for each parameter based on the inverse of the square root of the sum of the squared gradients for that parameter up to the current time step. In other words, AdaGrad adapts the learning rate to the features based on how frequently they occur in the training data. The advantage of AdaGrad is that it automatically adapts the learning rate to the scale of the gradients for each parameter, which can speed up the convergence of the optimization process and reduce the risk of diverging from the optimal solution."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1e5lfIEcwlIl-1pp9gc07TqF3jJMCPJ5p",
      "label": "A"
    },
    {
      "id": 315,
      "word": "Adam Optimization",
      "definition": ["An optimization algorithm that can be used instead of the classical stochastic gradient descent procedure to update network weights iteratively based on training data. It computes individual adaptive learning rates for different parameters from estimates of the first and second moments of the gradients." ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1nKLngaSa3mB4sWICTSV-2byCCcLss91k  ",
      "label": "A"
    },
    {
      "id": 316,
      "word": "Air Flow",
      "definition": ["Apache Airflow is an open-source platform for creating, scheduling, and monitoring workflows, also known as pipelines, in data science. Airflow is designed to automate and orchestrate complex workflows that involve multiple tasks, dependencies, and data sources.",
    <p>The core of Airflow is its DAG (Directed Acyclic Graph) scheduler, which allows users to define a set of tasks and dependencies between them, and then schedule and monitor the execution of those tasks. Each task in the DAG is defined as a Python function or a script that performs a specific action, such as running a Spark job, executing a SQL query, or sending an email.</p>
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1jZZUO_wclVbUs2Akff09RYoXA8VBCusi",
      "label": "A"
    },
    {
      "id": 317,
      "word": "Algorithm",
      "definition": ["In data science, an algorithm refers to a set of rules or procedures used by a computer program to solve a particular problem or perform a specific task. These rules or procedures are usually expressed in the form of code, which is executed by a computer to process and analyze data.",
    <p>Data scientists use algorithms to build predictive models, which can be used to forecast future trends, identify patterns in data, or make decisions based on historical data. The choice of algorithm depends on the nature of the problem and the characteristics of the data being analyzed.
    </p>
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1bjAWyl6M3R3tghTsRDtCDO5-6WQcoere",
      "label": "A"
    },
    {
      "id": 318,
      "word": "Anaconda",
      "definition": ["Anaconda is a popular data science platform that provides a collection of open-source tools and libraries for data analysis, scientific computing, and machine learning. It is designed to simplify the process of setting up and managing data science environments by providing an all-in-one solution that includes a wide range of tools and packages.",
      <p>Anaconda includes the following components:</p>,
      <p>Conda: A package management system that allows users to easily install, update, and remove software packages.</p>,
      <p>Python: A popular programming language used for data analysis and machine learning.</p>,
      <p>Jupyter Notebook: A web-based interactive computational environment for creating and sharing data analyses.</p>,
      <p>Spyder: An integrated development environment (IDE) for data science that provides a powerful code editor and debugging tools.</p>,
      <p>Pandas: A library for data manipulation and analysis.</p>,
      <p>NumPy: A library for numerical computing in Python.</p>,
      <p>Matplotlib: A library for creating visualizations and plots.</p>,
      <p>Scikit-learn: A library for machine learning in Python.</p>
      ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1DHiW86d_NZeyNesW3nDGPwezNgbhtdcE",
      "label": "A"
    },
    {
      "id": 319,
      "word": "Analytics",
      "definition": ["Analytics refers to the systematic analysis of data, using statistical and computational methods, to derive insights and make informed decisions. In the context of data science, analytics involves the process of collecting, cleaning, processing, and analyzing large volumes of data to uncover patterns, trends, and relationships that can be used to improve business outcomes, optimize processes, or gain a deeper understanding of a phenomenon."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1ygyEKrxzUTJ4_OEBX1_5Rplh6xl68_pY",
      "label": "A"
    },
    {
      "id": 320,
      "word": "Apache Spark",
      "definition": ["Apache Spark is an open-source, distributed computing framework designed for large-scale data processing. Spark has gained immense popularity in the data science community due to its ability to handle large volumes of data, processing speed, and support for a wide range of data sources and processing models.",
      <p>In data science, Apache Spark can be used for a variety of tasks such as data preprocessing, data cleaning, feature extraction, and machine learning. Spark's ability to process data in-memory and its support for distributed computing allows data scientists to work with large datasets without having to worry about memory limitations or processing delays.
      </p>],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1V9ZbSm9dt7NGITrzXUANkKW_rbVnFdvS",
      "label": "A"
    },
    {
      "id": 321,
      "word": "Autoregression",
      "definition": ["Autoregression is a statistical modeling technique used in data science to model time-series data. It is a type of regression analysis where the dependent variable is regressed on one or more lagged values of itself. In other words, autoregression models the relationship between a variable and its own past values."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1mPBAraxvhjz0_MkSZRfHCAgjQv3ezKKX",
      "label": "A"
    },
    {
      "id": 322,
      "word": "API",
      "definition": ["API stands for Application Programming Interface, and it is a set of rules, protocols, and tools used for building software applications. In data science, APIs are used to connect different software systems, allowing them to exchange data and information.",
      <p>APIs can be used to access and manipulate data from various sources, such as social media platforms, databases, web services, and other applications. Data scientists often use APIs to retrieve data from these sources, process and analyze it, and then present the results to end-users or other applications.</p>
       ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1fv2SPwcWMgV6MODoo8y5C0kmZqfiX-GD",
      "label": "A"
    },
    {
      "id": 323,
      "word": "Artificial General Intelligence",
      "definition": ["Artificial General Intelligence (AGI) refers to a hypothetical form of artificial intelligence (AI) that can perform any intellectual task that a human being can. AGI would be able to reason, understand natural language, learn from experience, and solve complex problems without being explicitly programmed for each task.",
      <p>In data science, AGI represents the ultimate goal of AI research, as it would enable machines to perform a wide range of tasks across different domains, such as natural language processing, image recognition, and decision making. AGI could also potentially surpass human intelligence in certain areas and help solve some of the world's most pressing problems.</p>
      ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1m7WLH-iCf4NSPN0I35XXr08Jj3Rh2Txd",
      "label": "A"
    },
    {
      "id": 324,
      "word": "Artificial Intelligence",
      "definition": ["A branch of computer science that involves using machine learning, programming, and data science techniques that enable computers to behave intelligently. Artificial Intelligence (AI) refers to the development of intelligent machines that can perform tasks that typically require human intelligence, such as speech recognition, decision-making, and visual perception."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=12NMVv59_8AwmHR9C_vYhRjDx1qUFpQNp",
      "label": "A"
    },
    {
      "id": 325,
      "word": "Artificial Neural Networks",
      "definition": ["Artificial Neural Networks (ANNs) are a type of AI algorithm modeled after the structure and function of the human brain. ANNs are used in data science to analyze and interpret complex data sets, learn patterns, and make predictions.",
      <p>ANNs are made up of layers of interconnected nodes, or "neurons," which process and transmit information. The input layer receives data, and the output layer produces the final result. In between, there can be one or more hidden layers that process the information and extract patterns.</p>
  ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1ZY1AxxMsjJTo5CMw63d3ariEMM5GA_11",
      "label": "A"
    },
    {
      "id": 326,
      "word": "Attention",
      "definition": ["Any of a wide range of neural network architecture mechanisms that aggregate information from a set of inputs in a data-dependent manner. A typical attention mechanism might consist of a weighted sum over a set of inputs, where the weight for each input is computed by another part of the neural network."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1uGuHkzjFWq6eYFT-HuTLiyN7ZNWKsxG4",
      "label": "A"
    },
    {
      "id": 327,
      "word": "Attribute Sampling",
      "definition": ["Attribute Sampling is a statistical sampling technique used in data science to estimate the proportion of a specific characteristic, or attribute, in a population. This technique is used to determine whether a population meets certain quality standards, such as the proportion of defective items in a manufacturing process."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1zxbOR3FkbmO1p0gH3ocBFlXvs7S-mOLj",
      "label": "A"
    },
    {
      "id": 328,
      "word": "Backpropogation",
      "definition": ["Backpropagation is a supervised learning algorithm used in Artificial Neural Networks (ANNs) in data science. It is a widely used algorithm for training deep learning models, such as Convolutional Neural Networks (CNNs) and Recurrent Neural Networks (RNNs).",
      <p>The backpropagation algorithm uses a form of gradient descent to minimize the error between the predicted output and the actual output of the network. It works by propagating the error backward from the output layer to the input layer, adjusting the weights and biases of the neurons at each layer to minimize the error.</p>
      ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1rsEFOZhSlUaTA81tZFL_ewUH_42IIbmR",
      "label": "B"
    },
    {
      "id": 329,
      "word": "Bagging",
      "definition": ["Bagging, or Bootstrap Aggregating, is a machine learning technique used in data science to improve the accuracy and stability of predictive models. Bagging is a type of ensemble learning, where multiple models are trained on different subsets of the data, and the final prediction is made by aggregating the predictions of each model.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1-9s-DiPm1Zj9rZL4k85hmGUWT5yiRGXs",
      "label": "B"
    },
    {
      "id": 330,
      "word": "Bar Chart",
      "definition": ["A bar chart is a type of chart used in data science to represent categorical data with rectangular bars. The height or length of each bar is proportional to the value it represents, and the bars are usually separated by a small gap.",
      <p>Bar charts are commonly used to visualize the distribution of data or to compare different categories. They are particularly useful for displaying discrete or categorical data, such as the frequency of different outcomes, the distribution of categorical variables, or the comparison of different groups.</p>
      ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=18B0jlQ-h_XgcdCqVURg8PIyZkyJCTgSx",
      "label": "B"
    },
    {
      "id": 331,
      "word": "Bayes Theorem",
      "definition": ["Bayes' theorem is a fundamental concept in probability theory used in data science to calculate the probability of an event based on prior knowledge of related events. It is named after the 18th-century English mathematician Thomas Bayes, who first proposed the idea.",
      <p>It states that the conditional probability of an event, based on the occurrence of another event, is equal to the likelihood of the second event given the first event multiplied by the probability of the first event.</p>
      ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1xCjwsBRmLTwiub1033Oxsl6_OuDxVBgU",
      "label": "B"
    },
    {
      "id": 332,
      "word": "Bayesian Network",
      "definition": ["A Bayesian network is a type of probabilistic graphical model used in data science to represent the relationships between variables and their probability distributions. It is also known as a belief network, Bayesian belief network, or simply a Bayes net.",
      <p>Bayesian networks consist of nodes representing variables and directed edges representing the conditional dependencies between the variables. Each node is associated with a probability distribution that describes the likelihood of different values for that variable, given the values of its parent nodes. The network can be used to make probabilistic predictions and decisions based on incomplete or uncertain information.</p>
  ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1hy65J4wAgoWebsvrs30xhcNN85J2jhu3",
      "label": "B"
    },
    {
      "id": 333,
      "word": "Bayesian Statistics",
      "definition": ["Bayesian statistics is a branch of statistics that uses the principles of Bayesian inference to make probabilistic predictions and decisions based on incomplete or uncertain information. In contrast to traditional frequentist statistics, which treat probabilities as frequencies of events in a long-run experiment, Bayesian statistics treat probabilities as measures of subjective uncertainty or belief."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1AtCjRiW5JajlA8VevHSnhowmAN2-WHVQ",
      "label": "B"
    },
    {
      "id": 334,
      "word": "Bias",
      "definition": ["Bias is a significant issue in data science, as it can lead to unfair or inaccurate results. Bias can occur in various stages of the data science process, including data collection, data preprocessing, algorithm selection, and interpretation of results."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1uMBJ1PRcPOe67t9xX1-pZ1-FSXjXJZuJ",
      "label": "B"
    },
    {
      "id": 335,
      "word": "Bias-Variance Trade-off",
      "definition": ["The property of a model that the variance of the parameter estimated across samples can be reduced by increasing the bias in the estimated parameters."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1mdgHwaaJCTEmhhjbAPInGA2pGHUVpBL7",
      "label": "B"
    },
    {
      "id": 336,
      "word": "Big Data",
      "definition": ["A combination of structured, semi-structured, and unstructured data collected by organizations that can be mined for information and used in machine learning projects, predictive modeling, and other advanced analytics applications."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1ldl8A0qMD6PWqJpwmcDuAfrgF9LON3aF",
      "label": "B"
    },
    {
      "id": 337,
      "word": "Binary Variation",
      "definition": ["A categorical variable that can only take one of two values, usually represented as a Boolean — True or False — or an integer variable — 0 or 1 — whichtypically indicates that the attribute is absent, and indicates that it is present."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1imp6pBaYzgzqf8Tx1FENeQM72K6kOlKR",
      "label": "B"
    },
    {
      "id": 338,
      "word": "Binomial Distribution",
      "definition": ["A probability distribution in statistics that summarizes the likelihood that a value will take one of two independent values under a given set of parameters or assumptions."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1cF2ELGTtpnz4fWAfw94BrpZxKNcaWnPt",
      "label": "B"
    },
    {
      "id": 339,
      "word": "Business Analyst ",
      "definition": ["Business analysts assess how organisations are performing and help them improve their processes and systems. They conduct research and analysis in order to come up with solutions to business problems and help to introduce these solutions to businesses and their clients."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=107I9DZPtCUVTt7fag0zRa5LoOfCt6Hr6",
      "label": "B"
    },
    {
      "id": 340,
      "word": "Business Analytics ",
      "definition": ["The process of transforming data into insights to improve business decisions. Data management, data visualization, predictive modeling, data mining, forecasting simulation, and optimization are some of the tools used to create insights from data."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1JES9PAXAXVDC3le0JzrNOn_UwjK7hODp  ",
      "label": "B"
    },
    {
      "id": 341,
      "word": "Business Intelligence ",
      "definition": ["The process of transforming data into insights to improve business decisions. Data management, data visualization, predictive modeling, data mining, forecasting simulation, and optimization are some of the tools used to create insights from data."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1T4Ydy8lUSkMjzwitK0zxNKLIelb3iZmV",
      "label": "B"
    },
    {
      "id": 342,
      "word": "Categorical Variable ",
      "definition": ["A variable that can take on one of a limited, and usually fixed, number of possible values, assigning each individual or other unit of observation to a particular group or nominal category on the basis of some qualitative property."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1oK8RZZY5P063CG4v4eYZgRqX0NZau-N7",
      "label": "C"
    },
    {
      "id": 343,
      "word": "Classification ",
      "definition": ["Classification is a fundamental task in data science, and it involves categorizing data into distinct classes or categories based on their features or attributes. The goal of classification is to develop a predictive model that can accurately predict the class of new, unseen data based on the features of the data."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1tknzXtbda5a7lCaWm9CyiaLRFzeu93nK",
      "label": "C"
    },
    {
      "id": 344,
      "word": "Clustering ",
      "definition": ["Clustering is a technique in data science that involves grouping similar objects or data points into clusters or subgroups. The goal of clustering is to identify patterns and structures in the data without prior knowledge of the underlying categories or labels."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1XONefsPGknTxf60PRQoriEEZt14oL0kL",
      "label": "C"
    },
    {
      "id": 345,
      "word": "Computer Science ",
      "definition": ["A multi-faceted field of study focused on theoretical and practical aspects of processing information in digital computers, designing computer hardware and software, and the applications of computers."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1uOaFqBs3DabmY2QLSzl81liXIvwIBlTB",
      "label": "C"
    },
    {
      "id": 346,
      "word": "Computer Vision ",
      "definition": ["A field of artificial intelligence (AI) that enables computers and systems to derive meaningful information from digital images, videos and other visual inputs — and take actions or make recommendations based on that information."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1McaGyuj25kC1EwJNNozbNBThiVaXOass",
      "label": "C"
    },
    {
      "id": 347,
      "word": "Condition Matrix ",
      "definition": ["A matrix measures how sensitive the answer is to perturbations in the input data and to round off errors made during the solution process."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1tbKb4K75VF0qrVQ8lfm9C0IHJ92gLDT9",
      "label": "C"
    },
    {
      "id": 348,
      "word": "Continuous Variable ",
      "definition": ["A variable which can take an uncountable set of values or an infinite set of values"],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1odDAjCclFzZG8szyzjQQUzNI7lYx8fkJ",
      "label": "C"
    },
    {
      "id": 349,
      "word": "Correlation ",
      "definition": ["It measures the relationship between two variables. It can reveal meaningful relationships between different metrics or groups of metrics."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1wUu6X0_BVPjE4JxBI7MndYANydGXeW7N",
      "label": "C"
    },
    {
      "id": 350,
      "word": "Cost Function ",
      "definition": ["A measure of how wrong the model is in terms of its ability to estimate the relationship between X and y. ",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1I49Yw4TuE8KhOx7LlFDorQOHmdlUp-0j",
      "label": "C"
    },
    {
      "id": 351,
      "word": "Co-variance ",
      "definition": ["A systematic relationship between two random variables in which a change in the other reflects a change in one variable.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1QJqoRaeLVVy2jkZ0yVdazbmxqS4w4AY-",
      "label": "C"
    },
    {
      "id": 352,
      "word": "Cross-validation ",
      "definition": ["Cross-validation is a technique for validating the model efficiency by training it on the subset of input data and testing on a previously unseen subset of the input data.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=18oYJUK-rIDNvpKzcUe3NdT5G-gAyiTh9",
      "label": "C"
    },
    {
      "id": 353,
      "word": "Dashboard",
      "definition": ["An interactive analysis tool used by businesses to track and monitor the performance of their strategies with quality KPIs."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1VMFtrM5EFrsvqLv3CoishNB59Yu4v1Np",
      "label": "D"
    },
    {
      "id": 354,
      "word": "Data Analysis ",
      "definition": ["A process for obtaining raw data, and subsequently converting it into information useful for decision-making by users."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=14reLssXFxXk332JbozEWOZiZsDshrAHw",
      "label": "D"
    },
    {
      "id": 355,
      "word": "Data Analyst ",
      "definition": ["A data analyst gathers cleans, and studies data sets to help solve problems.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1davm68jW5jiqcMHsQNCkNT8ZRnPmxrla",
      "label": "D"
    },
    {
      "id": 356,
      "word": "Database",
      "definition": ["A structured set of data held in a computer's memory or on the cloud that is accessible in various ways.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1QJxYLeiyM42RYGH2vSYgNJ2TeZy6DQag",
      "label": "D"
    },
    {
      "id": 357,
      "word": "Database Management System",
      "definition": ["Software systems used to store, retrieve, and run queries on data. It serves as an interface between an end-user and a database, allowing users to create, read, update, and delete data in the database.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=11pC78QAW49sA2nea5wk1nVeXFiclrLzP",
      "label": "D"
    },
    {
      "id": 358,
      "word": "Data Consumer ",
      "definition": ["Any user, application, or system that uses data collected by another system or stored in a data repository.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1YBq3_M4ObTjlTgqRlvaCVFbm7gAa7pXo",
      "label": "D"
    },
    {
      "id": 359,
      "word": "Data Engineer",
      "definition": ["An IT worker whose primary job is to prepare data for analytical or operational uses. These software engineers are typically responsible for building data pipelines to bring together information from different source systems.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1yhveau-Ju9cXDYc_A_a5CWscK871rDBU",
      "label": "D"
    },
    {
      "id": 360,
      "word": "Data Engineering ",
      "definition": ["The practice of designing and building systems for collecting, storing, and analyzing data at scale.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1OXZOYfirzP9giQVaCdHmBDzZK1X67z7g",
      "label": "D"
    },
    {
      "id": 361,
      "word": "Dataframe",
      "definition": ["A data structure that organizes data into a 2-dimensional table of rows and columns, much like a spreadsheet." ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1U13Iqq9kCjEdcx0TNrik3Uqk2ijKe8Wa",
      "label": "D"
    },
    {
      "id": 362,
      "word": "Data Governance",
      "definition": ["Data governance is everything you do to ensure data is secure, private, accurate, available, and usable. It includes the actions people must take, and the processes they must follow.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1dEOQBM-UqvsEGZfHBvp_vBTRvQ6GpnJh",
      "label": "D"
    },
    {
      "id": 363,
      "word": "Data Journalism ",
      "definition": ["A type of journalism reflecting the increased role that numerical data is used in the production and distribution of information in the digital era.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1X5nT4T56sptr8TNBy5kYFC6KfG5Xqrd4",
      "label": "D"
    },
    {
      "id": 364,
      "word": "Data Lake ",
      "definition": ["A centralized repository designed to store, process, and secure large amounts of structured, semi-structured and unstructured data.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1dqwcnRHKl1S4Q9fTR9DQiguswMVERyFn",
      "label": "D"
    },
    {
      "id": 365,
      "word": "Data Literacy ",
      "definition": ["The ability to read, write and communicate data in context, including an understanding of data sources and constructs, analytical methods and techniques applied, and the ability to describe the use case, application, and resulting value.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1mqlmd5i9LQgJ3WvSm3G0EvzMN77YouoA",
      "label": "D"
    },
    {
      "id": 366,
      "word": "Data Mining ",
      "definition": ["Data mining is the process of discovering hidden patterns, correlations, and insights from large datasets using various statistical and computational techniques. The goal of data mining is to extract valuable information and knowledge from data that can be used for decision-making and prediction."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1suOGQ-sTMFOOOZsX5yGUnWdgRTBtPbm9  ",
      "label": "D"
    },
    {
      "id": 367,
      "word": "Data Modeling ",
      "definition": ["The process of creating a visual representation of either a whole information system or parts of it to communicate connections between data points and structures."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=18Z_blvnL3oNidCdOd_Y1jb7EO5rFeXdB",
      "label": "D"
    },
    {
      "id": 368,
      "word": "Data Pipeline ",
      "definition": ["A method in which raw data is ingested from various data sources and then ported to data store, like a data lake or data warehouse, for analysis."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1ibnzFkY3WKBFagzcOAGQ1qtpjJrgDP5R",
      "label": "D"
    },
    {
      "id": 369,
      "word": "Data Science ",
      "definition": ["The study of data to extract meaningful insights for business. It is a multidisciplinary approach that combines principles and practices from the fields of mathematics, statistics, artificial intelligence, and computer engineering to analyze large amounts of data."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1t9qVIFoGKPNAMnh4KRfjmY4X4Uy3nmn3",
      "label": "D"
    },
    {
      "id": 370,
      "word": "Data Scientist ",
      "definition": ["An analytics professional who is responsible for collecting, analyzing and interpreting data to help drive decision-making in an organization."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1jjuh4pFnQgXre5kRj6uKvo3_PoWGagKj",
      "label": "D"
    },
    {
      "id": 371,
      "word": "Data Set",
      "definition": ["A collection of related, discrete items of related data that may be accessed individually or in combination or managed as a whole entity."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1UovZipMSgamY8nAQnyYvNTa9iE-BA1E8",
      "label": "D"
    },
    {
      "id": 372,
      "word": "Data Structure ",
      "definition": ["In data science, a data structure refers to the way that data is organized and stored in a computer system or database. A well-designed data structure is important for efficient and effective data storage, retrieval, and manipulation."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1s944_MRM7TlrP8kTXts-14HRuGHX_vzT",
      "label": "D"
    },
    {
      "id": 373,
      "word": "Data Visualization ",
      "definition": ["Data visualization is the graphical representation of data and information using charts, graphs, and other visual elements. The goal of data visualization is to help users understand complex data and patterns more easily and to identify trends, outliers, and relationships."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1iS8v5wq3f2GCnYX2t4PErz3KX_dt3XdV",
      "label": "D"
    },
    {
      "id": 374,
      "word": "Data Warehouse",
      "definition": ["A data warehouse is a large, centralized repository of data that is used for reporting, data analysis, and business intelligence purposes. The data in a data warehouse is typically organized around specific subjects or domains, such as sales, marketing, or finance, and is often used to support decision-making processes."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1Bv0AgwQys3WoSenc7KNiTa8uz8dYwDjM",
      "label": "D"
    },
    {
      "id": 375,
      "word": "Data Wrangling ",
      "definition": ["The process of removing errors and combining complex data sets to make them more accessible and easier to analyze."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=14CLg3CdeLhiSn4HTGOVEYPvPhoLyU4Ur",
      "label": "D"
    },
    {
      "id": 376,
      "word": "Decision Tree",
      "definition": ["A non-parametric supervised learning algorithm, which is utilized for both classification and regression tasks. It has a hierarchical, tree structure, which consists of a root node, branches, internal nodes and leaf nodes."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1WxJqRax5-4BwsndqaeBt3OfGZtrxi61I",
      "label": "D"
    },
    {
      "id": 377,
      "word": "Deep Learning ",
      "definition": ["A subset of machine learning that trains a computer to perform human-like tasks, such as speech recognition, image identification and prediction making. It improves the ability to classify, recognize, detect and describe using data."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1C0j_zCq31p9MC308ybjYwcnH7NN5mUvv",
      "label": "D"
    },
    {
      "id": 378,
      "word": "Dimensionality Reduction ",
      "definition": ["It is the transformation of data from a high-dimensional space into a low-dimensional space so that the low-dimensional representation retains some meaningful properties of the original data, ideally close to its intrinsic dimension."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1txgASKjvdyIo2bVaOGALE6TREfZjtLlD",
      "label": "D"
    },
    {
      "id": 379,
      "word": "Exploratory Data Analysis (EDA)",
      "definition": ["It is used by data scientists to analyze and investigate data sets and summarize their main characteristics, often employing data visualization methods.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1J6OEPcMbL5wbfx89NjzWTamn1za87iFo",
      "label": "E"
    },
    {
      "id": 380,
      "word": "Extract, Load, Transform (ELT)",
      "definition": ["The process of extracting data from one or multiple sources and loading it into a target data warehouse.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1orM-JZGVJslVt2N8jZUzHMjR0G3jw2M5",
      "label": "E"
    },
    {
      "id": 381,
      "word": "Extract, Transform and Load (ETL)",
      "definition": ["A traditionally accepted way for organizations to combine data from multiple systems into a single database, data store, data warehouse, or data lake.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=10l4IKkwdlVgKlaiSwqkeJu7-cEsEQGZp",
      "label": "E"
    },
    {
      "id": 382,
      "word": "False Negative (FN, Type II Error)",
      "definition": ["A False Negative is also known as a Type II Error. It is a condition where the investigator fails to reject a null hypothesis that is actually False.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=16yxu7UfaXye0sBz3jfm8FzAt-dlp1gGq",
      "label": "F"
    },
    {
      "id": 383,
      "word": "False Positive (FP, Type I Error)",
      "definition": ["A False Positive is also known as a Type I Error. It is a condition where the investigator rejects the null hypothesis that is actually True",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1iaB0YzjQHljo75FVfRbcHmza5125Wxxo",
      "label": "F"
    },
    {
      "id": 384,
      "word": "Feature",
      "definition": ["A measurable property of the object you're trying to analyze.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1UvXZ5vTPAO1OI-z2LF_9iCsDCzGRnSZ7  ",
      "label": "F"
    },
    {
      "id": 385,
      "word": "Feature Engineering",
      "definition": ["The process of selecting, transforming, extracting, combining, and manipulating raw data to generate the desired variables for analysis or predictive modeling.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=187WmHHfDQNP6WJn8YDs_mvPvlxuNlYtd",
      "label": "F"
    },
    {
      "id": 386,
      "word": "Feature Selection ",
      "definition": ["The method of reducing the input variable to your model by using only relevant data and getting rid of noise in data.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1gqJiF-rB3B2Psy94P7M7kFV3PyrWi-ze",
      "label": "F"
    },
    {
      "id": 387,
      "word": "Gradient Descent ",
      "definition": ["An iterative first-order optimisation algorithm used to find a local minimum/maximum of a given function.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1SajqQET7Se_zXTBCjqiwvFnSuSwxfTwT",
      "label": "G"
    },
    {
      "id": 388,
      "word": "Hadoop",
      "definition": ["An open source framework that is used to efficiently store and process large datasets ranging in size from gigabytes to petabytes of data.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1H9rwI0W9fiLx0ZukrEjbYfbO7gkaya_r",
      "label": "H"
    },
    {
      "id": 389,
      "word": "Hyperparameter",
      "definition": ["Parameters whose values control the learning process and determine the values of model parameters that a learning algorithm ends up learning.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1cDHsii6LStcuADYPWaWOl4bEmHOdi1yA",
      "label": "H"
    },
    {
      "id": 390,
      "word": "Hypothesis ",
      "definition": ["In data science, a hypothesis is proven through data analytics. This means, once the hypothesis is defined, you can collect data to determine whether it provides enough evidence to support the hypothesis.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1u6z7WQQZXz-wq7ltORFmMj78LO1mSp0v",
      "label": "H"
    },
    {
      "id": 391,
      "word": "Imputation",
      "definition": ["A technique used for replacing the missing data with some substitute value to retain most of the data/information of the dataset. These techniques are used because removing the data from the dataset every time is not feasible and can lead to a reduction in the size of the dataset to a large extend, which not only raises concerns for biasing the dataset but also leads to incorrect analysis.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1PeyhAxzCAdubAyNPrRevhIX1Sr_SfAKt",
      "label": "I"
    },
    {
      "id": 392,
      "word": "K-Means",
      "definition": ["It groups similar data points together into clusters by minimizing the mean distance between geometric points.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1KZZeKwlRWa0-DXqjJgMBITBgXX5EkePc",
      "label": "K"
    },
    {
      "id": 393,
      "word": "K-Nearest Neighbors ",
      "definition": ["A non-parametric, supervised learning classifier, which uses proximity to make classifications or predictions about the grouping of an individual data point.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=187PSzJ4xxhpbLq3M-k-qvk0nh1NM8ZGK",
      "label": "K"
    },
    {
      "id": 394,
      "word": "Linear Algebra",
      "definition": ["A branch of mathematics that is extremely useful in data science and machine learning.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=11FLt1syf3PZa3A1OR51DtAOwNB1mHGAI",
      "label": "L"
    },
    {
      "id": 395,
      "word": "Linear Regression ",
      "definition": ["A regression model that estimates the relationship between one independent variable and one dependent variable using a straight line.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1OxCJtFzYb-yZqKzwYzeFneIhJjsG3Mlc",
      "label": "L"
    },
    {
      "id": 396,
      "word": "Logistics Regression ",
      "definition": ["A statistical analysis method to predict a binary outcome, such as yes or no, based on prior observations of a data set. ",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=186mtFYZ0ltZkW6ja1pVpix173qx14Odu",
      "label": "L"
    },
    {
      "id": 397,
      "word": "Machine Learning ",
      "definition": ["A branch of artificial intelligence (AI) and computer science which focuses on the use of data and algorithms to imitate the way that humans learn, gradually improving its accuracy.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1GnN5LtszdUE9OfR0oAruipXO76Bjuzxh",
      "label": "M"
    },
    {
      "id": 398,
      "word": "Mean ",
      "definition": ["The average value i.e. a number around which a whole data is spread out. ",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1J9f4-Q8uvsYSyQTNC6VVy0AG-xuswhuY",
      "label": "M"
    },
    {
      "id": 399,
      "word": "Mean Absolute Error",
      "definition": ["The magnitude of difference between the prediction of an observation and the true value of that observation.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1seXVF9rR-nESo4SZC2kuBWaQTCHUS_Jj",
      "label": "M"
    },
    {
      "id": 345,
      "word": "Mean Squared Error",
      "definition": ["It is calculated by the square of the difference between the predicted and actual target variables, divided by the number of data points.",
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1MpHiMSWyROHVB3T6c1arAztCzmu73MHK",
      "label": "M"
    },
    {
      "id": 345,
      "word": "Median",
      "definition": ["It is the middle point in a dataset—half of the data points are smaller than the median and half of the data points are larger."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1rvZychkj9wV3xFQ0G2qbaXgx8yW1z_GI",
      "label": "M"
    },
    {
      "id": 345,
      "word": "Mode",
      "definition": ["The value which occurs the maximum number of times in a given data set."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1mwd_O5Qv69mmtxYNgu_an1_Wefvm3-Y0",
      "label": "M"
    },
    {
      "id": 345,
      "word": "Model Tuning ",
      "definition": ["The experimental process of finding the optimal values of hyperparameters to maximize model performance."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1vkUfQqDM8P-A4fjFheCCnoMKwIu7ZN1B",
      "label": "M"
    },
    {
      "id": 345,
      "word": "Multivariate Modeling",
      "definition": ["A Statistical procedure for analysis of data involving more than one type of measurement or observation."],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1IN_vK2pxY7PKiqgdNMOmPo-qs_QB2Zci",
      "label": "M"
    },
    {
      "id": 345,
      "word": "Naive Bayes",
      "definition": ["A simple learning algorithm that utilizes Bayes rule together with a strong assumption that the attributes are conditionally independent, given the class. "
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1sSx7iiIOkz_5qMPXHgWDOQJaGZ0qyVrb",
      "label": "N"
    },
    {
      "id": 345,
      "word": "Natural Language Processing ",
      "definition": ["Natural Language Processing (NLP) is a subfield of artificial intelligence (AI). It helps machines process and understands human language."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=14XBxShW44O8BLZv8Z6vVVfsBUKdEYSNy",
      "label": "N"
    },
    {
      "id": 345,
      "word": "Normalization ",
      "definition": ["The process of transforming the values in a dataset to the same scale is referred to as normalization."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1am_K2B-sVfQ742gZtITR_NP0m9twAG4B",
      "label": "N"
    },
    {
      "id": 345,
      "word": "NoSQL",
      "definition": ["An approach to database design that enables the storage and querying of data outside the traditional structures found in relational databases."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1mr3WYcbtYeYoVSlDqr3OeqbUB98-vx5v",
      "label": "N"
    },
    {
      "id": 345,
      "word": "Null Hypothesis",
      "definition": ["A type of statistical hypothesis that proposes that no statistical significance exists in a set of given observations."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1x_LD_OpIPRgfADWe72A-21F7MWmQ5k7Y",
      "label": "N"
    },
    {
      "id": 345,
      "word": "Open Source ",
      "definition": ["A type of open data focused on publishing observations and results of scientific activities available for anyone to analyze and reuse."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1v3TLbD0QMvb33PUbxLLaiPzuQZhwtV8K",
      "label": "O"
    },
    {
      "id": 345,
      "word": "Ordinal Variable",
      "definition": ["A type of qualitative (non-numeric) data that groups variables into descriptive categories. A distinguishing feature of ordinal data is that the categories it uses are ordered on some kind of hierarchical scale, e.g. high to low."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1PiSmKjzoYdTcj3LD0-7r0j0xyKJg0Y6a",
      "label": "O"
    },
    {
      "id": 310,
      "word": "Outlier",
      "definition": ["An observation that lies an abnormal distance from other values in a random sample from a population. "
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1-wS4FYzwsVYMky9k9jv3RnZRBWR5aa4M",
      "label": "O"
    },
    {
      "id": 311,
      "word": "Overfitting ",
      "definition": ["An undesirable machine learning behavior that occurs when the machine learning model gives accurate predictions for training data but not for new data."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=10QD9gvOQ_geFbQrLCXQjXG4Dn4Oop_e2",
      "label": "O"
    },
    {
      "id": 312,
      "word": "Parameter ",
      "definition": ["A configuration variable that is internal to the model and whose value can be estimated from the given data."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=192_LBQFukOg_XmtB7j-sUCZeJOP6FSau",
      "label": "P"
    },
    {
      "id": 313,
      "word": "Precision ",
      "definition":["Precision is one indicator of a machine learning model's performance. Precision refers to the number of true positives divided by the total number of positive predictions."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1_e6EcU1J6uPIE-s07ljvwFFiDo-h9glR",
      "label": "P"
    },
    {
      "id": 314,
      "word": "Predictive Analytics ",
      "definition": ["A branch of advanced analytics that makes predictions about future outcomes using historical data combined with statistical modeling, data mining techniques and machine learning."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1YixeUYGiGnEPVqEtz0FitDNwtQoZ2a7j",
      "label": "P"
    },
    {
      "id": 315,
      "word": "Principal Component Analysis ",
      "definition": ["A method of extracting important variables from a large number of variables available in a dataset, it extracts a set of low-dimensional features from a high-dimensional dataset with the goal of capturing as much information as possible(variance) in the data."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1TYvSylDfiedlatZ5rFAiXNb8dd2DiOM_",
      "label": "P"
    },
    {
      "id": 316,
      "word": "Python ",
      "definition": ["An interpreted, object-oriented, high-level programming language with dynamic semantics developed by Guido van Rossum."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1hKbuC66VqhC6Ook1HJSM6as9gSilgIaY",
      "label": "P"
    },
    {
      "id": 317,
      "word": "Random Forest ",
      "definition": ["A supervised algorithm that uses an ensemble learning method consisting of a multitude of decision trees, the output of which is the consensus of the best answer to the problem."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1px1RHT6vVGqJuRGyKrEQ1kg1gBHnFg5Q",
      "label": "R"
    },
    {
      "id": 318,
      "word": "Recall ",
      "definition": ["The ability of a model to find all the relevant cases within a data set. It is the ratio between the number of Positive samples correctly classified as Positive to the total number of Positive samples."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=134csJnOxHGnifyftdgEkuh7FGDl-p4JY",
      "label": "R"
    },
    {
      "id": 319,
      "word": "Regression ",
      "definition": ["The estimation of a continuous dependent variable or response from a list of input variables, or features."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=18FQjAhRYkXoEIraK9_0MEXKl4xsj5pdl",
      "label": "R"
    },
    {
      "id": 320,
      "word": "Reinforcement Learning ",
      "definition": ["A type of machine learning technique that enables an agent to learn in an interactive environment by trial and error using feedback from its own actions and experiences."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=19iqtiH719QCLMJEMEZZTeVL-SZpTuivg",
      "label": "R"
    },
    {
      "id": 321,
      "word": "Root Mean Squared Error ",
      "definition": ["A standard way to measure the error of a model in predicting quantitative data. "
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1MJYsPgQKz8Nb-3n7kPpVJSiJRA54IK0f",
      "label": "R"
    },
    {
      "id": 322,
      "word": "Sampling Error",
      "definition": ["The difference between a population parameter and a sample statistic used to estimate it."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1hF88PUbkb6OHRISaydM_pg5hTMhRJdtb",
      "label": "S"
    },
    {
      "id": 323,
      "word": "Structured Query Language (SQL)",
      "definition": ["A powerful programming language that is used for communicating with and extracting various data types from databases."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1uwxT5aRRcF2SYMhVYrRa80Y_qIexwtYs",
      "label": "S"
    },
    {
      "id": 324,
      "word": "Standard Deviation ",
      "definition": ["A statistic that measures the dispersion of a dataset relative to its mean and is calculated as the square root of the variance."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1zL5vzYPP3HXrAxaO0HxH5OidIB4jsf9i",
      "label": "S"
    },
    {
      "id": 325,
      "word": "Supervised Learning ",
      "definition": ["A machine learning approach that's defined by its use of labeled datasets. These datasets are designed to train or “supervise” algorithms into classifying data or predicting outcomes accurately. Using labeled inputs and outputs, the model can measure its accuracy and learn over time."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1R4aEXX1_ijm_N8TpmXympf9rM3_OdPb7",
      "label": "S"
    },
    {
      "id": 326,
      "word": "Support Vector Machine (SVM)",
      "definition": ["Support Vector Machine(SVM) is a supervised machine learning algorithm used for both classification and regression. It is best suited for classification. The objective of SVM algorithm is to find a hyperplane in an N-dimensional space that distinctly classifies the data points."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1sGBNpU6aXfyU9058Jdy1wgZk68i_lOfM",
      "label": "S"
    },
    {
      "id": 327,
      "word": "Synthetic Data ",
      "definition": ["It is generated by applying a sampling technique to real-world data or by creating simulation scenarios where models and processes interact to create completely new data not directly taken from the real world."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1yjHQ5eU-EEAgUs5QtW-QEEr0CgFXoJCz",
      "label": "S"
    },
    {
      "id": 328,
      "word": "Target Variable",
      "definition": ["The variable whose values are modeled and predicted by other variables"
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1yECTYjiwaLzVuBOz7MBAKz9c5Kmq4C5Y",
      "label": "T"
    },
    {
      "id": 329,
      "word": "Test Set ",
      "definition": ["A portion of a data set used in data mining to assess the likely future performance of a single prediction or classification model that has been selected from among competing models, based on its performance with the validation set."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1ptyjiqpYtF1RcN7mtsnMewrOMZx1sWL3",
      "label": "T"
    },
    {
      "id": 330,
      "word": "Time Series",
      "definition": ["Also referred to as time-stamped data, is a sequence of data points indexed in time order."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=132xkxLOM8GlY1a_nkiP8e20OhDsU5yDH",
      "label": "T"
    },
    {
      "id": 331,
      "word": "Training Set",
      "definition": ["A portion of a data set used to fit (train) a model for prediction or classification of values that are known in the training set, but unknown in other (future) data."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1Nw6LeLHB7qeJvYdkjbn6EKj5cm3ENm3F",
      "label": "T"
    },
    {
      "id": 332,
      "word": "True Negative (TN)",
      "definition": ["An outcome where the model correctly predicts the negative class"
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=11AmqI3lgsfUgbc_e8kNYeYm4qkGHL9_Y",
      "label": "T"
    },
    {
      "id": 332,
      "word": "True Positive (TP)",
      "definition": ["An outcome where the model correctly predicts the positive class"
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1GJyJSSX-MGi7p9oKJkrf02k4nk_LcXtm",
      "label": "T"
    },
    {
      "id": 330,
      "word": "Underfitting ",
      "definition": ["A scenario in data science where a data model is unable to capture the relationship between the input and output variables accurately, generating a high error rate on both the training set and unseen data."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=11Db4p-IvrUxquuq8--8R5ErrYb86qxx-",
      "label": "U"
    },
    {
      "id": 331,
      "word": "Unstructured Data",
      "definition": ["Datasets that have not been structured in a predefined manner. Unstructured data is typically textual, like open-ended survey responses and social media conversations, but can also be non-textual, like images, video, and audio."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1NVIt-SKnfbq_i4EmPJ748BdxNkPMnr9b",
      "label": "U"
    },
    {
      "id": 332,
      "word": "Unsupervised Learning ",
      "definition": ["The training of a machine using information that is neither classified nor labeled and allowing the algorithm to act on that information without guidance. Here the task of the machine is to group unsorted information according to similarities, patterns, and differences without any prior training of data. "
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=143D-rTecaWjlb1U3chGILEdwxDgyQNtd",
      "label": "U"
    },
    {
      "id": 332,
      "word": "Variance ",
      "definition": ["A statistical measurement of the spread between numbers in a data set."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1l7wj6416X4Qe2AviwLFM7lHwQd2gNxVE",
      "label": "V"
    },
    {
      "id": 332,
      "word": "Variance ",
      "definition": ["A statistical measurement of the spread between numbers in a data set."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1l7wj6416X4Qe2AviwLFM7lHwQd2gNxVE",
      "label": "V"
    },
    {
      "id": 332,
      "word": "Web Scraping ",
      "definition": ["An automatic method to obtain large amounts of data from websites. Most of this data is unstructured data in an HTML format which is then converted into structured data in a spreadsheet or a database so that it can be used in various applications."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1tTu-kWfqUqurvB0ptqKl9RYQdX5e7TxH",
      "label": "W"
    },
    {
      "id": 332,
      "word": "Z-Score ",
      "definition": ["A Z-score measures how many standard deviations away a data point is from the mean of the group of observations."
    ],
      "vertical": "DS",
      "status": "1",
      "image": "https://drive.google.com/uc?export=download&id=1HsK5qEAI7wuWearKdo7LgdWVKPos984x",
      "label": "Z"
    },
  ]



export default DS_search