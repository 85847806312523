import React, { useEffect,useState } from "react";
import './App.css'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet,
  Switch,
  Redirect,
  Navigate,
  useLocation
} from "react-router-dom";
import Home from "./components/Home";
import GMHome from "./components/GMhome";
import BAHome from "./components/BAhome";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import MobileNav from "./components/MobileNav";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
// import {  } from "react-router";
import DataScience from "./components/dataScience";



function App() {
  
  const {id}=useParams();
  const location=useLocation();
  
  const navigate = useNavigate();
  // https://insaid-glossary.netlify.app/
  // https://inglossary.insaid.co/
  // useEffect(()=>{
    
  //     if(window.location.href=='https://inglossary.insaid.co/'){
  //       navigate("/PM")
  //     }
      
   
  // },[])
 
  return (
    <div className="App">
      <Navbar/>
      <MobileNav/>
      
      <Routes key={location.pathname} location={location}>
      <Route path='/' element={<Navigate to="/PM" replace/>} />
     <Route path="/PM"  exact element={<Home/>} />
     <Route path="/DS" exact element={<DataScience/>} />
     <Route path="/GM" exact element={<GMHome/>} />
     <Route path="/BA" exact element={<BAHome/>} />
      </Routes>
   
  <Footer/>
      
    
      
    </div>
  );
}


export default App;
