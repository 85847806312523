import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CardMedia from "@mui/material/CardMedia";
import { Grid } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import SchoolIcon from "@mui/icons-material/School";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import logo from '../images/accredian-logo.png'
// import Topbar from "../Topbar/topbar";
// import useStyles from "./style";
import WestIcon from '@mui/icons-material/West';
import AppBar from '@mui/material/AppBar';
const drawerWidth = 380;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MobileNav() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
//   const classes = useStyles();
  const [opens, setOpens] = React.useState(false);
  const [dsopens, setOpensds] = React.useState(false);
  const [pmopens, setOpenspm] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  };
  const handleClick = () => {
    setOpens(!opens);
  };
  const dshandleClick = () => {
    setOpensds(!dsopens);
  };
  const pmhandleClick = () => {
    setOpenspm(!pmopens);
  };
  const [abopens, setOpenab] = React.useState(false);
  const abhandleClick = () => {
    setOpenab(!abopens);
  };
  return (
    <>
  
    <Box sx={{ display: { xs: "block", lg: "none" } }}>
 
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "#f5f9fc",
          boxShadow: "0 0 2px 0 rgb(0 0 0 / 50%)",
          height:"80px",
        //  mt:12
        }}
        // className={classes.mobilebox}
      >
        <Toolbar sx={{py:'auto',display: { xs: "flex", lg: "none",sm:"none" },justifyContent:"space-between"}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "block" }), color: "#000",py:'auto' }}
          >
            <MenuIcon />
          </IconButton>
          <CardMedia
            component="img"
            image={logo}
            alt="green iguana"
            sx={{ display: { xs: "block", lg: "none" }, width: 170,py:4,pt:5}}
          />
        </Toolbar>
        <Toolbar sx={{py:'auto',display: { xs: "none", lg: "none",sm:"flex" },justifyContent:"space-between"}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "block" }), color: "#000",py:'auto' }}
          >
            <MenuIcon />
          </IconButton>
          <CardMedia
            component="img"
            image={logo}
            alt="green iguana"
            sx={{ display: { xs: "block", lg: "none" }, width: 140,py:2 }}
          />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Box>
          <Grid item lg={12} xs={12} container sx={{py:3,backgroundColor:"#f4f8fb"}}>
            <Grid item lg={6} xs={6}>
             
              <Link
              to="https://www.accredian.com/"
              style={{textDecoration:"none"}}
              >
              <Box sx={{display:"flex",my:1,ml:2,color:"#002080",width:"100%"}}>
                <WestIcon/>
              <Typography sx={{textAlign:"center",ml:1,fontWeight:"bold"}}>
                Back To Home Page
              </Typography>
              
              </Box>
              
              </Link>
              
              
              
            </Grid>
            <Grid item lg={6} xs={6} >
              <IconButton onClick={handleDrawerClose} sx={{ ml: 14 }}>
                {theme.direction === "ltr" ? (
                  <MenuIcon sx={{ fontSize: 30 }} />
                ) : (
                  <MenuIcon />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
            ></ListSubheader>
          }
        >
           <Link to="/DS" style={{textDecoration:"none",color:"#000"}} onClick={handleDrawerClose} >
          <ListItemButton >
            {/* <ListItemIcon>
              <InboxIcon />
            </ListItemIcon> */}
            <ListItemText primary="Data Science" />
            {/* {opens ? <ExpandLess /> : <ExpandMore />} */}
          </ListItemButton>
          </Link>
          <Link to="/PM" style={{textDecoration:"none",color:"#000"}} onClick={handleDrawerClose} >
          <ListItemButton >
            {/* <ListItemIcon>
              <InboxIcon />
            </ListItemIcon> */}
            <ListItemText primary="Product Management" />
            {/* {opens ? <ExpandLess /> : <ExpandMore />} */}
          </ListItemButton>
          </Link>
          <Link to="/GM" style={{textDecoration:"none",color:"#000"}} onClick={handleDrawerClose} >
          <ListItemButton >
            {/* <ListItemIcon>
              <InboxIcon />
            </ListItemIcon> */}
            <ListItemText primary="General Management" />
            {/* {opens ? <ExpandLess /> : <ExpandMore />} */}
          </ListItemButton>
          </Link>
          <Link to="/BA" style={{textDecoration:"none",color:"#000"}} onClick={handleDrawerClose} >
          <ListItemButton >
            {/* <ListItemIcon>
              <InboxIcon />
            </ListItemIcon> */}
            <ListItemText primary="Business Analytics" />
            {/* {opens ? <ExpandLess /> : <ExpandMore />} */}
          </ListItemButton>
          </Link>
         
         
         
           
          
          
          {/* <ListItemButton>
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize", my: 2 }}
              href="https://www.insaid.co/my-account/"
            >
              Back To Home Page
            </Button>
          </ListItemButton> */}
        </List>
      </Drawer>
      {/* <Main open={open}>
        <DrawerHeader />
      </Main> */}
    </Box>
    </>
  );
}
